import { createSelector } from "reselect";
import { GlobalState, RequestError } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: GlobalState = {
  error: undefined,
  explorer: {
    settings: {
      sort: "",
      display: "large",
    },
  },
  lang: "pt",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    requestFailed: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload;
    },
    setExplorerSettingSort: (state, action: PayloadAction<string>) => {
      state.explorer.settings.sort = action.payload;
    },
    setExplorerSettingDisplay: (state, action: PayloadAction<string>) => {
      state.explorer.settings.display = action.payload;
    },
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
  },
});

// Selectors
const getGlobal = (state: RootState): GlobalState => state.global;
export const getError = createSelector(getGlobal, (global) => global.error);
export const getExplorerData = createSelector(getGlobal, (global) => global.explorer);
export const getLang = createSelector(getGlobal, (global) => global.lang);

const { actions, reducer } = globalSlice;

export const {
  clearError,
  requestFailed,
  setExplorerSettingSort,
  setExplorerSettingDisplay,
  setLang,
} = actions;

export default reducer;
