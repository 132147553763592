import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Link, useNavigate } from 'react-router-dom';
import { theme } from '../../styles/material-theme';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import logo from '../../assets/img/logo.png';
import { Avatar, Typography } from '@mui/material';
import { useState } from 'react';
import { getIsAuthenticated, getUser, logout } from '../../redux/session';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Search from '../common/search';
import { getInitials } from '../../config/helpers/utils';
import { cleanExploreFilters } from '../../redux/filters';
import { useTranslation } from 'react-i18next';
import NotificationNavbarMenu from '../../pages/notifications/notification-navbar-menu';
import LoginIcon from '@mui/icons-material/Login';
import FavoriteIcon from '@mui/icons-material/Favorite';

const useStyles = makeStyles(() => ({
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  menuItem: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
}));

const NavbarMobile = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [state, setState] = useState({
    right: false,
  });
  const [openSearch, setOpenSearch] = useState(false);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleLogout = async () => {
    try {
      const response = await dispatch(logout());
      if (response) {
        navigate('/login');
      }
    } catch {}
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  type Anchor = 'right';

  return (
    <AppBar
      position='fixed'
      sx={{
        boxShadow: 'none',
        width: '100%',
        backgroundImage: 'none',
        borderRadius: 0,
        background: theme.palette.background.default,
        zIndex: 1300,
      }}
    >
      <Container maxWidth='xl'>
        {openSearch ? (
          <Toolbar disableGutters>
            <IconButton
              size='large'
              color='inherit'
              onClick={() => setOpenSearch(!openSearch)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Search />
          </Toolbar>
        ) : (
          <Toolbar disableGutters>
            <Link to='/'>
              <img src={logo} alt='4 All Degens' width='159' height='45' />
            </Link>

            {/* Mobile */}
            <Box className={classes.menu}>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => setOpenSearch(!openSearch)}
              >
                <SearchIcon />
              </IconButton>
              {isAuthenticated && <NotificationNavbarMenu />}
              {state['right'] === false ? (
                <IconButton
                  size='large'
                  onClick={toggleDrawer('right', true)}
                  color='inherit'
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton
                  size='large'
                  onClick={toggleDrawer('right', false)}
                  color='inherit'
                >
                  <CloseIcon />
                </IconButton>
              )}

              <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                PaperProps={{
                  style: {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    top: 56,
                  },
                }}
              >
                <Box
                  sx={{ width: 250, borderTopLeftRadius: 0 }}
                  role='presentation'
                  onClick={toggleDrawer('right', false)}
                  onKeyDown={toggleDrawer('right', false)}
                >
                  <List>
                    <ListItem
                      button
                      onClick={toggleDrawer('right', false)}
                      sx={{ marginLeft: 1.5, paddingTop: 2 }}
                    >
                      <Link
                        to='/explore'
                        className={classes.menuItem}
                        onClick={() => dispatch(cleanExploreFilters())}
                      >
                        <ListItemIcon>
                          <ExploreOutlinedIcon fontSize='large' />
                        </ListItemIcon>
                        <ListItemText primary={'Explore'} />
                      </Link>
                    </ListItem>
                    <Divider />
                    {!isAuthenticated && (
                      <Box>
                        <ListItem
                          button
                          onClick={toggleDrawer('right', false)}
                          sx={{ marginLeft: 1.5 }}
                        >
                          <Link
                            to='/login'
                            className={classes.menuItem}
                            onClick={() => dispatch(cleanExploreFilters())}
                          >
                            <ListItemIcon>
                              <LoginIcon fontSize='large' />
                            </ListItemIcon>
                            <ListItemText primary={'Login'} />
                          </Link>
                        </ListItem>
                        <Divider />
                      </Box>
                    )}
                    {isAuthenticated && (
                      <Box>
                        <ListItem button sx={{ marginLeft: 1.5 }}>
                          <Link
                            to={'/user/' + user.username}
                            className={classes.menuItem}
                          >
                            <ListItemIcon>
                              {!isAuthenticated ? (
                                <Avatar style={{ width: 35, height: 35 }}>
                                  <Typography variant='h6' color='primary'>
                                    {getInitials('user.name')}
                                  </Typography>
                                </Avatar>
                              ) : (
                                <PersonIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={t('profile')} />
                          </Link>
                        </ListItem>
                        <Divider />
                        <ListItem
                          button
                          onClick={toggleDrawer('right', false)}
                          sx={{ marginLeft: 1.5, paddingTop: 2 }}
                        >
                          <Link
                            to='/wishlist'
                            className={classes.menuItem}
                            onClick={() => dispatch(cleanExploreFilters())}
                          >
                            <ListItemIcon>
                              <FavoriteIcon fontSize='large' />
                            </ListItemIcon>
                            <ListItemText primary={t('wishlist')} />
                          </Link>
                        </ListItem>
                        <Divider />
                        <ListItem
                          button
                          onClick={handleLogout}
                          sx={{ marginLeft: 1.5 }}
                        >
                          <div className={classes.menuItem}>
                            <ListItemIcon>
                              <LogoutIcon fontSize='large' />
                            </ListItemIcon>
                            <ListItemText primary={t('logout')} />
                          </div>
                        </ListItem>
                        <Divider />
                      </Box>
                    )}
                  </List>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        )}
      </Container>
    </AppBar>
  );
};

export default NavbarMobile;
