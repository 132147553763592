import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ProductsService, { ProductLite } from "../../../services/products-service";
import { errorMessage } from "../../../config/helpers/utils";
import { useSnackbar } from "notistack";
import NftCard from "../../../components/common/nft-card";
import { Link } from "react-router-dom";
import { cleanExploreFilters, setExploreFilterCollection } from "../../../redux/filters";
import { useAppDispatch } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";

interface Props {
  collection: string;
}

const MoreFromThisCollection = ({ collection }: Props) => {
  const [collectionItems, setCollectionItems] = useState<ProductLite[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    async function getProducts() {
      if (collection !== "") {
        setLoading(true);
        try {
          const response = await ProductsService.getProducts({ page: 1, size: 4 }, { collection: collection });
          if (response) {
            setCollectionItems(response.items);
            setLoading(false);
          }
        } catch (e) {
          setLoading(false);
          enqueueSnackbar(errorMessage(e), {
            variant: "error",
          });
        }
      }
    }

    getProducts();
  }, [collection, enqueueSnackbar]);

  return (
    <Grid item xs={12} sx={{ marginBottom: 8 }}>
      <Accordion expanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ViewModuleIcon sx={{ marginRight: 1 }} />
          <Typography color="textPrimary" fontWeight="bold">
            {t("more_from_this_collection")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 4 }}>
          <Grid container spacing={2}>
            {collection === "" || loading
              ? [...Array(4)].map((p, i) => (
                  <Grid key={i} item xs={12} md={3}>
                    <NftCard product={p} loading={true} />
                  </Grid>
                ))
              : collectionItems?.map((item, i) => (
                  <Grid key={i} item xs={12} md={3}>
                    <Link
                      to={`/product/${item.assetContractAddress}/${item.tokenId}`}
                      style={{ textDecoration: "none" }}
                      reloadDocument={true}
                    >
                      <NftCard product={item} loading={loading} />
                    </Link>
                  </Grid>
                ))}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Link
                to={`/explore`}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  dispatch(cleanExploreFilters());
                  dispatch(setExploreFilterCollection(collection));
                }}
              >
                <Button variant="outlined" size="large" sx={{ marginTop: 2 }}>
                  {t("view_collection")}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default MoreFromThisCollection;
