import { Container } from '@mui/material';
import React from 'react';
import Footer from '../../../components/footer/footer';
import { makeStyles } from '@mui/styles';
import Background from '../../home/components/background';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    paddingTop: '100px',
    paddingBottom: '100px',
  },
}));

const TermsOfUse = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <Background fixed={true} />
        <h1>Terms of Use for NFT Marketplace</h1>

        <p>
          These Terms and Conditions (“Terms”) constitute an agreement between
          you (“you” or the “User”) and Galactic Mark Limited, a limited
          liability company incorporated in Hong Kong, whose registered office
          is at Flat E, 8/F, Far East Mansion, 5-6 Middle Road, Tsimshatsui,
          Kowloon, Hong Kong (“Company”). For the purposes of this agreement,
          each of User and 4AllDegensis a “Party” and, collectively, constitute
          the “Parties”.
        </p>
        <p>
          These Terms and Conditions regulate your access to and use of the NFT
          Marketplace located at www.4alldegens.com , or such other URL as may
          be designated by the Company from time to time (the “Site”), as well
          as any mobile apps or other related services or applications thereto,
          where users can acquire, purchase and/or collect (collectively,
          “Acquire”) and/or sell, transfer or otherwise dispose of
          (collectively, “Transfer”) unique non-fungible tokens (“NFTs”)
          (collectively, the “NFT Marketplace”).{' '}
        </p>
        <p>
          By accessing and using the NFT Marketplace, you are deemed to have
          read, accepted, executed and agreed to be bound by these Terms. We may
          change or amend these Terms at any time at our sole and absolute
          discretion.
        </p>
        <p>
          Privacy Policy. Privacy Policy describes the ways Company collects,
          uses, stores and/or discloses your personal information in connection
          with the site (including the NFT Marketplace). By agreeing to the
          Terms, you agree to the collection, use, storage, and disclosure of
          your data in accordance with Company’s Privacy Policy.
        </p>
        <h3>1. Definitions</h3>
        <p>
          “Account” shall have the meaning as set out in Clause 2 of these
          Terms.
        </p>
        <p>
          “Agreement” or “Terms” shall mean the agreement between You, as a
          User, and the Company for the use of the NFT Marketplace and Services,
          in accordance with these Terms.
        </p>
        <p>
          “Applicable Law” means any applicable statutes, laws, ordinances,
          orders, judgments, decrees, rules or regulations issued by any
          government authority, and any judicial or administrative
          interpretation of any of these which are the Laws of the United
          Kingdom.
        </p>
        <p>
          “Company” or “We” or “Us” means Galactic Mark Limited, the owner and
          operator of NFT Marketplace.
        </p>
        <p>
          “Confidential Information” means information, technical data or
          know-how, including, but not limited to, information relating to
          business and product or service plans, financial projections, customer
          lists, business forecasts, sales and merchandising, human resources,
          patents, patent applications, computer object or source code,
          research, inventions, processes, designs, drawings, engineering,
          marketing or finance to be confidential or proprietary or information
          which would, under the circumstances, appear to a reasonable person to
          be confidential or proprietary.
        </p>
        <p>
          “Intellectual Property” means all and any software innovations (in
          both machine-readable forms and in source code form), computer
          programs, code, designs, concepts, ideas, artwork, notes, documents,
          data, information, materials, discoveries, inventions or other
          original work, including, without limitation, any additions,
          modifications or enhancements thereof.
        </p>
        <p>
          “Non-Fungible Token” or “NFT” means a unique digital identifier that
          cannot be copied, substituted, or subdivided, that is recorded in a
          blockchain, and that is used to certify authenticity and ownership (as
          of a specific digital asset and specific rights relating to it).
        </p>
        <p>
          “NFT Marketplace” means an online platform accessible through website
          www.4alldegens.com and application fully owned and operated by the
          Company;
        </p>
        <p>
          “Services” means the access and use of the NFT Marketplace provided by
          the Company and any other services offered by the Company to You.
        </p>
        <p>
          “Order Instruction” means an instruction – submitted by you to us – to
          buy or sell an NFT at a specified price.
        </p>
        <p>“You” shall mean You, as the User;</p>
        <p>
          “Site” shall mean the Company’s website, accessible on
          www.4alldegens.com
        </p>

        <h3>2. Account.</h3>

        <p>
          2.1. Eligibility. To be eligible to use any of the services and
          products on the NFT Marketplace, you must: (i) be at least 18 years
          old, (ii) have the capacity to enter into legally binding contracts
          and (iii) reside in a country in which the relevant services and
          products are available.
        </p>
        <p>
          2.2. Registration of Account. To use the Site and before purchase of
          NFT, you will need to register for an Account by providing your
          identification information and accepting the terms of this Agreement.
          You are fully responsible for all activity that occurs under your
          Account. The Company may, in its sole discretion, refuse to open an
          Account for you, or limit the number of Accounts that you may hold or
          suspend or terminate any Account.
        </p>
        <p>
          2.3. Password Security and Contact Information. You are solely
          responsible for maintaining adequate security and control of any and
          all IDs, passwords, hints, personal identification numbers (PINs),
          application programming interface (API) keys or any other codes
          related to your Account or that You use to access the Services. Any
          loss or compromise of the foregoing information and/or your personal
          information may result in unauthorized access to your Account by third
          parties and the loss or theft of any NFT(s) and/or funds held in your
          Account and any associated accounts. We assume no responsibility for
          any loss that you may sustain due to compromise of your sensitive
          information or failure to follow or act on any notices or alerts that
          We may send to You.
        </p>
        <p>
          2.4. Verification of Your Account. Due to any statutory and regulatory
          requirements, the creation and usage of Your Account can be subject to
          additional verification. You agree to provide Us with the information
          We request for the purposes of identity verification, compliance with
          KYC rules, as well as detection of money laundering, terrorism
          financing, fraud or any other financial crime. The requested
          information may include Personal Data (please refer to our Privacy
          Policy). By providing Us with the information We request, You confirm
          that it is true and accurate, and agree to inform Us in case of change
          concerning such information. Your Account will be blocked until We are
          satisfied with the information You have provided and determine at our
          sole discretion that it is sufficient to validate Your Account.
        </p>
        <p>
          2.5. Additional information. You agree to provide additional
          information and documents at the request of any competent authority or
          in case of application of any applicable law or regulation, including
          laws related to anti-laundering (legalization) of incomes obtained by
          criminal means, or for counteracting financing of terrorism. We may
          also require You to provide additional information and documents in
          cases where it has reasons to believe that: (i) Your Account is being
          used for money laundering or for any other illegal activity; (ii) You
          have concealed or reported false identification information and other
          details; or (iii) Transactions effected via Your Account were affected
          in breach of these Terms.
        </p>
        <p>
          2.6. Deletion of Your Account. You can permanently delete Your Account
          at any time by sending Us an email to ola@4alldegens.com. Your Account
          will be deleted within 10 days after We receive Your email. Before
          permanently deleting Your Account, You are obligated to pay
          outstanding fees or any other charges to the Company (if any). If
          deletion of Your Account causes You to lose any funds, We will not be
          recovering these funds and will not be responsible or liable for any
          such lost funds.
        </p>
        <p>
          2.7. Prohibited Persons. No Prohibited Person may register and create
          an Account or otherwise make use of the Site. “Prohibited Person”
          shall mean: (i) any resident, the government or a government official
          of Cuba, Democratic People’s Republic of Korea (North Korea), Iran,
          Syria or Crimea (a region of Ukraine annexed by the Russian
          Federation); (ii) any person or entity controlled by a person listed
          on the “Specially Designated Nationals and Blocked Persons” (“SDN”)
          List and the Non-SDN List, including the Sectoral Sanctions
          Identifications List, published by OFAC; and, any other foreign
          terrorist organization or other sanctioned, restricted, or debarred
          party; or, (iii) any person that Company, it is sole discretion, deems
          to have violated any of the Terms.
        </p>
        <h3>3. Duties of the User</h3>
        <p>
          3.1. You shall comply with all Applicable Laws when using the NFT
          Marketplace. You are responsible for providing and paying for any
          means and equipment and connectivity necessary to access the NFT
          Marketplace. You shall be, to the extent permitted by Applicable Law,
          and except as otherwise expressly provided in these General Terms,
          solely responsible for procuring, maintaining and securing your
          network connections and telecommunications links to use the NFT
          Marketplace. We have no control over, and are not responsible, for all
          problems, delays, delivery failures and all other loss or damage
          arising from or relating to your network connections or
          telecommunications links or caused by the internet.
        </p>
        <p>
          3.2. You acknowledge that the NFT Marketplace, like other Internet
          applications, is not secure. We will implement reasonable security
          measures in our Services in accordance with standard industry
          practice, but we do not guarantee the prevention or detection of any
          unauthorized attempts to access the NFT Marketplace.
        </p>
        <p>
          3.3. We shall not be liable for any damages or losses caused by
          unauthorized access or use of the NFT Marketplace caused by you (for
          instance, if you allow a third party, intentionally or inadvertently,
          to access the NFT Marketplace using your devices or your accounts and
          such party makes a payment or an instruction unauthorized by you).
        </p>
        <p>
          3.4. You must not create more than one Account for your own use of the
          NFT Marketplace.
        </p>
        <p>
          3.5. Your Account is personal and shall not be transferred to or
          shared with any other person. In the event that another person
          accesses your Account due to you having provided or made available,
          intentionally or inadvertently any of your Account details, passwords
          or any other information to a third party, you are liable for any
          occurrence during this access. You shall compensate us for all and any
          losses, damages, costs and expenses we may suffer as a result of any
          failure by you to keep your Account details and passwords
          confidential.
        </p>
        <p>
          3.6. You shall immediately notify us via email at ola@4alldegens.com
          if you are aware of, or have any suspicion of, unauthorized use of the
          Account or any fraudulent activity or breach of security, including
          unauthorized use of your debit or credit card.
        </p>
        <p>
          3.7. You agree not to and not attempt to (and not to permit or
          authorize anyone to):
        </p>
        <p>
          3.7.1. disguise, or interfere in any way, with any method of detecting
          the true location where you access or use the NFT Marketplace, or
        </p>
        <p>
          3.7.2. act in any way to prevent us from correctly identifying the
          actual IP address you are using while accessing or using the NFT
          Marketplace,
        </p>
        <p>
          3.7.3. use virtual private networks (VPNs), or any technology that
          aims to mask your IP address, or any relevant details; or
        </p>
        <p>
          3.7.4. circumvent (or attempt to circumvent) any restrictions we apply
        </p>
        <h3>4. Risks</h3>
        <p>
          4.1. You agree that using the Site, you assume the following risks:
        </p>
        <p>
          4.1.1. To the extent there is a price or market for NFT and
          cryptocurrency, such markets and prices are extremely volatile, and
          variations in the price of digital assets could materially and
          adversely affect the value of any digital asset(s) you own, including
          NFT, and there is no guarantee that your NFTs will have or retain any
          value;
        </p>
        <p>
          4.1.2. there are risks associated with using a digital assets
          including, but not limited to, the risk of hardware, software and
          Internet connections and/or failures, the risk of malicious software
          introduction, and the risk that third parties may obtain unauthorized
          access to information stored within your digital “wallet” or
          elsewhere, and We will not be responsible for any of these, however
          caused;
        </p>
        <p>
          4.1.3. We do not make any promises or guarantees about the
          availability of NFT;
        </p>
        <p>
          4.1.4. We do not make any promises or guarantees related to any other
          third parties provided you any services for your NFT, including but
          not limited to the continued availability of either and/or the
          protection and/or storage of NFT or any data you provide to those
          parties;
        </p>
        <p>
          4.1.5. The risk of losing access to NFT due to loss of private key(s),
          custodial error or purchaser error;
        </p>
        <p>
          4.1.6. The risk of hacking, security weaknesses, fraud,
          counterfeiting, cyber attacks and other technological difficulties;
        </p>
        <p>
          4.1.7. Legislative and regulatory changes or actions at the state,
          federal, or international level may adversely affect the use,
          transfer, exchange, and value of NFTs. NFTs are not legal tender and
          are not backed by the government.
        </p>
        <p>4.1.8. The risks related to taxation;</p>
        <p>
          4.1.9. That NFTs are not legal tender and are not back by any
          government;
        </p>
        <p>
          4.2. You agree and understand that you are solely responsible for
          determining the nature, potential value, suitability, and
          appropriateness of these risks for yourself, and that the Company does
          not give advice or recommendations regarding NFTs, including the
          suitability and appropriateness of, and investment strategies for,
          NFTs. You agree and understand that you access and use the Services at
          your own risk; however, this brief statement does not disclose all of
          the risks associated with NFTs and other digital assets. You agree and
          understand that the Company will not be responsible for any
          communication failures, disruptions, errors, distortions or delays you
          may experience when using NFTs, however caused.
        </p>
        <p>
          4.3. You are solely responsible for determining what, if any, taxes
          apply to your NFT transactions. The Company is not responsible for
          determining the taxes that apply to NFT transactions.
        </p>
        <h3>5. NFT Transactions.</h3>
        <p>
          5.1. The Services rely on third-party platforms to perform NFT
          transactions. Our Service may contain links to third-party websites or
          applications (collectively, “Third Party Sites”). The Company does not
          own or control Third Party Sites. You understand and agree that your
          use of any Third Party Site is subject to any terms of use and/or
          privacy policy provided by such Third Party Site. The Company is not a
          party to any such agreement. You should review any terms of use and/or
          privacy policy provided by such Third Party Site and should make
          whatever investigation you feel necessary or appropriate before
          proceeding with any transaction with any third party. If the terms and
          conditions or pricing of such platform providers change or if any of
          such platforms loses market share or falls out of favor or is
          unavailable for a prolonged period of time, access to and use of the
          Services will suffer.
        </p>
        <p>
          5.2. NFT Marketplace is an administrative platform only. We are not a
          broker, financial institution, or creditor. We facilitate transactions
          between the buyer and seller in the auction but are not a party to any
          agreement between the buyer and seller of NFTs or between any users.
        </p>
        <p>
          5.3. NFTs are initially held in the Company custodial wallet. Company
          provides enterprise grade custodial services for your acquired NFTs
          for free. You can transfer any acquired NFTs to other registered users
          through the NFT Marketplace but You may not transfer them to other
          wallets or outside of the NFT Marketplace. In any case, title and risk
          to the NFTs shall at all times remain with you and shall not be
          assumed to be transferred to the Company. Also, you shall bear all
          potential risk of loss of such NFTs.
        </p>
        <p>
          5.5. Initial sales of NFTs will occur either pursuant to a stated
          sales price or via a bidding process which may take place on the NFT
          Marketplace and/or other marketplaces as approved by the Company.
          Additional Marketplace auction bidding rules and regulations (the
          “Bidding Rules”) may be disclosed in association with specific NFTs
          auctions. Bids will be valued on the currency displayed in the NFT
          Marketplace. Upon expiration of the time allotted for the bidding
          process, the highest bid will be selected and entered as part of a
          binding purchase of the applicable NFT. These purchase transactions
          will be processed for execution and recorded on the Company internal
          databases.
        </p>
        <p>
          5.6. Subsequently to a purchase and subject to an applicable holding
          period based on Company’s then-current chargeback and risk-mitigation
          policies, which may be updated from time to time, and further subject
          to your compliance with any necessary KYC/AML requirements, settlement
          and payouts of sales proceeds will be available to you in the payment
          methods available at the Site.
        </p>
        <p>
          5.7. In no event shall the Company be responsible for determining the
          tax implications of your transactions on the NFT Marketplace. You are
          solely responsible for determining what, if any, taxes apply to your
          NFT transactions.
        </p>
        <h3>6. Company’s Management of the NFT Marketplace.</h3>
        <p>
          Company reserves the right, but does not undertake the obligation to:
          (i) monitor or review the NFT Marketplace for violations of the Terms
          and for compliance with Company’s policies; (ii) report to law
          enforcement authorities and/or take legal action against anyone who
          violates the Terms; (iii) manage the NFT Marketplace in a manner
          designed to protect Company’s and third parties’ rights and property
          or to facilitate the proper functioning of the NFT Marketplace; (iv)
          screen Company’s users (registered or otherwise), or attempt to verify
          the statements of Company’s users (registered or otherwise); (v)
          monitor disputes between you and other users (registered or otherwise)
          or to terminate or block you and other users (registered or otherwise)
          for violating the Terms; and/or (vi) refuse, restrict access to or the
          availability of, or remove, delete, edit or disable (to the extent
          technologically feasible) any NFTs or any portion thereof.
        </p>
        <h3>7. Orders, cancellations and refunds</h3>
        <p>
          7.1. You may place an Order Instruction by inputting the transaction
          details and submitting a request to buy or sell an NFT at a specified
          price subject to Fees as provided in section 10 (Fees) of these Terms.
        </p>
        <p>
          7.2. Once an order to buy or sell NFT has been made it cannot be
          cancelled or recalled. This no-refund policy shall apply at all times
          regardless of your decision to use the NFT, any disruption to the
          operations of any components of the NFT, or any other reason
          whatsoever.
        </p>
        <p>
          7.3. You hereby agree that upon delivery, you will not be entitled to
          any credit or refund and all purchases are final.
        </p>
        <p>
          7.4. The price and availability of NFT may change at any time and
          without prior notice. If you have placed an Order Instruction to
          purchase or sell the NFT before the price changes, we will notify you
          and ask you to re-authorise the relevant NFT Transaction by submitting
          a fresh Order Instruction (which may be at a different price). The
          Company may also apply quantity limitations on orders at its sole
          discretion.
        </p>
        <h3>8. Intellectual property rights</h3>
        <p>
          8.1. Ownership of Site and Marketplace Assets. All legal right, title,
          and interest in and/or to this Site (including the NFT Marketplace),
          its content, components, elements, the compilation and collection
          thereof, and all intellectual property rights associated with any of
          the foregoing (collectively, the “Marketplace Assets”), resides with
          the Company or its licensors, as applicable, unless expressly provided
          otherwise in the Terms. As used in the Terms, the Marketplace Assets
          do not include the NFTs or the rights associated with them. All
          Marketplace Assets are the copyrighted property of the Company or its
          licensors, and all trademarks, service marks, and trade names
          associated with or used in the Marketplace Assets are proprietary to
          the Company or its licensors, unless expressly provided otherwise in
          the Terms. Use of this Site (including the NFT Marketplace) does not
          grant ownership of, or any other rights, with respect to any content,
          code, data, or other Marketplace Assets. You understand and agree that
          you do not have the right to reproduce, distribute, or otherwise
          commercialize any element of the Marketplace Assets without prior
          written consent of the Company. You further agree to observe
          applicable intellectual property and other laws and to refrain from
          using Marketplace Assets in any manner that infringes or violates the
          rights of any person or entity. You may not use, reproduce, modify,
          transmit, display, publish, sell, create derivative works, or
          distribute Marketplace Assets by any means for commercial profit or
          gain.
        </p>
        <p>
          8.2. Ownership of NFTs. An “Acquired NFT” means an NFT that you Own;
          and “Own” means any NFT that you acquired via a valid acquisition or
          transfer on the NFT Marketplace . Subject to the terms and conditions
          of the Terms, each holder of an Acquired NFT is granted a worldwide,
          non-exclusive, non-transferable, non-sublicensable, royalty-free right
          and license, solely during that period of time during which such
          holder Owns an Acquired NFT stored on the Company centralized
          server(s), and to the extent applicable, to display full length or
          extended length versions of the NFT specifically associated with each
          such Acquired NFT, solely for individual, personal, noncommercial
          use(s).
        </p>
        <p>
          8.3. License Restrictions. You agree that you may not, nor permit any
          third party at any time to do or attempt to do any of the foregoing
          without Company’s express prior written consent in each case: (i)
          modify, edit or repurpose the NFTs in any way; (ii) commercialize the
          NFTs, including using them to advertise, market, or sell any third
          party product or service; (iii) use the NFTs in connection with
          images, videos, or other forms of media that depict hatred,
          intolerance, violence, cruelty, or anything else that could reasonably
          be found to constitute hate speech or otherwise infringe upon the
          rights of others; (iv) use the NFTs in movies, videos, or any other
          forms of media, except solely for your own personal, non-commercial
          use; (v) sell, distribute for commercial gain (including, without
          limitation, giving away in the hopes of eventual commercial gain), or
          otherwise commercialize merchandise that includes, contains, or
          consists of the NFTs; (vi) attempt to trademark, copyright, or
          otherwise acquire additional intellectual property rights in or to the
          NFTs; or (vii) otherwise utilize the NFTs not specifically permitted
          herein. You further agree that you must (1) comply with all applicable
          laws in your use of the NFTs; (2) except as is expressly provided
          herein, you are not granted (x) any commercial, sale, resale,
          reproduction, distribution or promotional use rights respecting the
          NFTs, or (y) any rights for uses that require a synchronization or
          public performance license with respect to any musical compositions
          embodied in any NFTs.
        </p>
        <h3>9. Availability of the Services</h3>
        <p>
          9.1 Services are provided without warranty of any kind, either express
          or implied. We do not represent that the Services will be available
          all of the time to meet your needs. We will use reasonable endeavors
          to provide you with the Services as soon as possible but we do not
          guarantee that access will not be interrupted, or that there will be
          no delays, failures, errors, omissions or loss of transmitted
          information.
        </p>
        <p>
          9.2 We may suspend use of the Services for maintenance and we will
          make reasonable efforts to give you notice of any such suspensions.
          Where this isn’t possible, we’ll provide you with notice as soon as
          possible after the fact.
        </p>
        <h3>10. Fees</h3>
        <p>
          10.1 If applicable, Fees will be notified to you in advance to any NFT
          transactions and they will be displayed upon placing the Order
          Instruction and shall be paid in currency displayed at the NFT
          Marketplace.
        </p>
        <h3>11. Termination; suspension; refusal</h3>
        <p>
          11.1 You may terminate your access to the Services with us at any time
          by sending us notice in writing.
        </p>
        <p>
          11.2 We may terminate your access to the Services at any time by
          giving you notice, which may take effect immediately or on such a
          later date as may be specified in the notice.
        </p>
        <p>
          11.3 We may at any time suspend or terminate your use of the Services
          without notice if:
        </p>
        <p>11.3.1 we are required to take such action by any third party;</p>
        <p>11.3.2 you breach any condition of these Terms;</p>
        <p>
          11.3.3 you violate or we have reason to believe that you are in
          violation of any law or regulation that is applicable to your use of
          the Services;
        </p>
        <p>
          11.3.4 we have reason to believe that you are in any way involved in
          any fraudulent activity, money laundering, terrorism financing or
          other criminal activity; or
        </p>
        <p>
          11.3.5 it is reasonably necessary for us to do so to prevent you or us
          contravening any applicable law or regulatory requirements;
        </p>
        <p>11.4 We may suspend your use of the Services at any time:</p>
        <p>11.4.1 for security reasons; or</p>
        <p>
          11.4.2 if we reasonably suspect the Services have been or are being
          used without your authorisation or fraudulently; and we shall notify
          you either prior to the suspension or, if prior notification is not
          possible under the circumstances, promptly after the suspension unless
          we are prohibited by law to notify you.
        </p>
        <p>11.5 We may refuse to submit an Order Instruction where:</p>
        <p>11.5.1 we are required by a third party to do so;</p>
        <p>
          11.5.2 we are, in our reasonable opinion, required to do so by
          applicable law, regulation or any court or other authority to which we
          are subject in any jurisdiction;
        </p>
        <p>
          11.5.3 we have concerns about the security of your Account or we
          suspect the Services are being used in a fraudulent or unauthorized
          manner;
        </p>
        <p>
          11.5.4. we have identified that a transaction is erroneous or shows
          signs of market manipulation; or
        </p>
        <p>
          11.5.5. you fail to provide on request documents or information as
          reasonably required in order to comply with obligations under
          applicable laws.
        </p>
        <p>
          11.6 We will try to give you advance notice of any suspension or
          refusal. Where advance notice is not possible, we will provide you
          with notice as soon as reasonably practicable thereafter. Unless we
          are prevented by law, we will try to provide you with details of the
          nature of the suspension or refusal, the anticipated duration and
          anything you have to do to remedy any error or circumstances which
          have led to the suspension or refusal.
        </p>
        <p>
          11.7. Where the circumstances described in this clause cease to exist,
          the Company may, at its discretion, either reinstate access to the
          account and the services and/or issue you with new account details and
          reserves the right to ask you to repeat the account opening procedure
          in part or in full as outlined in these Terms and to resolve any open
          issues with your account before a restriction is removed.
        </p>
        <p>
          11.8. Whilst your use of the Services is suspended, we may (using our
          reasonable discretion) sell off all or some of your NFTs by providing
          notice to you in certain circumstances, for example;
        </p>
        <p>
          11.8.1 if you fail to provide any information we have requested or any
          warranty/representation you have given us is or becomes, in our
          opinion, materially inaccurate, incorrect or misleading;
        </p>
        <p>
          11.8.2 if you take some action (or refrain from doing something) which
          places us in breach of our legal or regulatory obligations;
        </p>
        <p>11.8.3 if you breach these Terms; or</p>
        <p>
          11.8.4 for any other reason which we may specify in our notice to you.
        </p>
        <p>
          11.9. If we sell off your NFTs, we will use our best efforts to sell
          it at prevailing market rates. If the value of the NFT has weakened,
          this means that you may incur a loss relative to the amount you
          initially paid. We will credit the sale proceeds to your Account
          and/or by using the same payment method used by you to purchase the
          NFT, at our discretion.
        </p>
        <p>
          11.10. On termination, unless prohibited by applicable law or by any
          court or other order to which we are subject in any jurisdiction, for
          a period of 10 days following the date of termination:
        </p>
        <p>
          11.10.1 you will continue to be able to view the balance of your NFTs
          on your Account dashboard;
        </p>
        <p>11.10.2 you will not be permitted to buy any further NFTs; and</p>
        <p>11.10.3 you may – at our discretion – sell your NFTs.</p>
        <p>
          11.11. If at the end of the 10 day period you have not sold down all
          your NFTs, you authorize us to sell your NFTs at the best available
          price via the relevant third party’s websites. The proceeds of such
          buy back will be credited to the e-money balance in your Account
          and/or by using the same payment method used by you to purchase the
          NFT, at our discretion.
        </p>
        <h3>12. Limitation of Liability</h3>
        <p>
          12.1 Nothing in these Terms shall operate to exclude liability for
          death or personal injury due to negligence or for fraud or fraudulent
          misrepresentation or for any statutory liability that cannot be
          excluded or amended according to applicable laws.
        </p>
        <p>12.2 Subject to section 12.1, we shall not be liable for:</p>
        <p>
          12.2.1 any disruption or impairment of our service or for disruptions
          or impairments of intermediary services on which we rely for the
          performance of our obligations hereunder, provided that such
          disruption or impairment is due to abnormal and unforeseeable
          circumstances beyond our reasonable control or the control of the
          intermediary affected;
        </p>
        <p>
          12.2.2 any indirect or consequential losses including but not limited
          to loss of profit, loss of business and loss of reputation;
        </p>
        <p>
          12.2.3 any losses arising from our compliance with legal and
          regulatory requirements;
        </p>
        <p>
          12.2.4 the assessment or payment of any taxes, duties or other charges
          that arise from your use of the Services;
        </p>
        <p>
          12.2.5 any losses arising from the inaccuracy or incompleteness of any
          market data provided to you in the course of us providing the
          Services; and
        </p>
        <p>
          12.2.6 any losses arising from incorrect instructions received by us
          from you.
        </p>
        <p>
          12.3. All Services, including the content contained therein and the
          listed NFTs, are provided on an “as is” and “as available” basis
          without warranties or conditions of any kind, either express or
          implied. The Company disclaims all warranties, including warranties of
          non-infringement, marketability and fitness for a particular purpose.
          The Company makes no warranty the Services: (a) will meet your
          requirements, (b) will be available on an uninterrupted, timely,
          secure or error-free basis; or © will be accurate, reliable, complete,
          legal or safe. The Company shall not be liable for any pecuniary
          damages, loss of profits, cost of substitute goods or services, loss
          of data, loss of goodwill, work stoppage, computer and/or device or
          technology failure or malfunction, or for any form of direct or
          indirect damages, legal action whatsoever related to any NFT, any
          technology used by the Company or related parties. You accept the
          inherent security risks of providing information and dealing online
          over the internet and agree that we have no liability or
          responsibility for any breach of security unless it is due to our
          gross negligence. The Company will not be responsible or liablel to
          you for any loss and take no responsibility for, and will not be
          liable to you for, any use of NFTs, including but not limited to, any
          losses, damages or claims arasing from: (a) user error, such as
          forgotten passwords, incorrectly constructed trnasactions or mistyped
          addresses; (b) server failure or data loss, (c) unautorized access to
          applications, (d) any unauthorized third party activities, including
          without limitation the use of viruses, phishing, or other means of
          attack against the Services. If applicable law does not allow all or
          any part of the above limitation of liability to apply to you, the
          limitations will apply to you only to the extent permitted by
          applicable law.
        </p>
        <p>
          12.4. NFTs are intangible digital assets. They exist only by virtue of
          the ownership record mantained in the relevant network. User
          acknowledges and accepts that these are all off chain transactions and
          that the transfer or title will not be reflected in the descentralized
          ledger.{' '}
        </p>
        <p>
          12.5. You agree that this limitation of liability is applicable to any
          event, to the maximum extent permitted by applicable law, the
          Company’s total aggregate liability shall not exceed one percent (10%)
          of the total sum paid directly by you to the Company for the
          applicable NFT. The foregoing limitations of liability shall not apply
          to liability of the Company for (a) death or personal injury caused by
          the Company’s negligence or (b) any injury caused by the Company’s
          fraud or fraudulent misrepresentation.{' '}
        </p>
        <h3>13. Indemnification</h3>
        <p>
          13.1. To the fullest extent permitted by law, you agree to indemnify
          the Company and its employees, affiliates, officers, directors,
          agents, representatives, related persons, contractors or
          subcontractors (the “Indemnified Parties”) and to hold them harmless
          from and against any claim, liability, loss, damage, suit, judgment,
          including without limitation, reasonable legal and accounting fees,
          resulting from or alleged to result from, (i) your use of and access
          to the Site (including the NFT Marketplace), (ii) breach or violation
          of the Terms, (iii) your violation of applicable laws, rules or
          regulations, and/or your violation of any third party right. You
          additionally agree to indemnify Indemnified Parties from and against
          actual or alleged third-party claims, damages, awards, judgments,
          losses, obligations and other expenses of any kind and nature. You
          further agree that the Indemnified Parties shall have control of the
          defense or settlement of any third-party claims. THIS INDEMNITY IS IN
          ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A
          WRITTEN AGREEMENT BETWEEN YOU AND THE COMPANY.
        </p>
        <h3>14. Miscellaneous</h3>
        <p>
          14.1 We reserve the right to change, modify or amend these Terms at
          any time. Any such change, modification or amendment shall become
          effective as soon as the same is published on our Site. Your continued
          use of the Services after the effective date of such change,
          modification or amendment shall be deemed your acceptance thereof.
        </p>
        <p>
          14.2. These Terms comprise the entire understanding and agreement
          between you and Company as to the subject matter hereof, and it
          supersedes any and all prior discussions, agreements and
          understandings of any kind between you and the Company.
        </p>
        <p>
          14.3. If any provision of these Terms is determined to be invalid or
          unenforceable under any applicable law, this will not affect the
          validity of any other provision. If any provision is found
          unenforceable, the unenforceable provision will be severed, and the
          remaining provisions will be enforced.
        </p>
        <p>
          14.4. We may not always strictly enforce our rights under these Terms.
          If we do choose not to enforce our rights at any time, it shall not be
          seen as renouncement of such right.
        </p>
        <p>
          14.5. All provisions of these Terms which by their nature extend
          beyond the expiration or termination of these Terms will continue to
          be binding and operate after the termination or expiration of these
          Terms.
        </p>
        <p>
          14.6. From time to time, the Company may engage third parties to
          assist the Company in providing certain aspects of the Services (each,
          a “Service Provider”). Service Providers may include, but are not
          limited to, Company’s technology or engineering service providers.
        </p>
        <p>
          14.7. These Terms, and any rights and licenses granted hereunder, may
          not be transferred or assigned by You, but may be assigned and
          transferred (partially or as a whole) by the Company without
          restriction, including without limitation to any of its affiliates or
          subsidiaries, or to any successor in interest of any business
          associated with the Services. Any attempted transfer or assignment in
          violation hereof shall be null and void. Subject to the foregoing,
          these Terms will bind and ensure to the benefit of the parties, their
          successors and permitted assigns.
        </p>
        <p>
          14.8. For any complaint relating to the services, disputes, unexpected
          payments from the Company for various reasons, including suspected
          fraud, or charges more than you expected, and not receiving NFT or
          service, you are advised to contact us ola@4alldegens.com.
        </p>
        <p>
          14.9 By creating an Account, you consent to receive electronic
          communications from the Company (e.g., via email or by posting notices
          to the Service). These communications may include notices about your
          Account (e.g., password changes and other transactional information)
          and are part of your relationship with us. You agree that any notices,
          agreements, disclosures or other communications that we send to you
          electronically will satisfy any legal communication requirements,
          including, but not limited to, that such communications be in writing.
          You should maintain copies of electronic communications from us by
          printing a paper copy or saving an electronic copy. We may also send
          you promotional communications via email, including, but not limited
          to, newsletters, special offers, surveys and other news and
          information we think will be of interest to you. You may opt out of
          receiving these promotional emails at any time by following the
          unsubscribe instructions provided therein.
        </p>
        <p>
          14.10. We shall not be liable for delays, failure in performance or
          interruption of service which result directly or indirectly from any
          cause or condition beyond our reasonable control, including but not
          limited to, any delay or failure due to any act of God, an act of
          civil or military authorities, act of terrorists, civil disturbance,
          war, strike or other labor dispute, fire, interruption in
          telecommunications or Internet services or network provider services,
          failure of equipment and/or software, hacking attacks, major market
          disturbances, other major event or natural catastrophe, including
          global pandemics or other public health crisis, or any other
          occurrence which is beyond our reasonable control and shall not affect
          the validity and enforceability of any remaining provisions.
        </p>
        <h3>15. Governing Law and Jurisdiction</h3>
        <p>
          These Terms and any legal relationship between the Parties arising out
          of or in connection with the Services or the NFT Marketplace shall be
          governed by and interpreted in accordance with the laws of England and
          Wales. The Parties hereby irrevocably agree to submit to the exclusive
          jurisdiction of the courts of England and Wales for the settlement of
          any claim, dispute or matter arising out of or in connection with
          these Terms.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfUse;
