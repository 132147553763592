import React from "react";
import { Box, Button, CardMedia, Paper, Typography } from "@mui/material";
import notFoundImg from "../../assets/img/404-2.png";
import { t } from "i18next";
import { Link } from "react-router-dom";

interface Props {
  slug: string;
}

const CollectionNotOpenCard = ({ slug }: Props) => {

  return (
    <Box textAlign="center">
      <Paper elevation={5} sx={{ marginTop: 16, p: 8 }}>
        <Box width={"50%"} maxWidth={"400px"} margin="0 auto">
          <CardMedia component="img" image={notFoundImg} alt="404" />
        </Box>
        <Typography variant="h4" paddingY={3} color="textSecondary">
          {t("collection_not_open")}
        </Typography>
        <Link to={`/assets/${slug}`} style={{ textDecoration: "none" }}>
          <Button variant="contained" sx={{ margin: "24px auto", display: "block" }}>
            {t("back_to_collection")}
          </Button>
        </Link>
      </Paper>
    </Box>
  );
};

export default CollectionNotOpenCard;
