import CountryService, { Country } from '../../services/country-service';
import SignUpService from '../../services/auth-services/signup-service';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingBtn from '../../components/common/loading-btn';
import ProfileService from '../../services/profile-service';
import { errorMessage } from '../../config/helpers/utils';
import { theme } from '../../styles/material-theme';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { updateUser } from '../../redux/session';
import { useTranslation } from 'react-i18next';
import { Select, TextField } from 'formik-mui';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../../types/profile';
import { Field, Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
} from '@mui/material';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    padding: theme.spacing(2, 10),
  },
  boxFields: {},
  form: {
    marginTop: theme.spacing(2),
    minWidth: 600,
  },
  formField: {
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  inputError: {
    '& fieldset': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '& p': {
      color: theme.palette.error.main,
    },
  },
  saveBtn: {
    marginTop: theme.spacing(10),
  },
  loading: {
    position: 'absolute',
  },
}));

interface Props {
  profile: Profile;
  onSuccess: () => void;
}

const UpdateInformation: React.FC<Props> = ({ profile, onSuccess }) => {
  const [loadingVerifyUser, setLoadingVerifyUser] = useState<boolean>(false);
  const [userExist, setUserExists] = useState<boolean | null>(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  const updateInformation = async (values: Profile) => {
    setLoading(true);
    try {
      setLoading(true);
      await ProfileService.updateProfile(values);
      navigate('/user/' + values.username);
      dispatch(updateUser(values));
      onSuccess();
      enqueueSnackbar(t('information_updated_successfully'), {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(errorMessage(e), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getCountries() {
      try {
        setLoading(true);
        const response = await CountryService.getCountries();
        if (response) {
          setCountries(response);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        enqueueSnackbar(errorMessage(e), {
          variant: 'error',
        });
      }
    }
    getCountries();
  }, [enqueueSnackbar]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const verifyUsername = async (e: any) => {
    setLoadingVerifyUser(true);
    setUserExists(null);

    if (
      e.target.value === '' ||
      e.target.value.length < 4 ||
      e.target.value.length > 20
    ) {
      setLoadingVerifyUser(false);
      return;
    } else {
      try {
        const response = await SignUpService.userExists(e.target.value);
        if (response) {
          setUserExists(response.exists);
          setLoadingVerifyUser(false);
        }
      } catch (e) {
        setLoadingVerifyUser(false);
        setUserExists(null);
      }
    }
  };

  const handleValidateUserName = (
    userExist: boolean | null,
    userNameErrors: string | undefined
  ) => {
    if (userExist) return t('username_exists');
    if (!!userNameErrors) return userNameErrors;
    return '';
  };

  return (
    <Box className={classes.container}>
      <Formik
        enableReinitialize
        initialValues={{
          username: profile?.username,
          country: profile?.country,
          email: profile?.email,
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .min(4, t('username_between_4_20'))
            .max(20, t('username_between_4_20'))
            .required(t('this_field_is_required'))
            .matches(/^[a-zA-Z0-9]+$/, t('is_not_correct_format')),
        })}
        onSubmit={async (values) => {
          updateInformation(values);
        }}
      >
        {({ dirty, isValid, errors }) => (
          <>
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    sx={{ marginBottom: 3 }}
                    component={TextField}
                    type='text'
                    label={t('username')}
                    name='username'
                    fullWidth
                    autoComplete='nope'
                    onBlur={verifyUsername}
                    helperText={handleValidateUserName(
                      userExist,
                      errors.username
                    )}
                    className={
                      handleValidateUserName(userExist, errors.username)
                        ? classes.inputError
                        : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {userExist === true || !!errors.username ? (
                            <HighlightOffIcon color='error' />
                          ) : userExist === null ? (
                            ''
                          ) : userExist === false ? (
                            <CheckCircleIcon color='success' />
                          ) : null}
                          <LoadingBtn
                            showLoading={
                              loadingVerifyUser && userExist === null
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Select}
                    id='country'
                    name='country'
                    labelId='Country'
                    label={t('country')}
                    style={{ width: '100%' }}
                  >
                    {countries.map((country, i) => (
                      <MenuItem value={country.isocode} key={i}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>

              <Box textAlign='center' paddingBottom={6}>
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  type='submit'
                  className={classes.saveBtn}
                  disabled={!isValid || !dirty || loading || userExist === true}
                >
                  {loading ? (
                    <CircularProgress size={14} className={classes.loading} />
                  ) : null}
                  {t('save_changes')}
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateInformation;
