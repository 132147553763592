import { Box, Container, Grid, Typography, alpha } from '@mui/material';
import { NftCardLite } from '../../components/common/nft-card-lite';
import WishListService from '../../services/wishlist-service';
import { ProductLite } from '../../services/products-service';
import { getIsAuthenticated } from '../../redux/session';
import Background from '../home/components/background';
import { Link, useNavigate } from 'react-router-dom';
import { theme } from '../../styles/material-theme';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '68px',
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: 32,
    color: '#fff',
    marginBottom: 50,
  },
  totalLabel: {
    color: '#fff',
  },
}));

const WishListpage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [wishList, setWishList] = React.useState<ProductLite[]>([]);
  const [loadingAll, setLoadingAll] = useState<boolean>(false);
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  useEffect(() => {
    setLoadingAll(true);
    async function getUserWishList() {
      try {
        const response = await WishListService.getUserWishList();
        setWishList(Object.values(response));
      } catch (error) {
      } finally {
        setLoadingAll(false);
      }
    }
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    getUserWishList();
  }, []);

  return (
    <Box display='flex' sx={{ marginTop: '108px' }}>
      <Container maxWidth='lg'>
        <Background fixed={true} />
        <Typography variant='h1' className={classes.headerTitle}>
          {t('my_favorites')}
        </Typography>
        <Box
          sx={{
            background: alpha(theme.palette.background.paper, 0.5),
            backdropFilter: 'blur(2px)',
            padding: 4,
            borderRadius: 4,
            marginBottom: 10,
          }}
        >
          <Typography
            className={classes.totalLabel}
          >{`${wishList.length} items`}</Typography>
          {wishList && wishList.length > 0 && (
            <Grid container marginTop={1} spacing={2}>
              {wishList &&
                wishList.map((item) => (
                  <Grid
                    key={item.tokenId}
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    marginY={1}
                  >
                    <Link
                      to={`/product/${item.assetContractAddress}/${item.tokenId}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <NftCardLite product={item} loading={loadingAll} />
                    </Link>
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default WishListpage;
