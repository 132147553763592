import urls from '../config/constants/urls';
import { buildPathWithParams } from '../config/helpers/url';
import { toCamelCaseObject, toSnakeCaseObject } from '../config/helpers/utils';
import { ITrait } from '../pages/create/assets/add-properties';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export interface ICollectionLite {
  name: string;
  contractAddress: string;
  imageUrl: string;
  items: number;
  status: 'open' | 'closed';
}

interface ICollections {
  collections: ICollectionLite[];
}

interface RCollectionAsset {
  asset_contract_address: string;
  token_id: string;
  name: string;
  description: string;
  image: string;
  rarity: number;
  attributes: ITrait[];
}

export interface CollectionAsset {
  assetContractAddress: string;
  tokenId: string;
  name: string;
  description: string;
  image: string;
  rarity: number;
  attributes: ITrait[];
  price: number;
  currency: string;
}

interface RCollectionAssets {
  slug: string;
  status: string;
  image: string;
  items: RCollectionAsset[];
}

export interface CollectionAssets {
  slug: string;
  status: string;
  image: string;
  items: CollectionAsset[];
}

export interface IMintCollection {
  smartContractAddress: string;
  slug: string;
  paymentMethodCode: 'IX' | 'WA';
}

export interface IMintCollectionRes {
  currency: string;
  id: number;
  minterUserId: number;
  payment: {
    externalId: string;
    status: string;
    merchantPaymentId: string;
  };
  paymentStatus: string;
  price: number;
  slug: string;
  smartContractAddress: string;
  status: string;
}

export default class MintService {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  static createCollection = async (collection: any) => {
    const headers = {
      'content-type': 'multipart/form-data',
    };
    try {
      const response = await Api.post<string>(
        urls.createCollection,
        collection,
        { headers: headers }
      );
      return toCamelCaseObject(response);
    } catch (e) {
      throw e;
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  static createAsset = async (asset: any) => {
    console.log('create asset service', asset);
    const headers = {
      'content-type': 'multipart/form-data',
    };
    try {
      const response = await Api.post<string>(urls.createAsset, asset, {
        headers: headers,
      });
      return toCamelCaseObject(response);
    } catch (e) {
      throw e;
    }
  };

  static validateSlug = async (collectionName: string) => {
    try {
      const response = await Api.get<boolean | null>(
        buildPathWithParams(urls.slugValidate, {
          collectionName,
        })
      );
      return response;
    } catch (e) {
      throw e;
    }
  };

  static getCollectionsByUser = async (
    username: string,
    status: string,
    len: string
  ) => {
    try {
      const response = await Api.get<ICollections>(
        buildPathWithParams(urls.getCollectionsByUser, {
          username,
          status,
          len,
        })
      );
      return toCamelCaseObject<ICollections>(response);
    } catch (e) {
      throw e;
    }
  };

  static getCollectionAssets = async (slug: string) => {
    try {
      const response = await Api.get<RCollectionAssets>(
        buildPathWithParams(urls.getCollectionAssets, {
          slug,
        })
      );
      return toCamelCaseObject<CollectionAssets>(response);
    } catch (e) {
      throw e;
    }
  };

  static mintCollection = async (mintCollection: IMintCollection) => {
    try {
      const response = await Api.post<string>(
        urls.mintCollection,
        toSnakeCaseObject(mintCollection)
      );
      return toCamelCaseObject<IMintCollectionRes>(response);
    } catch (e) {
      throw e;
    }
  };
}
