import { Chip } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

interface Props {
  weeklyScore: number;
  height?: number;
  marginTop?: number;
  fontSize?: number;
}

const TrendTag = ({ weeklyScore, height, marginTop, fontSize }: Props) => {
  return (
    <>
      {weeklyScore > 0 && (
        <Chip
          size='small'
          label={t('ADtrend')}
          color='primary'
          sx={{
            height: height || 17,
            fontSize: fontSize || '11px',
            fontWeight: '500',
            marginTop: marginTop || 0.5,
          }}
        />
      )}
    </>
  );
};

export default TrendTag;
