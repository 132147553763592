import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TimelineIcon from '@mui/icons-material/Timeline';
import PriceHistoryService, {
  PriceHistoryPagination,
} from '../../../../../services/price-history-service';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import BarChart from '../../../../../components/common/bar-chart';
import { useTranslation } from 'react-i18next';

interface ChartItem {
  date: string;
  price: number;
}

const PriceHistoryChart = () => {
  const location = useLocation();
  const path = location.pathname;
  const addressContractId = path.substring(9, path.lastIndexOf('/'));
  const tokenId = path.substring(path.lastIndexOf('/') + 1);
  const [dataChart, setDataChart] = useState<ChartItem[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const getPriceHistory = async (nextPage: string) => {
      try {
        const response = await PriceHistoryService.getPriceHistory(
          addressContractId,
          tokenId,
          nextPage
        );
        if (response) {
          calculatePrice(response);
        }
      } catch (e) {
        console.log('History Price Error');
      }
    };

    getPriceHistory('0');
  }, []);

  const chartPrice: ChartItem[] = [];

  const calculatePrice = (object: PriceHistoryPagination) => {
    object.items.forEach((priceEvent) => {
      chartPrice.push({
        price: priceEvent.price.sessionPrice,
        date: moment(priceEvent.createdDate).format('DD-MM-YYYY'),
      });
    });

    setDataChart(chartPrice);
  };

  return (
    <Accordion
      expanded={true}
      disabled={true}
      sx={{ marginBottom: '24px!important' }}
    >
      <AccordionSummary>
        <TimelineIcon sx={{ marginRight: 1 }} />
        <Typography color='textPrimary' fontWeight='bold'>
          {t('price_history')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {dataChart === [] ? (
          <Skeleton width={'100%'} height={200} />
        ) : (
          <BarChart dataChart={dataChart} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default PriceHistoryChart;
