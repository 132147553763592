import SaleService, { Sale } from '../../../services/sale-service';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Product } from '../../../types/product';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { Select, TextField } from 'formik-mui';
import LoadingBtn from '../../../components/common/loading-btn';
import { useSnackbar } from 'notistack';

interface SellDialogProps {
  product: Product;
  type: 'SELL' | 'EDIT';
  onClose: () => void;
  onSuccess: () => void;
}

const SellDialog = ({ product, type, onClose, onSuccess }: SellDialogProps) => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const confirmSale = async (currency: string, price: number) => {
    setLoadingBtn(true);
    const sale: Sale = {
      tokenId: product.tokenId,
      assetContractAddress: product.assetContractAddress,
      currency: currency,
      price: price,
    };

    if (type === 'SELL') {
      try {
        const response = await SaleService.saleNFT(sale);
        if (response) {
          setLoadingBtn(false);
          onSuccess();
          enqueueSnackbar(t('your_nft_is_for_sale'), {
            variant: 'success',
          });
        }
      } catch (e) {
        setLoadingBtn(false);
        console.log('Confirm Sale Error');
      }
    } else {
      try {
        const response = await SaleService.editNFTSale(sale);
        if (response) {
          setLoadingBtn(false);
          onSuccess();
          enqueueSnackbar(t('nft_edited_successfully'), {
            variant: 'success',
          });
        }
      } catch (e) {
        setLoadingBtn(false);
        console.log('Confirm Edit Sale Error');
      }
    }
  };

  return (
    <Box p={3} textAlign='center'>
      <InfoOutlinedIcon color='primary' sx={{ mt: 3, mb: 1, fontSize: 80 }} />
      <Typography variant='h5' color='primary' fontWeight='bold' mt={1} mb={3}>
        {type === 'SELL'
          ? t('do_you_want_request_sales_order')
          : t('edit_sale')}
      </Typography>

      <Formik
        enableReinitialize
        initialValues={{
          currency: 'BRL',
          price: '',
        }}
        validationSchema={Yup.object().shape({
          price: Yup.number().required(t('this_field_is_required')),
        })}
        onSubmit={async (values) => {
          confirmSale(values.currency, Number(values.price));
        }}
      >
        {({ dirty, isValid }) => (
          <>
            <Form style={{ maxWidth: 400, textAlign: 'center' }}>
              <Typography mb={2} color='textSecondary'>
                {t('select_price')}
              </Typography>
              <Grid container spacing={2} sx={{ marginY: 3 }}>
                <Grid item xs={4}>
                  <Field
                    component={Select}
                    id='currency'
                    name='currency'
                    labelId='currency'
                    label={t('currency')}
                    style={{ width: '100%' }}
                  >
                    <MenuItem value={'BRL'} disabled>
                      BRL
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={8}>
                  <Field
                    sx={{ marginBottom: 3 }}
                    component={TextField}
                    type='text'
                    label={t('price')}
                    name='price'
                    fullWidth
                    autoComplete='nope'
                  />
                </Grid>
              </Grid>

              <Box textAlign='center'>
                <Button
                  variant='outlined'
                  size='large'
                  color='primary'
                  onClick={() => onClose()}
                  disableElevation
                  sx={{ marginRight: 2 }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  disableElevation
                  type='submit'
                  disabled={!dirty || !isValid}
                >
                  <LoadingBtn showLoading={loadingBtn} />
                  {t('confirm')}
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default SellDialog;
