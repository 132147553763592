import NotificationsService, {
  INotification,
} from '../../services/notifications-service';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationSkeleton from './notification-skeleton';
import NotificationDialog from './notification-dialog';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import NotificationRow from './notification-row';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';

const useStyles = makeStyles(() => ({
  menu: {
    maxWidth: 400,
  },
  menuItem: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
    color: '#b9fa51',
    fontSize: '0.9rem',
    background: 'rgba(255, 255, 255, 0.08)',
    padding: 10,
  },
  dot: {
    width: 10,
    height: 10,
    position: 'absolute',
    top: 8,
    right: 10,
    background: 'red',
    borderRadius: 10,
  },
}));

const NotificationNavbarMenu = () => {
  const [notificationSelected, setNotificationSelected] =
    useState<INotification | null>(null);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [listAll, setListAll] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const classes = useStyles();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (location.pathname !== '/notifications') {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function getNotifications() {
      try {
        setLoading(true);
        const response = await NotificationsService.getNotifications({
          page: 1,
          size: 1000,
        });
        setNotifications(response.items);
      } catch (error) {
        console.error('e', error);
      } finally {
        setLoading(false);
      }
    }
    getNotifications();
  }, []);

  const handleFilterItems = () => {
    if (listAll) return notifications;
    return notifications.filter((item) => item.seenDate == null);
  };

  const setSeen = (id?: number) => {
    setNotifications((items) =>
      items.map((item) => {
        if (item.notificationId == id) {
          return { ...item, seenDate: 'read' };
        } else {
          return item;
        }
      })
    );
  };

  const handleShowNotification = (item: INotification) => {
    setNotificationSelected(item);
    setShowDialog(true);
  };

  const handleHasUreadNotifications = () => {
    return notifications.filter((item) => item.seenDate == null).length > 0;
  };

  return (
    <>
      <IconButton
        color='primary'
        onClick={handleClick}
        style={{ position: 'relative' }}
      >
        {handleHasUreadNotifications() && <div className={classes.dot}></div>}
        <NotificationsIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem>
          <Box style={{ width: 400 }} display='flex' gap={2}>
            <Button
              style={{ width: 100 }}
              size='small'
              variant={listAll ? 'contained' : 'text'}
              onClick={() => setListAll(true)}
            >
              {t('label_all')}
            </Button>
            <Button
              style={{ width: 100 }}
              size='small'
              variant={listAll ? 'text' : 'contained'}
              onClick={() => setListAll(false)}
            >
              {t('label_unread')}
            </Button>
          </Box>
        </MenuItem>
        {handleFilterItems().length == 0 && !loading && (
          <Box
            height={100}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Typography>{t('dont_have_notifications')}</Typography>
          </Box>
        )}
        {handleFilterItems()
          .slice(0, 3)
          .map((item, i) => (
            <MenuItem className={classes.menuItem} key={i}>
              <NotificationRow
                item={item}
                setSeen={setSeen}
                onOpen={handleShowNotification}
                lite={true}
              />
            </MenuItem>
          ))}
        {loading && (
          <>
            <NotificationSkeleton />
            <NotificationSkeleton />
            <NotificationSkeleton />
          </>
        )}
        <MenuItem style={{ padding: 0 }}>
          <Link
            to='/notifications'
            onClick={handleClose}
            className={classes.link}
          >
            <span>{t('label_see_all')}</span>
          </Link>
        </MenuItem>
      </Menu>
      {notificationSelected && (
        <NotificationDialog
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          notification={notificationSelected}
        />
      )}
    </>
  );
};

export default NotificationNavbarMenu;
