import NotificationsService, {
  INotification,
  NotificationIds,
} from '../../services/notifications-service';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius: 8,
    },
  },
  rootLite: {
    width: '100%',
  },
  listItemTextLite: {
    cursor: 'pointer',
    '& .MuiListItemText-primary': {
      fontWeight: 'bold',
      maxWidth: 250,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& .MuiListItemText-secondary': {
      fontWeight: 'bold',
    },
  },
  listItemText: {
    color: '#b9fa51',
    cursor: 'pointer',
    '& .MuiListItemText-primary': {
      fontWeight: 'bold',
    },
    '& .MuiListItemText-secondary': {
      fontWeight: 'bold',
    },
  },
  listItemTextReaded: {
    color: '#b9fa51',
    cursor: 'pointer',
  },
  listItemTextReadedLite: {
    cursor: 'pointer',
    '& .MuiListItemText-primary': {
      maxWidth: 250,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 10,
    background: '#b9fa51',
  },
}));

interface Props {
  item: INotification;
  setSeen: (id?: number) => void;
  onOpen: (item: INotification) => void;
  lite?: boolean;
}

const NotificationRow = (props: Props) => {
  const { lite = false, item, setSeen, onOpen } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const getIconType = (type: string) => {
    switch (type) {
      case 'INFO':
        return <PriorityHighIcon />;
      case 'DANGER':
        return <DangerousIcon />;
      case 'WARNING':
        return <NotificationImportantIcon />;
      default:
        return <PriorityHighIcon />;
    }
  };

  const getDiffTime = (date: string) => {
    const date1 = moment(date);
    const date2 = moment();
    const diff = date2.diff(date1, 'seconds');
    if (diff < 60) {
      return getTranslateTime(diff.toString(), 'seconds');
    } else if (diff < 3600) {
      const time = (diff / 60).toFixed(0);
      return getTranslateTime(time, 'minutes');
    } else if (diff < 86400) {
      const time = (diff / 3600).toFixed(0);
      return getTranslateTime(time, 'hours');
    } else {
      const time = (diff / 86400).toFixed(0);
      return getTranslateTime(time, 'days');
    }
  };

  const getTranslateTime = (time: string, key: string) => {
    switch (key) {
      case 'seconds':
        return `${t('notification_seconds')}`.replace('{time}', time);
      case 'minutes':
        return `${t('notification_minutes')}`.replace('{time}', time);
      case 'hours':
        return `${t('notification_hours')}`.replace('{time}', time);
      default:
        return `${t('notification_days')}`.replace('{time}', time);
    }
  };

  const handleMarkAsReadSelected = async () => {
    if (item.seenDate !== null) return;
    try {
      const payload: NotificationIds = {
        notificationIds: [item.notificationId],
      };
      await NotificationsService.setNotificationAsRead(payload);
      setSeen(item.notificationId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSee = () => {
    handleMarkAsReadSelected();
    if (item.redirectUrl !== null) {
      window.location.href = item.redirectUrl;
    } else {
      onOpen(item);
    }
  };

  return (
    <div className={lite ? classes.rootLite : classes.root} onClick={handleSee}>
      <ListItem
        secondaryAction={
          item.seenDate == null && <div className={classes.dot}></div>
        }
      >
        <ListItemAvatar>
          <Avatar>{getIconType(item.type)}</Avatar>
        </ListItemAvatar>
        <ListItemText
          color='primary'
          className={`${
            item.seenDate
              ? lite
                ? classes.listItemTextReadedLite
                : classes.listItemTextReaded
              : lite
              ? classes.listItemTextLite
              : classes.listItemText
          }`}
          primary={t(item.title)}
          secondary={getDiffTime(item.createdDate)}
        />
      </ListItem>
    </div>
  );
};

export default NotificationRow;
