import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import SimpleDialog from '../../../components/common/simple-dialog';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { theme } from '../../../styles/material-theme';
import { Product } from '../../../types/product';
import React, { useState } from 'react';
import SellDialog from './sell-dialog';
import { t } from 'i18next';

interface SellAssetProps {
  product: Product;
  onReloadProduct: (reload: boolean) => void;
  owner: string;
}

const SellAsset = ({ product, onReloadProduct, owner }: SellAssetProps) => {
  const [showSellDialog, setShowSellDialog] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  return (
    <>
      {owner === 'OWNED' || owner === 'ON_SALE' ? (
        <Accordion
          expanded={true}
          disabled={true}
          sx={{
            position: 'relative',
            top: { xs: 48, md: 68 },
          }}
        >
          <AccordionDetails
            sx={{
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              backgroundImage: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
            }}
          >
            <Container maxWidth='lg'>
              <Grid container>
                <Grid
                  xs={12}
                  md={10}
                  item
                  marginY={{ xs: 1, md: 0 }}
                  alignItems='center'
                  display='center'
                >
                  <InfoRoundedIcon
                    sx={{ marginRight: 1, verticalAlign: 'bottom' }}
                  />
                  <Typography fontWeight='bold' display='inline'>
                    {t('this_is_your_nft')} -&nbsp;
                  </Typography>

                  <Typography color='textSecondary' display='inline'>
                    {owner === 'ON_SALE'
                      ? t('your_nft_is_for_sale')
                      : t('here_you_have_the_option_to_sell')}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={2} marginY={{ xs: 2, md: 0 }}>
                  <Box>
                    {owner === 'ON_SALE' ? (
                      <Button
                        variant='contained'
                        onClick={() => setShowEditDialog(true)}
                        fullWidth
                      >
                        {t('edit_sale')}
                      </Button>
                    ) : (
                      <Button
                        variant='contained'
                        onClick={() => setShowSellDialog(true)}
                        fullWidth
                      >
                        {t('sell')}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </AccordionDetails>
        </Accordion>
      ) : null}

      {/* Sell Dialog */}
      <SimpleDialog
        isOpen={showSellDialog}
        onClose={() => setShowSellDialog(false)}
        content={
          <SellDialog
            product={product}
            type='SELL'
            onClose={() => {
              setShowSellDialog(false);
              onReloadProduct(true);
            }}
            onSuccess={() => {
              setShowSellDialog(false);
              onReloadProduct(true);
            }}
          />
        }
        actions={[]}
        maxWidth={'sm'}
      />

      {/* Edit Dialog */}
      <SimpleDialog
        isOpen={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        content={
          <SellDialog
            product={product}
            type='EDIT'
            onClose={() => {
              setShowEditDialog(false), onReloadProduct(true);
            }}
            onSuccess={() => {
              setShowEditDialog(false);
              onReloadProduct(true);
            }}
          />
        }
        actions={[]}
        maxWidth={'sm'}
      />
    </>
  );
};

export default SellAsset;
