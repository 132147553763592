import { alpha, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../../../styles/material-theme';
import Carousel from 'react-material-ui-carousel';
import React from 'react';

const howToUseAstropay = require('../../../assets/videos/how-to-use-astropay.mov');
const howToShare = require('../../../assets/videos/how-to-share.mov');
const howToBuyANft = require('../../../assets/videos/how-to-buy-a-nft.mov');
const howToSetup = require('../../../assets/videos/how-to-setup.mov');

const HowTo = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const items = [
    {
      src: howToBuyANft,
    },
    {
      src: howToSetup,
    },
    {
      src: howToShare,
    },
    {
      src: howToUseAstropay,
    },
  ];

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(90deg, ${alpha(
          theme.palette.secondary.main,
          0.5
        )}, ${alpha(theme.palette.grey.A700, 0.5)})`,
        backdropFilter: 'blur(2px)',
        borderRadius: 1.5,
        paddingX: 4,
        paddingY: 6,
      }}
    >
      <Box textAlign='center' marginBottom={4}>
        <Typography variant='h4' color='textPrimary' fontWeight='bold'>
          Aprenda tudo sobre NFT conosco
        </Typography>
      </Box>
      <Grid container spacing={{ xs: 0, md: 2 }}>
        {isMobile ? (
          <Box width={'100%'}>
            <Carousel animation='fade' height='65vh' navButtonsAlwaysVisible>
              {items.map((item, i) => (
                <Grid item xs={12} md={3} key={i}>
                  <video
                    controls
                    playsInline
                    style={{ width: '100%', borderRadius: 12 }}
                  >
                    <source src={item.src} type='video/mp4' />
                  </video>
                </Grid>
              ))}
            </Carousel>
          </Box>
        ) : (
          items.map((item, i) => (
            <Grid item xs={12} md={3} key={i}>
              <video controls style={{ width: '100%', borderRadius: 12 }}>
                <source src={`${item.src}#t=0.001`} type='video/mp4' />
              </video>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default HowTo;
