import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import NftCardLite from '../../../../components/common/nft-card-lite';
import SortFilters from './components/sort-filters';
import { makeStyles } from '@mui/styles';
import {
  Collection,
  ProductLite,
  Products,
} from '../../../../services/products-service';
import ExploreFilterChips from '../explore-filters/explore-filter-chips';
import { useAppSelector } from '../../../../redux/hooks';
import { getExplorerData } from '../../../../redux/global';
import { Waypoint } from 'react-waypoint';
import React, { useEffect, useState } from 'react';
import NoItems from './components/no-items';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 108px)',
    padding: 20,
    overflow: 'auto',
  },
}));

interface Props {
  productsItems: ProductLite[];
  productsPagination: Products;
  loading: boolean;
  loadingAll: boolean;
  collections: Collection[];
  onAskForMore: () => void;
}

export const ExploreGrid: React.FC<Props> = (props) => {
  const {
    productsItems,
    productsPagination,
    loading,
    loadingAll,
    collections,
    onAskForMore,
  } = props;
  const [loadingMore, setLoadingMore] = useState<boolean>(loading);
  const classes = useStyles();
  const explorerData = useAppSelector(getExplorerData);

  useEffect(() => {
    setLoadingMore(loading);
  }, [loading]);

  const getDisplaySize = (size: string) => {
    if (size === 'xs') {
      if (explorerData.settings.display === 'large') return 12;
      else return 6;
    } else {
      if (explorerData.settings.display === 'large') return 4;
      else return 2;
    }
  };

  return (
    <Box className={classes.root}>
      <SortFilters total={productsPagination.total} />
      <ExploreFilterChips />
      <Grid container marginTop={1} spacing={2}>
        {productsItems.length === 0 && loading === false ? (
          <Grid item xs={12} marginY={1}>
            <NoItems collections={collections} />
          </Grid>
        ) : (
          productsItems.map((p, i) => (
            <Grid
              key={i}
              item
              xs={getDisplaySize('xs')}
              lg={getDisplaySize('lg')}
              marginY={1}
            >
              <Link
                to={`/product/${p.assetContractAddress}/${p.tokenId}`}
                style={{ textDecoration: 'none' }}
              >
                <NftCardLite product={p} loading={loadingAll} />
              </Link>
            </Grid>
          ))
        )}

        {loadingMore === true
          ? [...Array(9)].map((p, i) => (
              <Grid
                item
                xs={getDisplaySize('xs')}
                lg={getDisplaySize('lg')}
                marginY={1}
                key={i}
              >
                <NftCardLite product={p} loading={true} />
              </Grid>
            ))
          : null}
      </Grid>
      <Box height={2}>
        <Waypoint
          onEnter={() => {
            if (
              productsPagination.total !== 0 &&
              productsPagination.items.length !== 0
            ) {
              setLoadingMore(true);
              onAskForMore();
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ExploreGrid;
