import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Grow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import React, { useState } from 'react';
import Footer from '../components/footer/footer';
import { theme } from '../styles/material-theme';
import Background from './home/components/background';

const useStyles = makeStyles(() => ({
  accordion: {
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#33393e',
    },
    '& .MuiAccordionDetails-root': {
      padding: '32px 24px',
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
    '& .MuiAccordionSummary-root': {
      borderBottom: 'none',
      '&.Mui-expanded': {
        borderBottom: `1px solid ${theme.palette.common.black}`,
      },
    },
  },
}));

const questions = [
  {
    title: 'q_what_is_4alldegens',
    description: 'a_what_is_4alldegens',
  },
  {
    title: 'q_how_to_get_started',
    description: 'a_how_to_get_started',
  },
  {
    title: 'q_how_to_buy_nft',
    description: 'a_how_to_buy_nft',
  },
  {
    title: 'q_how_to_sell_nft',
    description: 'a_how_to_sell_nft',
  },
  {
    title: 'q_how_to_see_my_nft_vault',
    description: 'a_how_to_see_my_nft_vault',
  },
  {
    title: 'q_where_is_my_nft',
    description: 'a_where_is_my_nft',
  },
  {
    title: 'q_what_is_a_nft',
    description: 'a_what_is_a_nft',
  },
  {
    title: 'q_other_questions',
    description: 'a_other_questions',
  },
];

const Faq = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const accordionHandleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const classes = useStyles();

  return (
    <>
      <Container maxWidth='lg'>
        <Background fixed={true} />
        <Box marginY={15} id='faq'>
          <Box
            style={{
              position: 'relative',
              top: '-140px',
              visibility: 'hidden',
            }}
          ></Box>
          <Grid container paddingY={{ xs: 10, md: 4 }}>
            <Grow in>
              <Grid item xs={12}>
                <Box textAlign='center' marginBottom={4}>
                  <Typography
                    variant='h4'
                    color='textPrimary'
                    marginBottom={1}
                    fontWeight='bold'
                  >
                    {t('do_you_have_questions')}
                  </Typography>
                  <Typography
                    variant='h4'
                    color='textPrimary'
                    fontWeight='bold'
                  >
                    {t('we_have_answers')}
                  </Typography>
                </Box>
              </Grid>
            </Grow>
            <Grow in timeout={100}>
              <Box>
                {questions.map((item, i) => (
                  <Accordion
                    expanded={expanded === `${'panel' + i}`}
                    onChange={accordionHandleChange(`${'panel' + i}`)}
                    className={classes.accordion}
                    key={i}
                  >
                    <AccordionSummary
                      expandIcon={<KeyboardArrowDown fontSize='small' />}
                    >
                      <Typography
                        sx={{
                          width: '100%',
                          flexShrink: 0,
                        }}
                      >
                        {t(item.title)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{t(item.description)}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Grow>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Faq;
