import React from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { theme } from './styles/material-theme';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Navbar from './components/navbar/navbar';
import Explore from './pages/explore/explore';
import SignUp from './pages/signup/signup';
import Login from './pages/login/login';
import Profile from './pages/profile/profile';
import { SnackbarProvider } from 'notistack';
import { useAppSelector } from './redux/hooks';
import ProductDetail from './pages/product-detail/product-detail';
import ForgotPassword from './pages/forgot-password/forgot-password';
import NavbarMobile from './components/navbar/navbar-mobile';
import ValidateEmail from './pages/signup/validate-email';
import Privacy from './pages/policy/privacy/privacy';
import Cookies from './pages/policy/cookies/cookies';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { getLang } from './redux/global';
import Faq from './pages/faq';
import TermsOfUse from './pages/policy/terms-of-use/terms-of-use';
import SomethingWentWrong from './pages/error/something-went-wrong';
import TagManager from 'react-gtm-module';
import WishListpage from './pages/wishlist/wishlist-page';
import NotificationsPage from './pages/notifications/notifications';
import CreateCollection from './pages/create/collections/create-collection';
import CreateAsset from './pages/create/assets/create-asset';
import DetailsCollectionPage from './pages/mint/details-collection';

const tagManagerArgs = {
  gtmId: 'GTM-5BJL989',
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize(process.env.REACT_APP_GA || '');

function App() {
  const { i18n } = useTranslation();
  const lang = useAppSelector(getLang);

  React.useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Navbar />
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <NavbarMobile />
        </Box>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/explore' element={<Explore />}></Route>
          <Route
            path='/product/:contractAddress/:tokenId'
            element={<ProductDetail />}
          ></Route>
          <Route path='/signUp' element={<SignUp />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/confirmEmail' element={<ValidateEmail />}></Route>
          <Route path='/forgotPassword' element={<ForgotPassword />}></Route>
          <Route path='/user/:username' element={<Profile />}></Route>
          <Route path='/wishlist' element={<WishListpage />}></Route>
          <Route path='/privacy-policy' element={<Privacy />}></Route>
          <Route path='/cookies-policy' element={<Cookies />}></Route>
          <Route path='/terms-of-use' element={<TermsOfUse />}></Route>
          <Route path='/notifications' element={<NotificationsPage />}></Route>
          <Route
            path='/something-went-wrong'
            element={<SomethingWentWrong />}
          ></Route>
          <Route path='/faq' element={<Faq />}></Route>
          <Route
            path='/create/collection'
            element={<CreateCollection />}
          ></Route>
          <Route path='/create/asset/:slug' element={<CreateAsset />}></Route>
          <Route path='/assets/:slug' element={<DetailsCollectionPage />}></Route>
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
