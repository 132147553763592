import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  alpha,
} from '@mui/material';
import React, { useState } from 'react';
import Background from '../home/components/background';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { theme } from '../../styles/material-theme';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import LoginService, {
  CommonError,
} from '../../services/auth-services/login-service';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { login } from '../../redux/session';
import { useAppDispatch } from '../../redux/hooks';
import LoadingBtn from '../../components/common/loading-btn';
import { errorMessage } from '../../config/helpers/utils';
import { useTranslation } from 'react-i18next';
import { getQueryParam } from '../../config/helpers/url';

const Login = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('please_enter_a_valid_email_address'))
      .required(t('this_field_is_required')),
    password: Yup.string().required(t('this_field_is_required')),
  });

  const onSubmit = async (values: typeof initialValues) => {
    setLoadingBtn(true);
    try {
      const response = await LoginService.login(values);
      if (response) {
        setLoadingBtn(false);
        dispatch(login(response));
        enqueueSnackbar(t('login_successfully'), {
          variant: 'success',
        });
        if (getQueryParam('redirect'))
          navigate(getQueryParam('redirect') || '/explore');
        else navigate('/explore');
      }
    } catch (e) {
      setLoadingBtn(false);
      const error = e as CommonError;
      if (
        error.error === 'nft_marketplace:2' ||
        error.error === 'nft_marketplace:3'
      ) {
        enqueueSnackbar(t('email_password_incorrect'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(errorMessage(e), {
          variant: 'error',
        });
      }
    }
  };

  return (
    <>
      <Background fixed={true} />
      <Box>
        <Container maxWidth='md'>
          <Paper
            sx={{
              margin: '22vh auto 0',
              maxWidth: 425,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: { xs: 4, md: 8 },
              paddingY: { xs: 12, md: 8 },
              background: alpha(theme.palette.background.paper, 0.75),
            }}
          >
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5' color='textPrimary'>
              {t('sign_in')}
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isValid }) => (
                <Form style={{ width: '100%' }}>
                  <Box marginTop={3}>
                    <Field
                      component={TextField}
                      type='text'
                      label={t('email')}
                      name='email'
                      fullWidth
                    />
                  </Box>
                  <Box marginTop={3}>
                    <Field
                      component={TextField}
                      type='password'
                      label={t('password')}
                      name='password'
                      fullWidth
                    />
                  </Box>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    disabled={!isValid || loadingBtn}
                    sx={{ mt: 3, mb: 2 }}
                    size='large'
                  >
                    <LoadingBtn showLoading={loadingBtn} />
                    {t('sign_in')}
                  </Button>
                </Form>
              )}
            </Formik>
            <Grid container>
              <Grid item xs={12} md={6} marginBottom={{ xs: 2, md: 0 }}>
                <Link
                  to='/forgotPassword'
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 14,
                  }}
                >
                  {t('forgot_password')}
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                <Link
                  to={
                    '/signUp?' +
                    (getQueryParam('redirect') ? `redirect=${getQueryParam('redirect')}` : '')
                  }
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 14,
                  }}
                >
                  {t('don_t_have_an_account_sign_up')}
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default Login;
