import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import StyleIcon from '@mui/icons-material/Style';
import MultilineBtn from '../../../../../components/common/multiline-btn';
import React from 'react';
import { Product } from '../../../../../types/product';
import { useTranslation } from 'react-i18next';

interface CollectionPropertiesProps {
  loading: boolean;
  product: Product;
}

const CollectionProperties = ({
  loading,
  product,
}: CollectionPropertiesProps) => {
  const { t } = useTranslation();

  const calculatePercent = (count: number, total: number) => {
    const result = (count * 0.01) % total;
    return result.toFixed();
  };

  const checkDescription = (traitCount: number, totalSupply: number) => {
    if (totalSupply) {
      return `${calculatePercent(traitCount, totalSupply)}% have this trait`;
    } else {
      return '';
    }
  };

  return (
    <Accordion
      expanded={true}
      disabled={true}
      sx={{ marginBottom: '24px!important' }}
    >
      <AccordionSummary>
        <StyleIcon sx={{ marginRight: 1 }} />
        <Typography color='textPrimary' fontWeight='bold'>
          {t('properties')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Skeleton variant='rectangular' width={430} height={220} />
        ) : (
          <Grid container spacing={1}>
            {product.traits === undefined ? (
              <Typography padding={1}>{t('no_properties')}</Typography>
            ) : (
              product.traits.map((property, i) => (
                <Grid item xs={6} md={4} key={i}>
                  <MultilineBtn
                    title={property.traitType}
                    subtitle={property.value}
                    description={checkDescription(
                      property.traitCount,
                      product.collection.stats.totalSupply
                    )}
                  />
                </Grid>
              ))
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CollectionProperties;
