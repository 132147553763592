import { Box, Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import React from 'react';

interface Props {
  img: string;
  description: string;
  onClick: () => void;
}

export const SmallNftCard = ({ img, description, onClick }: Props) => {
  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <Box overflow='hidden'>
          <CardMedia component='img' height='100' image={img} alt={'img'} />
        </Box>
        <CardContent
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SmallNftCard;
