import { Button, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import React from 'react';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

interface ShareBtnProps {
  text?: string;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  size?: 'small' | 'medium' | 'large' | undefined;
  minWidth?: number;
}

const ShareBtn = ({
  text,
  variant = 'outlined',
  size = 'small',
  minWidth,
}: ShareBtnProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const currentURL = window.location.href;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyLink = () => {
    setAnchorEl(null);
    navigator.clipboard.writeText(currentURL);
    enqueueSnackbar(t('link_copied'), {
      variant: 'success',
    });
  };

  const shareOnFacebook = () => {
    setAnchorEl(null);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentURL}`);
  };

  const shareOnTwitter = () => {
    setAnchorEl(null);
    window.open(
      `http://twitter.com/share?text=${t(
        'check_out_this_item_on_4all'
      )}&url=${currentURL}`
    );
  };

  return (
    <>
      <Button
        variant={variant}
        size={size}
        sx={{ minWidth: minWidth ? minWidth : 64 }}
        onClick={handleClick}
      >
        {text && text}
        <ShareIcon fontSize='small' sx={{ marginLeft: text ? 1 : 0 }} />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ mt: '45px', right: 16 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={copyLink}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          {t('copy_link')}
        </MenuItem>
        <Divider sx={{ margin: 0 }} />
        <MenuItem onClick={shareOnFacebook}>
          <ListItemIcon>
            <FacebookIcon />
          </ListItemIcon>
          {t('share_on_facebook')}
        </MenuItem>
        <Divider sx={{ margin: 0 }} />
        <MenuItem onClick={shareOnTwitter}>
          <ListItemIcon>
            <TwitterIcon />
          </ListItemIcon>
          {t('share_on_twitter')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ShareBtn;
