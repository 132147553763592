import { Box, Button, Drawer, Fab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { theme } from '../../../../styles/material-theme';
import { makeStyles } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExploreSideBar from './explore-side-bar';
import { useTranslation } from 'react-i18next';
import { Collection } from '../../../../services/products-service';

const useStyles = makeStyles(() => ({
  sideBarMain: {
    position: 'relative',
    height: 'calc(100vh - 68px)',
  },
  sideBarOpen: {
    width: 340,
    overflow: 'auto',
  },
  sideBarClosed: {
    width: 60,
  },
  sideBarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
    padding: '0px 16px',
    minHeight: 64,
    border: '1px solid #151b22',
    backgroundColor: '#262b2f',
    transition: 'background-color 0.3s ease',
    position: 'sticky',
    top: 0,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#33393e',
    },
  },
}));

interface Props {
  collections: Collection[];
}

const getIsMobile = () => window.innerWidth <= 900;

const ExploreFilters = ({ collections }: Props) => {
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const { t } = useTranslation();

  const [state, setState] = useState({
    left: false,
  });

  // Check Mobile Resize
  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // Drawer Filters
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  type Anchor = 'left';

  return (
    <Box>
      {isMobile ? (
        <>
          <Fab
            variant='extended'
            color='primary'
            onClick={toggleDrawer('left', true)}
            sx={{
              position: 'fixed',
              bottom: 32,
              left: '50%',
              right: 0,
              maxWidth: '200px',
              width: '100%',
              margin: '0 auto',
              zIndex: 999,
              transform: 'translate(-50%, 0)',
            }}
          >
            {t('filter')}
          </Fab>
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
            PaperProps={{
              style: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                top: 56,
              },
            }}
          >
            <>
              <Box
                sx={{ background: theme.palette.grey.A700 }}
                padding={1}
                display='flex'
                justifyContent='space-between'
              >
                <Button>{t('clear_all')}</Button>
                <Button onClick={toggleDrawer('left', false)}>
                  {t('done')}
                </Button>
              </Box>
              <ExploreSideBar collections={collections} />
            </>
          </Drawer>
        </>
      ) : (
        <Box
          className={`${classes.sideBarMain} ${
            isSideBarOpen ? classes.sideBarOpen : classes.sideBarClosed
          }`}
        >
          <header
            className={classes.sideBarHeader}
            onClick={() => setIsSideBarOpen(!isSideBarOpen)}
          >
            {isSideBarOpen && (
              <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <FilterListIcon />
                <Typography style={{ fontWeight: 'bold' }}>
                  {t('filter')}
                </Typography>
              </span>
            )}
            {isSideBarOpen ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </header>
          {isSideBarOpen && <ExploreSideBar collections={collections} />}
        </Box>
      )}
    </Box>
  );
};

export default ExploreFilters;
