import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  List,
  ListItem,
  Typography,
  Divider,
  Skeleton,
  Box,
  Button,
} from '@mui/material';
import { errorMessage, objectToArray } from '../../config/helpers/utils';
import { useSnackbar } from 'notistack';
import OrdersService, { Order } from '../../services/orders-service';
import { DateFormats, formatDateAs } from '../../config/helpers/date';
import { statusFormatter } from '../../config/helpers/formatter';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cleanExploreFilters } from '../../redux/filters';
import { useAppDispatch } from '../../redux/hooks';

const useStyles = makeStyles(() => ({
  listItem: {
    color: '#fff',
    position: 'relative',
    width: '100%',
    paddingTop: 30,
    paddingBottom: 30,
  },
  statusRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  date: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  textToken: {
    overflowWrap: 'break-word',
  },
}));

const OrderAssets = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    async function getOrders() {
      try {
        setLoading(true);
        const response = await OrdersService.getOrders();
        if (response) {
          setOrders(objectToArray(response.items));
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        enqueueSnackbar(errorMessage(e), {
          variant: 'error',
        });
      }
    }
    getOrders();
  }, [enqueueSnackbar]);

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          marginTop: 23,
        }}
      >
        <Skeleton width={'100%'} height={240} />
        <Divider />
        <Skeleton width={'100%'} height={240} />
        <Divider />
        <Skeleton width={'100%'} height={240} />
        <Divider />
      </div>
    );

  return (
    <List>
      {orders.length > 0 ? (
        orders.map((o, i) => (
          <Link
            key={i}
            to={`/product/${o.assetContractAddress}/${o.tokenId}`}
            style={{ textDecoration: 'none' }}
          >
            <ListItem>
              <div className={classes.listItem}>
                <Typography className={classes.textToken}>
                  <b>Token Id: </b> {o.tokenId}
                </Typography>
                <Typography>
                  <b>{t('usd_price')}: </b> {o.usdPrice}
                </Typography>
                <Typography>
                  <b>{t('eth_price')}: </b> {o.ethPrice}
                </Typography>
                <Typography>
                  <b>{t('seller_wallet_address')}: </b> {o.sellerWalletAddress}
                </Typography>
                <Typography className={classes.textToken}>
                  <b>{t('asset_contract_address')}: </b>{' '}
                  {o.assetContractAddress}
                </Typography>
                <Typography>
                  <b>{t('buyer_user_id')}: </b> {o.buyerUserId}
                </Typography>
                <Typography className={classes.date}>
                  {formatDateAs(o.placedDate, DateFormats.RegularDateTime)}
                </Typography>
                <Typography>
                  <b>{t('status')}: </b> {statusFormatter(o.status)}
                </Typography>
              </div>
            </ListItem>
            <Divider />
          </Link>
        ))
      ) : (
        <Box textAlign='center'>
          <Typography
            style={{
              textAlign: 'center',
              width: '100%',
              color: '#fff',
              margin: '32px 0',
            }}
          >
            {t('you_dont_any_order')}
          </Typography>
          <Link
            to={'/explore'}
            style={{ textDecoration: 'none' }}
            onClick={() => dispatch(cleanExploreFilters())}
          >
            <Button
              variant='contained'
              sx={{ margin: '0 auto', display: 'block' }}
            >
              {t('go_to_explore')}
            </Button>
          </Link>
        </Box>
      )}
    </List>
  );
};

export default OrderAssets;
