import { Box, Button, Grid, keyframes, Typography } from '@mui/material';
import security from '../../../assets/img/security.png';
import { theme } from '../../../styles/material-theme';
import shape from '../../../assets/img/shape-2.png';
import shape2 from '../../../assets/img/shape-3.png';
import shape3 from '../../../assets/img/shape-4.png';
import laptop from '../../../assets/img/laptop.png';
import phone from '../../../assets/img/phone.png';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';

const scale = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
`;

const Why4AllDegens = () => {
  const { t } = useTranslation();

  const items = [
    {
      name: t('more_accessible'),
      description: t('the_easiest_way_to_access'),
      btn: t('go_to_explore'),
      route: '/explore',
      shape: shape,
      img: laptop,
    },
    {
      name: t('collection_without_fraud'),
      description: t('all_our_collections_are_filtered'),
      btn: t('register'),
      route: '/register',
      shape: shape2,
      img: security,
    },
    {
      name: t('all_payment_methods'),
      description: t('bank_credit_card_cash'),
      btn: t('go_to_explore'),
      route: '/explore',
      shape: shape3,
      img: phone,
    },
  ];

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      borderRadius='12px'
      paddingX={{ xs: 0, md: 4 }}
      paddingY={{ xs: 4, md: 4 }}
      marginTop={{ xs: 10, md: 0 }}
      marginBottom={10}
      sx={{
        backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A100})`,
      }}
    >
      <Box width={'100%'} minHeight='60vh'>
        <Carousel
          animation='fade'
          duration={2000}
          interval={4000}
          height='60vh'
        >
          {items.map((item, i) => (
            <Grid container key={i} padding={4}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant='h4'
                  color='white'
                  fontWeight='bold'
                  marginBottom={2}
                >
                  {t('why_4alldegens')}
                </Typography>
                <Typography
                  variant='h3'
                  color='primary'
                  fontSize={{ xs: '2.5rem', md: '3rem' }}
                  fontWeight='bold'
                >
                  {item.name}
                </Typography>
                <Typography
                  color='white'
                  fontSize={{ xs: '1.15rem', md: '1.25rem' }}
                  marginY={3}
                >
                  {item.description}
                </Typography>

                <Link to={item.route} style={{ textDecoration: 'none' }}>
                  <Button
                    variant='contained'
                    size='large'
                    sx={{ marginTop: 2 }}
                  >
                    {item.btn}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundImage: `url(${item.shape})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100vh',
                    width: '200%',
                    top: '-10vh',
                    position: 'absolute',
                    animation: `${scale} 12s ease-in`,
                    animationFillMode: 'forwards',
                    opacity: '0.5',
                    zIndex: 1,
                    display: { xs: 'none', md: 'block' },
                  }}
                ></Box>
                <Box
                  sx={{
                    zIndex: 2,
                    animation: `${scale} 20s ease-in`,
                    animationFillMode: 'forwards',
                    position: 'relative',
                    display: { xs: 'none', md: 'block' },
                  }}
                >
                  <img
                    src={item.img}
                    alt='laptop'
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Why4AllDegens;
