import {
  Box,
  Button,
  Grid,
  keyframes,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { theme } from '../../../styles/material-theme';
import { useAppSelector } from '../../../redux/hooks';
import React, { useEffect, useState } from 'react';
import { getUser } from '../../../redux/session';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const SellYourCollection = () => {
  const isFullWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const [isUserLogged, setIsUserLogged] = useState<boolean>(false);
  const user = useAppSelector(getUser);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.userId === -1) {
      setIsUserLogged(false);
    } else {
      setIsUserLogged(true);
    }
  }, []);

  return (
    <Link
      to={isUserLogged ? '/create/collection' : '/login'}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Grid
        container
        borderRadius='12px'
        paddingX={4}
        paddingY={16}
        marginY={16}
        sx={{
          backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          backgroundSize: '400% 400%',
          animation: `${gradient} 15s ease infinite`,
          cursor: 'pointer',
          boxShadow: '0 0 0 3px rgb(255 255 255 / 6%)',
          '&:hover': {
            backgroundImage: `linear-gradient(145deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          },
        }}
      >
        <Grid item xs={12} md={10} display='flex' alignItems='center'>
          <Typography
            variant='h6'
            fontWeight='bold'
            fontSize='1.75rem'
            color={{ xs: 'white', md: 'black' }}
          >
            {t('now_you_can_create_your_own_collections_of_NFTs')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Box
            display='flex'
            justifyContent='flex-end'
            marginTop={{ xs: 6, md: 0 }}
          >
            <Button variant='contained' size='large' fullWidth={isFullWidth}>
              {t('create_nfts')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Link>
  );
};

export default SellYourCollection;
