import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from '../../config/helpers/utils';
import SignUpService from '../../services/auth-services/signup-service';
import { useTranslation } from 'react-i18next';
import { eventGATracker } from '../../config/helpers/analyticsEvents';
import { CommonError } from '../../services/auth-services/login-service';
import InjectScript from '../../components/common/script-injected';

const ValidateEmail = () => {
  const params = new URLSearchParams(window.location.search);
  const emailParam = params.get('email');
  const authCodeParam = params.get('code');
  const redirectParam = params.get('redirect');

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const offerConversion =
    '<script language="javascript" type="text/javascript">var afoffer_id = 1304; var afstatus=1;</script>';
  const rocketLab =
    '<script language="javascript" type="text/javascript" src="https://click.rocketlab.biz/track.js"></script>';

  useEffect(() => {
    const onConfirm = async () => {
      const request = {
        email: encodeURI(emailParam || ''),
        authCode: authCodeParam || '',
      };
      try {
        const response = await SignUpService.confirmEmail(request);
        if (response) {
          eventGATracker('Signup', `User registered ${request.email}`);

          //
          // PIXEL SCRIPT
          //
          (function () {
            try {
              (function () {
                /*
            //click.rocketlab.biz/success.jpg?offer_id=1300&afstatus=1
            */

                let mm_ri = String(Math.random());
                mm_ri = mm_ri.replace(/0\./g, '');

                let mm_protocol = location.protocol;
                if (mm_protocol != 'http:' && mm_protocol != 'https:') {
                  mm_protocol = 'http:';
                }

                const mm_el0 = document.createElement('img');
                let url0 =
                  '//click.rocketlab.biz/success.jpg?offer_id=1300&afstatus=1';
                url0 = url0.replace('[RANDOM_NUMBER]', mm_ri);
                mm_el0.width = 1;
                mm_el0.height = 1;
                mm_el0.style.display = 'none';
                mm_el0.src = url0;
                document.body.appendChild(mm_el0);
              })();
              (function () {
                try {
                  if (document.getElementById('mm_sync_back_ground')) return;
                  const frm = document.createElement('iframe');
                  frm.style.visibility = 'hidden';
                  frm.style.display = 'none';
                  frm.src =
                    'https://pixel.mathtag.com/sync/iframe?mt_uuid=2f1f622b-8fd1-4c00-aba8-b4e325c0d040&no_iframe=1&mt_adid=254735&source=mathtag';
                  frm.setAttribute('id', 'mm_sync_back_ground');
                  frm.title = 'MediaMath Advertising';

                  if (document.body) document.body.appendChild(frm);
                  else if (document.head) document.head.appendChild(frm);
                } catch (ex) {
                  const error = ex as CommonError;
                  document.createElement('img').src =
                    '//pixel.mathtag.com/error/img?error_domain=synciframe&what=' +
                    encodeURIComponent(error.message);
                }
              })();
            } catch (ex) {
              const error = ex as CommonError;
              document.createElement('img').src =
                '//pixel.mathtag.com/error/img?error_domain=wrap_js&what=' +
                encodeURIComponent(error.message);
            }
          })();

          //
          // END PIXEL SCRIPT
          //

          if (redirectParam) {
            navigate(`/login?redirect=${redirectParam}`);
          } else {
            navigate('/login');
          }
          enqueueSnackbar(t('email_confirmed_successfully'), {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar(errorMessage(e), {
          variant: 'error',
        });
        navigate('/');
      }
    };

    onConfirm();
  }, []);

  return (
    <Box textAlign='center'>
      <CircularProgress size='80' />
      <InjectScript script={offerConversion} />
      <InjectScript script={rocketLab} />
    </Box>
  );
};

export default ValidateEmail;
