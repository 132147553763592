import urls from '../../config/constants/urls';
import {
  toCamelCaseObject,
  toSnakeCaseObject,
} from '../../config/helpers/utils';
import { HttpService } from '../http-service';

const Api = new HttpService(true);

export interface ForgotPasswordRequest {
  email: string;
}

export interface ChangePasswordRequest {
  email: string;
  authCode: string;
  newPassword: string;
}

export default class ForgotPasswordService {
  static forgotPassword = async (request: ForgotPasswordRequest) => {
    try {
      const response = await Api.post<string>(urls.forgotPassword, request);
      return toCamelCaseObject<string>(response);
    } catch (e) {
      throw e;
    }
  };

  static changePassword = async (request: ChangePasswordRequest) => {
    try {
      const response = await Api.post<string>(
        urls.changePassword,
        toSnakeCaseObject(request)
      );
      return toCamelCaseObject<string>(response);
    } catch (e) {
      throw e;
    }
  };
}
