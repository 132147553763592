import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { GetStatusColor } from "../../styles/material-theme";
import { camelCase } from "../../config/helpers/string";

const useStyles = makeStyles(() =>
  createStyles({
    statusLabel: {
      minWidth: 94,
      padding: "3px 8px",
      borderRadius: 4,
      margin: "0 auto",
    },
  })
);

export interface StatusLabelProps {
  /** Indicating status text of component */
  status: string;
  /** Indicating custom className of component */
  className?: string;
  /** Children element of component */
  children?: never;
  /** Boolean indicating whether the component does not have a background */
  background?: boolean;
  /** Boolean indicating whether the component should render with close button */
  withClose?: boolean;
  /** Callback of onClose event */
  onClose?: (status: string) => void;
}

export const StatusLabel = (props: StatusLabelProps) => {
  const { status, className, background = true } = props;
  const classes = useStyles();
  const statusClassNames = clsx(classes.statusLabel, { "only-text": !background }, GetStatusColor(status), className);
  const cleanStatus = status ? camelCase(status).toUpperCase() : "";
  return <span className={statusClassNames}>{cleanStatus}</span>;
};

export default StatusLabel;
