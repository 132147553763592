import { Chip } from "@mui/material";
import { t } from "i18next";
import React from "react";

interface Props {
  height?: number;
  marginTop?: number;
  fontSize?: number;
  backgroundColor?: string;
  label: string;
}

const CardTag = ({ height, marginTop, fontSize, label, backgroundColor }: Props) => {
  const background = backgroundColor !== undefined ? backgroundColor : "#b9fa51";
  return (
    <Chip
      size="small"
      label={t(label)}
      color="primary"
      sx={{
        height: height || 17,
        fontSize: fontSize || "11px",
        fontWeight: "500",
        marginTop: marginTop || 0.5,
        color: "rgba(0, 0, 0, 0.87)",
        backgroundColor: background,
      }}
    />
  );
};

export default CardTag;
