/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Button, Chip, Tooltip } from '@mui/material';
import StatusLabel from '../../components/common/status';
import React from 'react';
import { getCountryImage, handleErrorImage } from './url';

export const amountFormatter = (amount: number, currency?: string, negative?: boolean) => {
  if (typeof amount === 'undefined' || amount === null) return '—';
  return (
    <strong>
      {negative && <span>-&nbsp;</span>}
      {amount.toFixed(2)}&nbsp;
      {currency && <span>{currency}</span>}
    </strong>
  );
};

export const operationFormatter = (operation: string) => {
  return (
    <Chip
      label={operation}
      style={{
        backgroundColor: operation === 'SENT' ? '#ff4b54' : '#3bd5a9',
        color: '#fff',
      }}
    />
  );
};

export const chipFormatter = (text: string) => {
  if (!text) {
    return '—';
  } else {
    return <Chip size='small' label={text} color='default' />;
  }
};

export const fundsFormatter = (text: string) => {
  return (
    <Chip
      size='small'
      label={text}
      style={{
        backgroundColor: text === 'CARD' ? '#1b6ba8' : '#179e78',
        color: '#fff',
      }}
    />
  );
};

export const emptyFormatter = (value: any) =>
  (typeof value === 'string' && value.trim() === '') || value === null || value === undefined
    ? '—'
    : value === 0
    ? '0'
    : value;

export const formatText = (text: string) => {
  const stringText = text ? text.toString() : '';
  return stringText.length >= 35 ? `${stringText.substr(0, 32)}...` : stringText;
};

export const idFormatter = (id: string) => {
  const reducedId = id.toString();
  return (
    <Tooltip title={id} placement='top'>
      <Button>{reducedId}</Button>
    </Tooltip>
  );
};

export const statusFormatter = (status: string) => <StatusLabel key={status} status={status} />;

export const getInitials = (string: string) => {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const currencyFormat = (num: number) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const countryFormatter = (countryIso: string, countryName = '') => {
  return (
    <Avatar
      src={getCountryImage(countryIso)}
      style={{ height: 24, width: 24 }}
      alt={countryName}
      variant={'square'}
      onError={handleErrorImage}
    />
  );
};
