import NotificationNavbarMenu from "../../pages/notifications/notification-navbar-menu";
import { CardMedia, Divider, ListItemIcon, ListItemText } from "@mui/material";
import { getIsAuthenticated, getUser, logout } from "../../redux/session";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { cleanExploreFilters } from "../../redux/filters";
import { getInitials } from "../../config/helpers/utils";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link, useNavigate } from "react-router-dom";
import { theme } from "../../styles/material-theme";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/img/logo.png";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import Search from "../common/search";
import Box from "@mui/material/Box";
import { useState } from "react";
import * as React from "react";

const menuPages = ["Explore"];

const useStyles = makeStyles(() => ({
  navbar: {
    boxShadow: "none",
    width: "100%",
    backgroundImage: "none",
    borderRadius: 0,
    background: theme.palette.background.default,
    zIndex: 1300,
    position: "fixed",
    paddingLeft: 20,
    paddingRight: 20,
  },
  navbarTolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  navbarIconBtnsContainer: {
    display: "flex",
    gap: 10,
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  // eslint-disable-next-line
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (isAuthenticated) {
      setAnchorElUser(event.currentTarget);
    } else {
      navigate("/login");
      return;
    }
  };

  const handleLogout = async () => {
    try {
      const response = await dispatch(logout());
      if (response) {
        navigate("/login");
      }
    } catch {}
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar className={classes.navbar}>
      <Toolbar className={classes.navbarTolbar} disableGutters>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <CardMedia
            component="img"
            height="45"
            image={logo}
            alt="4 All Degens"
            sx={{ display: { xs: "none", md: "flex", maxWidth: 160 } }}
          />
        </Link>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            gap: { md: 2 },
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Search />

          {menuPages.map((page) => (
            <Link
              to={page.toLowerCase()}
              style={{ textDecoration: "none", color: "white" }}
              onClick={() => dispatch(cleanExploreFilters())}
              key={page}
            >
              <Button onClick={handleCloseNavMenu} sx={{ my: 2, mx: 0, display: "block" }}>
                {page.toLocaleUpperCase()}
              </Button>
            </Link>
          ))}

          {isAuthenticated && <NotificationNavbarMenu />}

          <Box>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sx={{
                  border: isAuthenticated
                    ? `1px solid ${theme.palette.primary.main}`
                    : `1px solid ${theme.palette.grey.A400}`,
                  backgroundImage: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
                  "&:hover": {
                    backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
                  },
                  color: theme.palette.grey.A200,
                }}
              >
                {isAuthenticated ? (
                  <Typography variant="h6" color="primary">
                    {getInitials(user?.username)}
                  </Typography>
                ) : null}
              </Avatar>
            </IconButton>
            <Menu
              sx={{ mt: "45px", right: 16 }}
              id="menu-profile"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Link to={"/user/" + user.username} style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t("profile")}</ListItemText>
                </MenuItem>
              </Link>
              <Divider />
              <Link to={"/wishlist"} style={{ textDecoration: "none", color: "inherit" }}>
                <MenuItem onClick={handleCloseUserMenu}>
                  <ListItemIcon>
                    <FavoriteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t("wishlist")}</ListItemText>
                </MenuItem>
              </Link>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("logout")}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
