import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, Skeleton } from '@mui/material';
import React from 'react';
import { theme } from '../../styles/material-theme';
import { currencyFormat } from '../../config/helpers/formatter';
import { useTranslation } from 'react-i18next';
import CardTag from './card-tag';

interface Props {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  product?: any;
  loading: boolean;
}

export const NftCard: React.FC<Props> = (props) => {
  const { product, loading } = props;
  const { t } = useTranslation();

  return (
    <Box>
      {loading ? (
        <Skeleton width={'100%'} height={406} />
      ) : (
        <Card>
          <CardActionArea>
            {product?.imageUrl ? (
              <Box overflow='hidden'>
                <CardMedia component='img' height='335' image={product?.imageUrl} alt='green iguana' />
              </Box>
            ) : (
              <Box
                height={335}
                sx={{
                  background: theme.palette.grey['700'],
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography width='100%'>{t('content_not_available_yet')}</Typography>
              </Box>
            )}

            <CardContent>
              <Box display='flex'>
                <Box
                  display='flex'
                  textAlign='left'
                  maxWidth='50%'
                  sx={{
                    flex: '1 1 auto',
                    flexFlow: 'column',
                    justifyContent: 'start',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography color={theme.palette.text.primary} fontWeight='bold' fontSize='12px'>
                    {product?.name || 'No name'}
                  </Typography>
                  {product?.tags?.trending && <CardTag label='ADtrend' />}
                </Box>
                <Box
                  display='flex'
                  sx={{
                    flex: '1 1 auto',
                    flexFlow: 'column',
                    justifyContent: 'start',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography color={theme.palette.text.primary} fontWeight='bold' fontSize='14px'>
                    {product && product?.price?.sessionPrice !== 0
                      ? `${currencyFormat(product.price.sessionPrice) + ' ' + product.price.symbol}`
                      : t('not_for_sale')}
                  </Typography>
                  <Typography color={theme.palette.text.secondary} display='block' textAlign='right' fontSize='12px'>
                    {product && product?.price?.sessionPrice !== 0 ? `(${product.price.ethPrice} eth)` : ''}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </Box>
  );
};

export default NftCard;
