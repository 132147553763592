import AnimatedCard from '../../../components/common/animated-card';
import { Box, Button, Grid, Typography } from '@mui/material';
import { cleanExploreFilters } from '../../../redux/filters';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getUser } from '../../../redux/session';

const FirstScroll = () => {
  const [isUserLogged, setIsUserLogged] = useState<boolean>(false);
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.userId === -1) {
      setIsUserLogged(false);
    } else {
      setIsUserLogged(true);
    }
  }, []);

  return (
    <Grid
      container
      spacing={2}
      paddingTop={{ xs: 4, md: 8 }}
      marginBottom={{ xs: 4, md: 4 }}
      sx={{ minHeight: '98vh' }}
    >
      <Grid item xs={12} md={7}>
        <Typography
          color='textPrimary'
          variant='h1'
          fontWeight='bold'
          paddingTop={{ xs: 10, md: 22 }}
          fontSize={'3.15rem'}
        >
          {t('buy_collect_and_enjoy')}
        </Typography>
        <Typography
          color='textSecondary'
          variant='body1'
          fontWeight='bold'
          paddingTop={2}
        >
          {t('a_curated_collection_of_nf')}
        </Typography>

        <Box paddingTop={4}>
          <Link
            to='/explore'
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => dispatch(cleanExploreFilters())}
          >
            <Button variant='contained' size='large' sx={{ marginRight: 3 }}>
              {t('explore')}
            </Button>
          </Link>
          <Link
            to={isUserLogged ? '/create/collection' : '/login?redirect=/create/collection'}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button variant='outlined' size='large'>
              {t('create_nfts')}
            </Button>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <AnimatedCard />
      </Grid>
    </Grid>
  );
};

export default FirstScroll;
