import { CircularProgress } from '@mui/material';
import React from 'react';

export interface LoadingBtnProps {
  showLoading: boolean;
}

const LoadingBtn = ({ showLoading }: LoadingBtnProps) => {
  return (
    <>
      {showLoading ? (
        <CircularProgress size={14} sx={{ marginRight: 2 }} />
      ) : null}
    </>
  );
};

export default LoadingBtn;
