import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import NftCardLite from '../../components/common/nft-card-lite';
import { ProductLite } from '../../services/products-service';
import ProfileService from '../../services/profile-service';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { cleanExploreFilters } from '../../redux/filters';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { theme } from '../../styles/material-theme';

const OwnedAssets = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [assets, setAssets] = useState<ProductLite[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const username = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  useEffect(() => {
    async function getAssets() {
      try {
        setLoading(true);
        const response = await ProfileService.getAssets(username);
        if (response) {
          setAssets(response.items);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
    getAssets();
  }, [enqueueSnackbar, username]);

  if (loading)
    return (
      <Grid container marginTop={1} spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton width={'100%'} height={406} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton width={'100%'} height={406} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton width={'100%'} height={406} />
        </Grid>
      </Grid>
    );

  return (
    <Grid container marginTop={1} spacing={2}>
      {assets.length > 0 ? (
        assets.map((p, i) => (
          <Grid key={i} item xs={12} lg={4}>
            <Link
              to={`/product/${p?.assetContractAddress}/${p.tokenId}`}
              style={{ textDecoration: 'none', position: 'relative' }}
            >
              {p.tags.onSale && (
                <Box
                  sx={{
                    backgroundImage: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.main})`,
                    color: theme.palette.text.primary,
                    fontWeight: 'bold',
                    padding: '6px 12px',
                    borderRadius: 1,
                    position: 'absolute',
                    top: 18,
                    left: 18,
                    zIndex: 1,
                    border: '0.5px solid #fff',
                    fontSize: '14px',
                  }}
                >
                  <LocalOfferIcon
                    sx={{
                      fontSize: '16px',
                      verticalAlign: 'sub',
                      marginRight: 0.75,
                    }}
                  />
                  NFT {t('ON_SALE')}
                </Box>
              )}
              <NftCardLite product={p} loading={false} />
            </Link>
          </Grid>
        ))
      ) : (
        <Box textAlign='center' marginX='auto'>
          <Typography
            style={{
              textAlign: 'center',
              width: '100%',
              color: '#fff',
              margin: '32px 0',
            }}
          >
            {t('no_items_to_display')}
          </Typography>
          <Link
            to={'/explore'}
            style={{ textDecoration: 'none' }}
            onClick={() => dispatch(cleanExploreFilters())}
          >
            <Button variant='contained' sx={{ my: 2, mx: 4, display: 'block' }}>
              {t('go_to_explore')}
            </Button>
          </Link>
        </Box>
      )}
    </Grid>
  );
};

export default OwnedAssets;
