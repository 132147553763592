/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { theme } from '../../styles/material-theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: { minWidth: 600, minHeight: 450 },
  },
  content: {
    display: 'flex',
    flex: 1,
    padding: '0 !important',
    margin: '0 auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  action: {
    minWidth: 150,
  },
}));

interface SimpleDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  content: React.ReactNode;
  actions: {
    onClick: () => void;
    text: string;
    color?: string;
    variant?: 'text' | 'contained';
  }[];
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const SimpleDialog: React.FC<SimpleDialogProps> = (props) => {
  const { isOpen, onClose, content, actions, title, maxWidth = false } = props;
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullWidth={fullScreen}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={onClose}
      aria-labelledby='simple-dialog-title'
      aria-describedby='simple-dialog-description'
    >
      <Box className={classes.root}>
        {title && <DialogTitle id={'simple-dialog-title'}>{title}</DialogTitle>}
        <DialogContent className={classes.content}>
          <Box className={classes.content}>{content}</Box>
        </DialogContent>
        {actions.length > 0 && (
          <DialogActions className={classes.actions}>
            {actions.map((action) => (
              <Button
                key={`simple-dialog-action-${action.text}`}
                onClick={action.onClick}
                className={actions.length === 1 ? classes.action : ''}
                color={action.color ? (action.color as never) : 'secondary'}
                variant={
                  action.variant ? (action.variant as never) : 'contained'
                }
              >
                {action.text}
              </Button>
            ))}
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

export default SimpleDialog;
