import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Skeleton,
  Typography,
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import { theme } from '../../styles/material-theme';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ICollectionCardProps {
  loading: boolean;
  name: string;
  img: string;
  items: number;
  status: 'open' | 'closed' | 'for_review';
}

const CollectionCard = ({
  loading,
  name,
  img,
  items,
  status,
}: ICollectionCardProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      {loading ? (
        <Skeleton width={'100%'} height={263} />
      ) : (
        <Card>
          <CardActionArea>
            {img ? (
              <Box overflow='hidden'>
                <CardMedia
                  component='img'
                  height='263'
                  image={img}
                  alt='logo'
                />
              </Box>
            ) : (
              <Box
                height={263}
                sx={{
                  background: theme.palette.grey['700'],
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography width='100%'>
                  {t('content_not_available_yet')}
                </Typography>
              </Box>
            )}

            <CardContent>
              <Box display='flex'>
                <Box
                  display='flex'
                  textAlign='left'
                  sx={{
                    flex: '1 1 auto',
                    flexFlow: 'column',
                    justifyContent: 'start',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography fontWeight='bold' fontSize={13}>
                    {name}
                  </Typography>
                  <Box
                    display='flex'
                    width='100%'
                    justifyContent='space-between'
                  >
                    <Typography fontSize={12}>{items} items</Typography>
                    <Chip
                      icon={
                        status === 'closed' ? (
                          <PublicIcon sx={{ fontSize: '12px !important' }} />
                        ) : (
                          <LockIcon sx={{ fontSize: '12px !important' }} />
                        )
                      }
                      color={
                        status === 'closed'
                          ? 'primary'
                          : status === 'for_review'
                          ? 'info'
                          : 'secondary'
                      }
                      label={
                        status === 'closed'
                          ? t('published')
                          : status === 'for_review'
                          ? t('pending')
                          : t('unpublished')
                      }
                      size='small'
                      sx={{
                        fontSize: 11,
                        height: 18,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </Box>
  );
};

export default CollectionCard;
