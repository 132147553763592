import urls from "../config/constants/urls";
import { buildPathWithParams } from "../config/helpers/url";
import { toCamelCaseObject, toSnakeCaseObject } from "../config/helpers/utils";
import { GenericListWithPagination, PaginationResquest } from "../types/services";
import { HttpService } from "./http-service";

const Api = new HttpService(true);

export interface RNotification {
  notification_id: number;
  user_id: number;
  title: string;
  content: string;
  created_date: string;
  seen_date: string;
  type: string;
  redirect_url: string;
}

export interface INotification {
  notificationId: number;
  userId: number;
  title: string;
  content: string;
  createdDate: string;
  seenDate: string;
  type: string;
  redirectUrl: string;
}

export interface NotificationIds {
  notificationIds: number[];
}

export interface RNotificationIds {
  notification_ids: number[];
}

export type RNotifications = GenericListWithPagination<RNotification>;
export type Notifications = GenericListWithPagination<INotification>;

export default class NotificationsService {
  static getNotifications = async (pageAndSize?: PaginationResquest) => {
    try {
      const response = await Api.get<RNotifications[]>(buildPathWithParams(urls.getNotifications, {}), {
        ...pageAndSize,
      });
      return toCamelCaseObject<Notifications>(response);
    } catch (e) {
      throw e;
    }
  };

  static setNotificationAsRead = async (notifications: NotificationIds) => {
    try {
      return await Api.post(
        urls.setNotificationAsRead,
        toSnakeCaseObject<RNotificationIds>(notifications),
      );
    } catch (e) {
      throw e;
    }
  };

}
