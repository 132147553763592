import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';
import { Chip } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

export interface PublishLabelProps {
  status: string;
}

const PublishLabel = ({ status }: PublishLabelProps) => {
  return (
    <Chip
      icon={
        status === 'closed' ? (
          <PublicIcon sx={{ fontSize: '12px !important' }} />
        ) : (
          <LockIcon sx={{ fontSize: '12px !important' }} />
        )
      }
      color={
        status === 'closed'
          ? 'primary'
          : status === 'for_review'
          ? 'info'
          : 'secondary'
      }
      label={
        status === 'closed'
          ? t('published')
          : status === 'for_review'
          ? t('pending')
          : t('unpublished')
      }
      size='small'
      sx={{
        fontSize: 11,
        height: 18,
      }}
    />
  );
};

export default PublishLabel;
