import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { theme } from '../../styles/material-theme';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ChartItem {
  date: string;
  price: number;
}

export interface BarChartProps {
  dataChart: ChartItem[];
}

const BarChart = ({ dataChart }: BarChartProps) => {
  const labels = dataChart.map((data) => data.date);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: dataChart.map((data) => data.price),
        backgroundColor: theme.palette.primary.main,
        barThickness: 50,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Bar options={options} data={data}/>;
};

export default BarChart;
