import urls from '../config/constants/urls';
import { buildPathWithParams } from '../config/helpers/url';
import { toCamelCaseObject, toSnakeCaseObject } from '../config/helpers/utils';
import { PagingAsset, Profile, RProfile } from '../types/profile';
import { userExists } from './auth-services/signup-service';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export default class ProfileService {
  static getProfile = async (username: string) => {
    try {
      const response = await Api.get<RProfile>(
        buildPathWithParams(urls.getProfile, { username })
      );
      return toCamelCaseObject<Profile>(response);
    } catch (e) {
      throw e;
    }
  };

  static updateProfile = async (profile: Profile) => {
    try {
      const response = await Api.post<string>(
        urls.updateProfile,
        toSnakeCaseObject(profile)
      );
      return toCamelCaseObject(response);
    } catch (e) {
      throw e;
    }
  };

  static getAssets = async (username: string) => {
    try {
      const response = await Api.get<string>(
        buildPathWithParams(urls.getAssets, { username })
      );
      return toCamelCaseObject<PagingAsset>(response);
    } catch (e) {
      throw e;
    }
  };

  static isUserLogged = async (userId: string) => {
    try {
      const response = await Api.get<string>(
        buildPathWithParams(urls.isUserLogged, { userId })
      );
      return toCamelCaseObject<userExists>(response);
    } catch (e) {
      throw e;
    }
  };
}
