import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Collection } from '../../../services/products-service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getInitials } from '../../../config/helpers/utils';
import { theme } from '../../../styles/material-theme';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import React from 'react';
import {
  FormControl,
  TextField,
  Button,
  InputAdornment,
  Avatar,
} from '@mui/material';
import {
  getExploreFilter,
  getExploreFiltersLoading,
  setExploreFilterCollection,
} from '../../../redux/filters';

const useStyles = makeStyles(() => ({
  filterCollectionsFields: {
    height: 260,
    overflow: 'hidden',
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  filterCollectionsScroll: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    overflow: 'auto',
    height: '100%',
    paddingRight: 15,
  },
  activeItem: {
    color: '#fff',
    fontWeight: 'bold',
  },
  item: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 200,
    textAlign: 'start',
  },
}));

interface Props {
  collections: Collection[];
}

export const FilterCollection = ({ collections }: Props) => {
  const filtersLoading = useAppSelector(getExploreFiltersLoading);
  const [loading, setLoading] = useState<boolean>(false);
  const filters = useAppSelector(getExploreFilter);
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const classes = useStyles();

  useEffect(() => {
    setLoading(filtersLoading || false);
  }, [filtersLoading]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value);
  };

  const getFilteredList = () => {
    if (search) {
      return collections.filter((c: Collection) => c.name.includes(search));
    }
    return collections;
  };

  const handleSubmit = (product: string) => {
    dispatch(setExploreFilterCollection(product));
  };

  const isActive = (label: string) => {
    if (filters.collection === label) return true;
    return false;
  };

  return (
    <>
      <FormControl fullWidth>
        <TextField
          id='outlined-text'
          label={t('search')}
          type='text'
          disabled={loading}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
        />
      </FormControl>
      <div className={classes.filterCollectionsFields}>
        <div className={classes.filterCollectionsScroll}>
          {collections !== []
            ? getFilteredList().map((item, i) => (
                <Button
                  endIcon={isActive(item.name) && <CheckCircleIcon />}
                  key={i}
                  onClick={() => handleSubmit(item.name)}
                  disabled={loading}
                >
                  <Avatar alt={item.name} style={{ marginRight: 10 }}>
                    {getInitials(item.name)}
                  </Avatar>
                  <span
                    className={`${classes.item} ${
                      isActive(item.name) ? classes.activeItem : ''
                    }`}
                  >
                    {item.name}
                  </span>
                </Button>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default FilterCollection;
