import urls from '../config/constants/urls';
import { toCamelCaseObject } from '../config/helpers/utils';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export interface TopCollection {
  items: TopCollectionItem[];
}

export interface TopCollectionItem {
  assetContractAddress: string;
  collection: { name: string; slug: string };
  name: string;
  id: string;
  imageUrl: string;
  price: {
    symbol: string;
    decimals: number;
    ethPrice: number;
    usdPrice: number;
    sessionPrice: number;
  };
  score: number;
  tokenId: string;
  weeklyScore: number;
}

export default class TopCollectionsService {
  static getTopCollectionsByWeek = async () => {
    try {
      const response = await Api.get<string>(urls.getTopCollectionByWeek);
      return toCamelCaseObject<TopCollection>(response);
    } catch (e) {
      throw e;
    }
  };
}
