import { persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { crashReport } from './middlewares';
import sessionReducer from './session';
import globalReducer from './global';
import filtersReducer from './filters';

const sessionPersistConfig = {
  key: '__NFT__SESSION__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const globalPersistConfig = {
  key: '__NFT__GLOBAL__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const filtersPersistConfig = {
  key: '__NFT__FILTER__',
  vesrion: 0,
  storage: storage,
  blacklist: [],
};

const store = configureStore({
  reducer: {
    session: persistReducer(sessionPersistConfig, sessionReducer),
    global: persistReducer(globalPersistConfig, globalReducer),
    filters: persistReducer(filtersPersistConfig, filtersReducer),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(crashReport),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

// export store singleton instance
export default store;
