import NotificationsService, { INotification, NotificationIds } from "../../services/notifications-service";
import { Box, Button, Menu, MenuItem, IconButton } from "@mui/material";
import { errorMessage } from "../../config/helpers/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { t } from "i18next";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
}));

interface Props {
  setListAll: (flag: boolean) => void;
  setSeen: () => void;
  listAll: boolean;
  notifications: INotification[];
}

const NotificationHeader = (props: Props) => {
  const { listAll, setListAll, notifications, setSeen } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsReadSelected = async () => {
    try {
      const payload: NotificationIds = {
        notificationIds: notifications.map((item) => item.notificationId),
      };
      await NotificationsService.setNotificationAsRead(payload);
      setSeen();
    } catch (error) {
      enqueueSnackbar(errorMessage(error), {
        variant: "error",
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" gap={2}>
        <Button style={{ width: 100 }} variant={listAll ? "contained" : "text"} onClick={() => setListAll(true)}>
          {t("label_all")}
        </Button>
        <Button style={{ width: 100 }} variant={listAll ? "text" : "contained"} onClick={() => setListAll(false)}>
          {t("label_unread")}
        </Button>
      </Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleMarkAsReadSelected}>{t('label_mark_see_all')}</MenuItem>
      </Menu>
    </Box>
  );
};

export default NotificationHeader;
