import React from 'react';
import clsx from 'clsx';
import { theme } from '../../styles/material-theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  roundedTitle: {
    display: 'flex',
    color: '#b9fa52',
    height: theme.spacing(3),
    width: 'fit-content',
    borderRadius: 12,
    padding: `7px 12px 6px 12px`,
    letterSpacing: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    backgroundImage: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
    fontSize: 10,
    '&.close': {
      fontSize: '1rem',
      marginLeft: '0.5rem',
      border: 'none',
      cursor: 'pointer',
      background: 'transparent',
    },
  },

  small: {
    height: theme.spacing(2),
    borderRadius: 16,
    padding: ` 3px 12px 2px 12px`,
    '&.close': {
      fontSize: '.8rem',
    },
  },
}));

export interface RoundedTitleProps {
  /** Indicating text label of component */
  title: string;
  /** Indicating custom className of component */
  className?: string;
  /** Indicating custom style of component */
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: object;
  /** Boolean indicating whether the component should render with close button */
  withClose?: boolean;
  /** Boolean indicating whether the component render in small size */
  small?: boolean;
  /** Callback of onClose event */
  onClose?: (title: string) => void;
}

export const RoundedTitle = (props: RoundedTitleProps) => {
  const {
    title,
    className,
    style,
    onClose,
    withClose = !!onClose,
    small,
  } = props;
  const [visible, setVisible] = React.useState(true);
  const cls = useStyles();
  const classes = clsx(cls.roundedTitle, className, { [cls.small]: small });

  return visible ? (
    <div className={classes} style={style}>
      {title}
      {withClose && (
        <button
          type='button'
          className='close'
          aria-label='Close'
          onClick={() => {
            setVisible(false);
            onClose && onClose(title);
          }}
        ></button>
      )}
    </div>
  ) : null;
};

export default RoundedTitle;
