import { createSelector } from 'reselect';

import { FilterPriceState, FilterState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: FilterState = {
  explore: {},
  loading: false,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setExploreFilterPrice: (state, action: PayloadAction<FilterPriceState>) => {
      delete state.explore.min;
      delete state.explore.max;
      delete state.explore.currency;
      state.explore.min = action.payload.min;
      state.explore.max = action.payload.max;
      state.explore.currency = action.payload.currency;
    },
    setExploreFilterCollection: (state, action: PayloadAction<string>) => {
      state.explore['collection'] = action.payload;
    },
    setExploreFilterQuery: (state, action: PayloadAction<string>) => {
      state.explore['query'] = action.payload;
    },
    setExploreOrder: (state, action: PayloadAction<string>) => {
      state.explore['order'] = action.payload;
    },
    setExploreFiltersLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    cleanExploreFilters: (state) => {
      state.explore = {};
    },
    cleanExploreFiltersPrice: (state) => {
      delete state.explore.min;
      delete state.explore.max;
    },
    cleanExploreFiltersCollection: (state) => {
      delete state.explore['collection'];
    },
    cleanExploreFiltersQuery: (state) => {
      delete state.explore['query'];
    },
    cleanExploreFiltersOrder: (state) => {
      delete state.explore['order'];
    },
  },
});

// Selectors
const getFilter = (state: RootState): FilterState => state.filters;
const getFiltersLoading = (state: RootState): FilterState => state.filters;

export const getExploreFilter = createSelector(
  getFilter,
  (filters) => filters.explore
);

export const getExploreFiltersLoading = createSelector(
  getFiltersLoading,
  (filters) => filters.loading
);

const { actions, reducer } = filtersSlice;

export const {
  setExploreFilterPrice,
  setExploreFilterCollection,
  setExploreFilterQuery,
  setExploreFiltersLoading,
  cleanExploreFilters,
  cleanExploreFiltersPrice,
  cleanExploreFiltersQuery,
  cleanExploreFiltersCollection,
  cleanExploreFiltersOrder,
  setExploreOrder,
} = actions;

export default reducer;
