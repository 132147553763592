import {
  Box,
  Container,
  Tab,
  Tabs,
  Paper,
  alpha,
  Grid,
  Button,
} from '@mui/material';
import AvatarComponent from '../../components/common/avatar-component';
import InfoComponent from '../../components/common/info-component';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import RoundedTitle from '../../components/common/rounded-title';
import SimpleDialog from '../../components/common/simple-dialog';
import ProfileService from '../../services/profile-service';
import { emptyProfile, Profile } from '../../types/profile';
import { getQueryParam } from '../../config/helpers/url';
import ShareBtn from '../../components/common/share-btn';
import TabPanel from '../../components/common/tab-panel';
import Background from '../home/components/background';
import UpdateInformation from './update-information';
import { theme } from '../../styles/material-theme';
import { useAppSelector } from '../../redux/hooks';
import EditIcon from '@mui/icons-material/Edit';
import { useLocation } from 'react-router-dom';
import { getUser } from '../../redux/session';
import MyCollections from './my-collections';
import OrderAssets from './orders-assets';
import OwnedAssets from './owned-assets';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import Card404 from '../error/404-card';
import { t } from 'i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '68px',
  },
  info: {
    marginTop: '100px',
    marginBottom: 16,
    position: 'relative',
    background: alpha(theme.palette.background.paper, 0.5),
    backdropFilter: 'blur(2px)',
    padding: '20px 20px 0 20px',
  },
  orders: {
    marginBottom: 100,
  },
  editButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  avatar: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundImage: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
    '&:hover': {
      backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
    },
    color: theme.palette.grey.A200,
    width: 180,
    height: 180,
    marginBottom: 20,
  },
}));

const Profilepage = () => {
  const [showUserNotExists, setShowUserNotExists] = useState<boolean>(false);
  const [isUserLogged, setIsUserLogged] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>(emptyProfile);
  const [forceReload, setForceReload] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector(getUser);
  const location = useLocation();
  const classes = useStyles();

  const username = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  const tabsHandleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (getQueryParam('tab')) {
      const value = getQueryParam('tab');
      if (value == 'orders') {
        setTabValue(2);
      }
    }
  }, []);

  useEffect(() => {
    async function getProfile() {
      try {
        setLoading(true);
        const response = await ProfileService.getProfile(username);
        if (response) {
          setProfile(response);
          setShowUserNotExists(false);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        setShowUserNotExists(true);
      }
    }

    async function checkIsUserLogged() {
      if (user.userId === -1) {
        setIsUserLogged(false);
      } else {
        try {
          setLoading(true);
          const response = await ProfileService.isUserLogged(
            user.userId.toString()
          );
          if (response) {
            setIsUserLogged(response.exists);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (username === user.username) {
      checkIsUserLogged();
    }
    getProfile();
  }, [forceReload, enqueueSnackbar, username]);

  const handleSuccess = () => {
    setShowDialog(false);
    setForceReload(true);
  };

  return (
    <Container className={classes.root} maxWidth='lg'>
      <Background fixed={true} />
      {showUserNotExists ? (
        <Card404 />
      ) : (
        <>
          <Paper className={classes.info} elevation={5}>
            <Box marginBottom={2.5}>
              <RoundedTitle title='information' />
            </Box>
            <Box>
              <Box className={classes.editButton}>
                {isUserLogged ? (
                  <>
                    <Button
                      variant='outlined'
                      size='medium'
                      sx={{ marginRight: 2, minWidth: 48 }}
                      onClick={() => setShowDialog(true)}
                    >
                      <EditIcon fontSize='small' />
                    </Button>
                    <ShareBtn variant='outlined' size='medium' minWidth={48} />
                  </>
                ) : (
                  <ShareBtn variant='outlined' size='medium' minWidth={48} />
                )}
              </Box>

              <Grid container>
                <Grid item xs={12} md={3}>
                  <AvatarComponent
                    loading={loading}
                    username={profile.username}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <InfoComponent loading={loading} profile={profile} />
                </Grid>
              </Grid>
            </Box>
            <Tabs
              value={tabValue}
              onChange={tabsHandleChange}
              sx={{
                borderRadius: 0,
                background: `transparent`,
              }}
            >
              {isUserLogged ? <Tab label={t('my_collections')} /> : null}
              <Tab label={t('collected')} />
              {isUserLogged ? <Tab label={t('orders')} /> : null}
            </Tabs>
          </Paper>
          <Box className={classes.orders}>
            <TabPanel value={tabValue} index={isUserLogged ? 1 : 0}>
              <OwnedAssets />
            </TabPanel>
            {isUserLogged ? (
              <>
                <TabPanel value={tabValue} index={2}>
                  <OrderAssets />
                </TabPanel>
                <TabPanel value={tabValue} index={0}>
                  <MyCollections username={username} />
                </TabPanel>
              </>
            ) : null}
          </Box>

          <SimpleDialog
            title='Update Information'
            isOpen={showDialog}
            onClose={() => setShowDialog(false)}
            content={
              <UpdateInformation profile={profile} onSuccess={handleSuccess} />
            }
            maxWidth={'md'}
            actions={[]}
          />
        </>
      )}
    </Container>
  );
};

export default Profilepage;
