import { alpha, Box, Grid, Typography } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { theme } from '../../../styles/material-theme';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { gradient } from '../../../styles/keyframes';

const NftsInSeconds = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      marginY={{ xs: 6, md: 16 }}
      paddingX={2}
      paddingY={10}
      sx={{
        backgroundImage: `linear-gradient(45deg, ${alpha(
          theme.palette.secondary.main,
          0.5
        )}, ${alpha(theme.palette.common.black, 0.5)}, ${alpha(
          theme.palette.secondary.main,
          0.5
        )}, ${alpha(theme.palette.common.black, 0.5)})`,
        backgroundSize: '400% 400%',
        animation: `${gradient} 15s ease infinite`,
        backdropFilter: 'blur(2px)',
        borderRadius: 1.5,
      }}
    >
      <Grid item xs={12}>
        <Box textAlign='center' marginBottom={8}>
          <Typography variant='h4' color='textPrimary' fontWeight='bold'>
            {t('nf_ts_in_seconds')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} md={3}>
        <Box textAlign='center' padding={2}>
          <AccountCircleRoundedIcon sx={{ fontSize: 40 }} color='primary' />
          <Typography
            color={theme.palette.text.primary}
            fontWeight='bold'
            marginY={1}
          >
            {t('create_your_accont')}
          </Typography>
          <Typography color={theme.palette.text.secondary}>
            {t('create_your_own_account')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box textAlign='center' padding={2}>
          <SearchRoundedIcon sx={{ fontSize: 40 }} color='primary' />
          <Typography
            color={theme.palette.text.primary}
            fontWeight='bold'
            marginY={1}
          >
            {t('search_your_nft')}
          </Typography>
          <Typography color={theme.palette.text.secondary}>
            {t('make_a_pic_in_any')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box textAlign='center' padding={2}>
          <ShoppingCartRoundedIcon sx={{ fontSize: 40 }} color='primary' />
          <Typography
            color={theme.palette.text.primary}
            fontWeight='bold'
            marginY={1}
          >
            {t('purchase_with_usd')}
          </Typography>
          <Typography color={theme.palette.text.secondary}>
            {t('buy_cell_your_favorite')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box textAlign='center' padding={2}>
          <LockRoundedIcon sx={{ fontSize: 40 }} color='primary' />
          <Typography
            color={theme.palette.text.primary}
            fontWeight='bold'
            marginY={1}
          >
            {t('we_hold_it_safely_for_you')}
          </Typography>
          <Typography color={theme.palette.text.secondary}>
            {t('we_will_secure_your')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NftsInSeconds;
