import urls from '../config/constants/urls';
import { buildPathWithParams } from '../config/helpers/url';
import { toCamelCaseObject } from '../config/helpers/utils';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export interface PriceHistoryPagination {
  items: PriceHistory[];
}

export interface PriceHistory {
  totalPrice: number;
  eventType: string;
  price: {
    symbol: string;
    address: string;
    decimals: number;
    ethPrice: number;
    usdPrice: number;
    sessionPrice: number;
  };
  createdDate: string;
}

export const emptyPriceHistory: PriceHistory = {
  totalPrice: 0,
  eventType: '',
  price: {
    symbol: '',
    address: '',
    decimals: 0,
    ethPrice: 0,
    usdPrice: 0,
    sessionPrice: 0,
  },
  createdDate: '',
};

export const emptyPriceHistoryPagination: PriceHistoryPagination = {
  items: [emptyPriceHistory],
};

export default class PriceHistoryService {
  static getPriceHistory = async (
    addressContractId: string,
    tokenId: string,
    page: string
  ) => {
    try {
      const response = await Api.get<PriceHistoryPagination>(
        buildPathWithParams(urls.getPriceHistory, {
          addressContractId,
          tokenId,
          page,
        })
      );
      return toCamelCaseObject<PriceHistoryPagination>(response);
    } catch (e) {
      throw e;
    }
  };
}
