import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Product } from '../../types/product';
import WishListService, { WishItem } from '../../services/wishlist-service';
import { useAppSelector } from '../../redux/hooks';
import { getIsAuthenticated } from '../../redux/session';
import { useNavigate } from 'react-router-dom';

interface Props {
  product: Product;
  onAdd: () => void;
  onRemove: () => void;
}

const FavoriteBtn = ({ product, onAdd, onRemove }: Props) => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const [wishList, setWishList] = useState<WishItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    async function getUserWishList() {
      try {
        const response = await WishListService.getUserWishList();
        setWishList(Object.values(response));
      } catch (error) {}
    }
    getUserWishList();
  }, []);

  const handleAdd = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    try {
      const payload: WishItem = {
        tokenId: product.tokenId,
        assetContractAddress: product.assetContract.address,
      };
      await WishListService.addToWishList(payload);
      setWishList((wishList) => [...wishList, payload]);
      onAdd();
    } catch (error) {}
  };

  const handleRemove = async () => {
    try {
      const payload: WishItem = {
        tokenId: product.tokenId,
        assetContractAddress: product.assetContract.address,
      };
      await WishListService.removeToWishList(payload);
      setWishList((wishList) =>
        wishList.filter((item) => item.tokenId !== product.tokenId)
      );
      onRemove();
    } catch (error) {}
  };

  const handleCheckIfFavorite = (productId: string) => {
    return wishList.filter((item) => item.tokenId == productId).length > 0;
  };

  return (
    <>
      {handleCheckIfFavorite(product.tokenId) ? (
        <Button variant='outlined' size='small' onClick={handleRemove}>
          <FavoriteIcon fontSize='small' />
        </Button>
      ) : (
        <Button variant='outlined' size='small' onClick={handleAdd}>
          <FavoriteBorderIcon fontSize='small' />
        </Button>
      )}
    </>
  );
};

export default FavoriteBtn;
