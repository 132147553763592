import { ProductLite } from '../services/products-service';

export interface RProfile {
  email: string;
  username: string;
  country: string;
}
export interface Profile {
  email: string;
  username: string;
  country: string;
}

export interface PagingAsset {
  items: ProductLite[];
  page: number;
  size: number;
  total: number;
}

export const emptyProfile: Profile = {
  email: '',
  username: '',
  country: '',
};
