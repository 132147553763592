import urls from '../config/constants/urls';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export interface Country {
  isocode: string;
  name: string;
}

export const countries = [
  { isocode: 'AF', name: 'Afghanistan' },
  { isocode: 'BR', name: 'Brazil' },
  { isocode: 'CL', name: 'Chile' },
  { isocode: 'CN', name: 'China' },
  { isocode: 'CO', name: 'Colombia' },
  { isocode: 'IN', name: 'India' },
  { isocode: 'MX', name: 'Mexico' },
  { isocode: 'NG', name: 'Nigeria' },
  { isocode: 'SE', name: 'Sweden' },
  { isocode: 'UG', name: 'Uganda' },
  { isocode: 'UK', name: 'United Kingdom' },
  { isocode: 'UY', name: 'Uruguay' },
];

export default class CountryService {
  static getCountries = async () => {
    try {
      const response = await Api.get<Country[]>(urls.getAllCountries);
      return response;
    } catch (e) {
      throw e;
    }
  };
}
