import NotificationsService, {
  INotification,
  Notifications,
} from '../../services/notifications-service';
import { Box, Container, Typography, Paper, List, alpha } from '@mui/material';
import NotificationSkeleton from './notification-skeleton';
import { PaginationResquest } from '../../types/services';
import { errorMessage } from '../../config/helpers/utils';
import { getIsAuthenticated } from '../../redux/session';
import NotificationDialog from './notification-dialog';
import NotificationHeader from './notification-header';
import Background from '../home/components/background';
import { theme } from '../../styles/material-theme';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import NotificationRow from './notification-row';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '108px',
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: 28,
    color: '#fff',
    marginBottom: 50,
  },
  container: {
    maxWidth: 991,
  },
  waypoint: {
    '& span': {
      height: 1,
      display: 'block',
      width: '100%',
    },
  },
}));

const initialState = {
  page: 1,
  size: 10,
  total: 0,
  items: [],
};

const NotificationsPage = () => {
  const [paginationInfo, setPaginationInfo] =
    useState<Notifications>(initialState);
  const [notificationSelected, setNotificationSelected] =
    useState<INotification | null>(null);
  const [page, setPage] = useState<PaginationResquest>({ page: 1, size: 15 });
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const [listAll, setListAll] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    async function getNotifications() {
      if (!isAuthenticated) {
        navigate('/login');
        return;
      }
      try {
        setLoading(true);
        const response = await NotificationsService.getNotifications(page);
        setPaginationInfo(response);
        setNotifications((notifications) => {
          if (notifications.length > 0) {
            return [...notifications, ...response.items];
          }
          return response.items;
        });
      } catch (error) {
        enqueueSnackbar(errorMessage(error), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
    getNotifications();
  }, [page]);

  const handleFilterItems = () => {
    if (listAll) return notifications;
    return notifications.filter((item) => item.seenDate == null);
  };

  const setSeen = (id?: number) => {
    if (id) {
      setNotifications((items) =>
        items.map((item) => {
          if (item.notificationId == id) {
            return { ...item, seenDate: 'read' };
          } else {
            return item;
          }
        })
      );
    } else {
      setNotifications((items) =>
        items.map((item) => ({ ...item, seenDate: 'read' }))
      );
    }
  };

  const handleShowNotification = (item: INotification) => {
    setNotificationSelected(item);
    setShowDialog(true);
  };

  return (
    <Box display='flex' className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <Background fixed={true} />
        <Typography variant='h1' className={classes.headerTitle}>
          {t('label_notifications')}
        </Typography>

        <Box
          sx={{
            background: alpha(theme.palette.background.paper, 0.5),
            backdropFilter: 'blur(2px)',
            padding: 4,
            borderRadius: 4,
            marginBottom: 10,
          }}
        >
          <NotificationHeader
            setListAll={setListAll}
            listAll={listAll}
            notifications={notifications}
            setSeen={setSeen}
          />
          <Paper elevation={5} sx={{ p: 2 }}>
            {handleFilterItems().length == 0 && !loading && (
              <Box
                height={150}
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Typography variant='h6'>
                  {t('dont_have_notifications')}
                </Typography>
              </Box>
            )}
            <List sx={{ width: '100%' }}>
              {handleFilterItems().map((item, i) => (
                <Box key={i}>
                  <NotificationRow
                    item={item}
                    setSeen={setSeen}
                    onOpen={handleShowNotification}
                  />
                </Box>
              ))}
              {loading && (
                <>
                  <NotificationSkeleton />
                  <NotificationSkeleton />
                </>
              )}
            </List>
          </Paper>
          <Box height={2}>
            <Waypoint
              onEnter={() => {
                if (
                  paginationInfo.total !== 0 &&
                  paginationInfo.items.length !== 0
                ) {
                  setLoading(true);
                  setPage({ ...page, page: page.page + 1 });
                }
              }}
            />
          </Box>
        </Box>
      </Container>
      {notificationSelected && (
        <NotificationDialog
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          notification={notificationSelected}
        />
      )}
    </Box>
  );
};

export default NotificationsPage;
