import urls from '../../config/constants/urls';
import { buildPathWithParams } from '../../config/helpers/url';
import {
  toCamelCaseObject,
  toSnakeCaseObject,
} from '../../config/helpers/utils';
import { HttpService } from '../http-service';

const Api = new HttpService(true);

export interface SignUpRequest {
  username: string;
  email: string;
  password: string;
  country: string;
  redirect_url: string;
}

export interface ConfirmEmailRequest {
  email: string;
  authCode: string;
}

export interface userExists {
  exists: boolean;
  message: string;
  ok: boolean;
}

export default class SignUpService {
  static signUp = async (request: SignUpRequest) => {
    try {
      const response = await Api.post<string>(urls.signUp, request);
      return toCamelCaseObject<string>(response);
    } catch (e) {
      throw e;
    }
  };

  static confirmEmail = async (request: ConfirmEmailRequest) => {
    try {
      return await Api.post<string>(
        urls.confirmEmail,
        toSnakeCaseObject(request)
      );
    } catch (e) {
      throw e;
    }
  };

  static userExists = async (username: string) => {
    try {
      const response = await Api.get<string>(
        buildPathWithParams(urls.userExists, {
          username,
        })
      );
      return toCamelCaseObject<userExists>(response);
    } catch (e) {
      throw e;
    }
  };

  static emailExists = async (email: string) => {
    try {
      const response = await Api.get<string>(
        buildPathWithParams(urls.emailExists, {
          email,
        })
      );
      return toCamelCaseObject<userExists>(response);
    } catch (e) {
      throw e;
    }
  };

  static resendValidation = async (email: string) => {
    try {
      return await Api.post<string>(urls.resendValidation, { email: email });
    } catch (e) {
      throw e;
    }
  };
}
