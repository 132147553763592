import { Box, Button, IconButton, Typography } from '@mui/material';
import { Formik, Form, Field, FieldArray } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';

interface AddPropertiesProps {
  onSubmit: (items: ITrait[]) => void;
  traits: ITrait[];
}

export interface ITraits {
  attributes: ITrait[];
}

export interface ITrait {
  traitType: string;
  value: string;
}

const emptyTraits: ITraits = {
  attributes: [
    {
      traitType: '',
      value: '',
    },
  ],
};

const AddProperties = ({ onSubmit, traits }: AddPropertiesProps) => {
  const [initialValues] = useState<ITraits>(
    traits.length === 0 ? emptyTraits : { attributes: traits }
  );

  return (
    <Box paddingY={1}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values.attributes);
        }}
        render={({ values }) => (
          <Form style={{ minWidth: 340 }}>
            <Box display='flex' marginBottom={1}>
              <Typography fontWeight='bold' marginLeft={1}>
                Type
              </Typography>
              <Typography fontWeight='bold' marginLeft={21}>
                Value
              </Typography>
            </Box>
            <FieldArray
              name='attributes'
              render={(arrayHelpers) => (
                <div>
                  {values.attributes &&
                    values.attributes.map((trait, index) => (
                      <Box display='flex' key={index} marginBottom={2} gap={1}>
                        <Field
                          component={TextField}
                          type='text'
                          name={`attributes.${index}.traitType`}
                          placeholder='Glasses'
                        />
                        <Field
                          component={TextField}
                          type='text'
                          name={`attributes.${index}.value`}
                          placeholder='Black'
                        />
                        <IconButton
                          sx={{ padding: '12px 15px' }}
                          disabled={index === 0}
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                  <Box marginY={4}>
                    <Button
                      variant='outlined'
                      size='large'
                      onClick={() =>
                        arrayHelpers.push({ traitType: '', value: '' })
                      }
                      disabled={values.attributes.length === 9}
                    >
                      Add More
                    </Button>
                  </Box>

                  <Box textAlign='center' marginBottom={4}>
                    <Button
                      type='submit'
                      size='large'
                      variant='contained'
                      sx={{ marginBottom: 4 }}
                      fullWidth
                    >
                      Save
                    </Button>
                  </Box>
                </div>
              )}
            />
          </Form>
        )}
      />
    </Box>
  );
};

export default AddProperties;
