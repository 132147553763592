import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import astropayLogo from '../../assets/img/astropay-logo.svg';
import pixLogo from '../../assets/img/pix-logo.png';
import { t } from 'i18next';
import React from 'react';

interface Props {
  paymentSelected: (payment: 'IX' | 'WA') => void;
}

interface IPaymentData {
  name: string;
  logo: string;
  id: 'IX' | 'WA';
}

const paymentData: IPaymentData[] = [
  {
    name: 'PIX',
    logo: pixLogo,
    id: 'IX',
  },
  {
    name: 'ASTROPAY',
    logo: astropayLogo,
    id: 'WA',
  },
];

const SelectPaymentMethod = ({ paymentSelected }: Props) => {
  return (
    <Box p={3}>
      <Typography pb={4} pt={1} fontWeight='bold' fontSize={18}>
        {t('select_your_payment_method')}
      </Typography>
      <Grid container spacing={3}>
        {paymentData.map((payment, i) => (
          <Grid item xs={6} key={i}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => paymentSelected(payment.id)}>
                <Box
                  sx={{
                    background: '#fff',
                    width: '100%',
                    height: 160,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={payment.logo}
                    alt='PIX'
                    style={{ width: 'calc(100% - 32px)' }}
                  />
                </Box>

                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SelectPaymentMethod;
