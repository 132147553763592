import { Order } from "../services/orders-service";
import { ProductLite } from "../services/products-service";

export interface PaginationResquest {
  page: number;
  size: number;
}

export interface GenericListWithPagination<T> extends PaginationResquest {
  items: T[];
  total: number;
}

export const emptyProductListWithPagination: GenericListWithPagination<ProductLite> = {
  items: [],
  page: 1,
  size: 10,
  total: 0,
};

export const emptyOrderListWithPagination: GenericListWithPagination<Order> = {
  items: [],
  page: 1,
  size: 10,
  total: 0,
};
