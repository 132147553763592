import { Container } from '@mui/material';
import React from 'react';
import Footer from '../../../components/footer/footer';
import { makeStyles } from '@mui/styles';
import Background from '../../home/components/background';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    paddingTop: '100px',
    paddingBottom: '100px',
  },
}));

const Privacy = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <Background fixed={true} />
        <h1>Privacy Policy</h1>

        <p>This Website collects some Personal Data from its Users.</p>
        <p>Data Controller and Owner</p>
        <p>
          Galactic Mark Limited, a limited liability company incorporated in
          Hong Kong, whose registered office is at Flat E, 8/F, Far East
          Mansion, 5-6 Middle Road, Tsimshatsui, Kowloon, Hong Kong
        </p>
        <p>Owner contact email: ola@4alldegens.com</p>
        <p>Types of Data collected</p>

        <p>
          The owner does not provide a list of Personal Data types collected.
        </p>
        <p>
          Other Personal Data collected may be described in other sections of
          this privacy policy or by dedicated explanation text contextually with
          the Data collection. The Personal Data may be freely provided by the
          User, or collected automatically when using this Application. Any use
          of Cookies - or of other tracking tools - by this Application or by
          the owners of third party services used by this Application, unless
          stated otherwise, serves to identify Users and remember their
          preferences, for the sole purpose of providing the service required by
          the User. Failure to provide certain Personal Data may make it
          impossible for this Application to provide its services.
        </p>
        <p>
          Users are responsible for any Personal Data of third parties obtained,
          published or shared through this Application and confirm that they
          have the third party&apos;s consent to provide the Data to the Owner.
        </p>
        <p>Mode and place of processing the Data</p>
        <p>Methods of processing</p>
        <p>
          The Data Controller processes the Data of Users in a proper manner and
          shall take appropriate security measures to prevent unauthorized
          access, disclosure, modification, or unauthorized destruction of the
          Data. The Data processing is carried out using computers and/or IT
          enabled tools, following organizational procedures and modes strictly
          related to the purposes indicated. In addition to the Data Controller,
          in some cases, the Data may be accessible to certain types of persons
          in charge, involved with the operation of the site (administration,
          sales, marketing, legal, system administration) or external parties
          (such as third party technical service providers, mail carriers,
          hosting providers, IT companies, communications agencies) appointed,
          if necessary, as Data Processors by the Owner. The updated list of
          these parties may be requested from the Data Controller at any time.
        </p>
        <p>Place</p>
        <p>
          The Data is processed at the Data Controller&apos;s operating offices
          and in any other places where the parties involved with the processing
          are located. For further information, please contact the Data
          Controller.
        </p>
        <p>Retention time</p>
        <p>
          The Data is kept for the time necessary to provide the service
          requested by the User, or stated by the purposes outlined in this
          document, and the User can always request that the Data Controller
          suspend or remove the data.
        </p>
        <p>The use of the collected Data</p>
        <p>
          The Data concerning the User is collected to allow the Owner to
          provide its services, as well as for the following purposes: Hosting
          and backend infrastructure and Infrastructure monitoring.
        </p>
        <p>
          The Personal Data used for each purpose is outlined in the specific
          sections of this document.
        </p>
        <p>Detailed information on the processing of Personal Data</p>
        <p>
          Personal Data is collected for the following purposes and using the
          following services:
        </p>
        <p>Hosting and backend infrastructure</p>
        <p>
          This type of service has the purpose of hosting data and files that
          enable this Application to run and be distributed as well as to
          provide a ready-made infrastructure to run specific features or parts
          of this Application. Some of these services work through
          geographically distributed servers, making it difficult to determine
          the actual location where the Personal Data are stored.
        </p>
        <p>Firebase (Firebase, Inc.)</p>
        <p>Firebase is a hosting and backend service provided by Google Inc.</p>
        <p>
          Personal Data collected: various types of Data as specified in the
          privacy policy of the service.
        </p>
        <p>Place of processing: US – Privacy Policy</p>
        <p>Infrastructure monitoring</p>
        <p>
          This type of service allows this Application to monitor the use and
          behavior of its components so its performance, operation, maintenance
          and troubleshooting can be improved. Which Personal Data are processed
          depends on the characteristics and mode of implementation of these
          services, whose function is to filter the activities of this
          Application.
        </p>
        <p>New Relic (New Relic)</p>
        <p>
          New Relic is a monitoring service provided by New Relic Inc. The way
          New Relic is integrated means that it filters all traffic of this
          Application, i.e., communication between the Application and the
          User&apos;s browser or device, while also allowing analytical data on
          this Application to be collected.
        </p>
        <p>
          Personal Data collected: various types of Data as specified in the
          privacy policy of the service.
        </p>
        <p>Place of processing: US – Privacy Policy</p>
        <p>Crashlytics (Crashlytics, Inc.)</p>
        <p>Crashlytics is a monitoring service provided by Crashlytics, Inc.</p>
        <p>
          Personal Data collected: various types of Data as specified in the
          privacy policy of the service.
        </p>
        <p>Place of processing: US – Privacy Policy</p>
        <p>Additional information about Data collection and processing</p>
        <p>Legal action</p>
        <p>
          The User&apos;s Personal Data may be used for legal purposes by the
          Data Controller, in Court or in the stages leading to possible legal
          action arising from improper use of this Application or the related
          services. The User declares to be aware that the Data Controller may
          be required to reveal personal data upon request of public
          authorities.
        </p>
        <p>Additional information about User&apos;s Personal Data</p>
        <p>
          In addition to the information contained in this privacy policy, this
          Application may provide the User with additional and contextual
          information concerning particular services or the collection and
          processing of Personal Data upon request.
        </p>
        <p>System logs and maintenance</p>
        <p>
          For operation and maintenance purposes, this Application and any third
          party services may collect files that record interaction with this
          Application (System logs) or use for this purpose other Personal Data
          (such as IP Address).
        </p>
        <p>Information not contained in this policy</p>
        <p>
          More details concerning the collection or processing of Personal Data
          may be requested from the Data Controller at any time. Please see the
          contact information at the beginning of this document.
        </p>
        <p>The rights of Users</p>
        <p>
          Users have the right, at any time, to know whether their Personal Data
          has been stored and can consult the Data Controller to learn about
          their contents and origin, to verify their accuracy or to ask for them
          to be supplemented, cancelled, updated or corrected, or for their
          transformation into anonymous format or to block any data held in
          violation of the law, as well as to oppose their treatment for any and
          all legitimate reasons. Requests should be sent to the Data Controller
          at the contact information set out above.
        </p>
        <p>
          This Application does not support \u201cDo Not Track\u201d requests.
          To determine whether any of the third party services it uses honor the
          \u201cDo Not Track\u201d requests, please read their privacy policies.
        </p>
        <p>Changes to this privacy policy</p>
        <p>
          The Data Controller reserves the right to make changes to this privacy
          policy at any time by giving notice to its Users on this page. It is
          strongly recommended to check this page often, referring to the date
          of the last modification listed at the bottom. If a User objects to
          any of the changes to the Policy, the User must cease using this
          Application and can request that the Data Controller remove the
          Personal Data. Unless stated otherwise, the then-current privacy
          policy applies to all Personal Data the Data Controller has about
          Users.
        </p>
        <p>Information about this privacy policy</p>
        <p>
          The Data Controller is responsible for this privacy policy, prepared
          starting from the modules provided by Iubenda and hosted on
          Iubenda&apos;s servers.
        </p>
        <p>Definitions and legal references</p>
        <p>Personal Data (or Data)</p>
        <p>
          Any information regarding a natural person, a legal person, an
          institution or an association, which is, or can be, identified, even
          indirectly, by reference to any other information, including a
          personal identification number.
        </p>
        <p>Usage Data</p>
        <p>
          Information collected automatically from this Application (or third
          party services employed in this Application), which can include: the
          IP addresses or domain names of the computers utilized by the Users
          who use this Application, the URI addresses (Uniform Resource
          Identifier), the time of the request, the method utilized to submit
          the request to the server, the size of the file received in response,
          the numerical code indicating the status of the server&apos;s answer
          (successful outcome, error, etc.), the country of origin, the features
          of the browser and the operating system utilized by the User, the
          various time details per visit (e.g., the time spent on each page
          within the Application) and the details about the path followed within
          the Application with special reference to the sequence of pages
          visited, and other parameters about the device operating system and/or
          the User&apos;s IT environment.
        </p>
        <p>User</p>
        <p>
          The individual using this Application, which must coincide with or be
          authorized by the Data Subject, to whom the Personal Data refers.
        </p>
        <p>Data Subject</p>
        <p>The legal or natural person to whom the Personal Data refers.</p>
        <p>Data Processor (or Data Supervisor)</p>
        <p>
          The natural person, legal person, public administration or any other
          body, association or organization authorized by the Data Controller to
          process the Personal Data in compliance with this privacy policy.
        </p>
        <p>Data Controller (or Owner)</p>
        <p>
          The natural person, legal person, public administration or any other
          body, association or organization with the right, also jointly with
          another Data Controller, to make decisions regarding the purposes, and
          the methods of processing of Personal Data and the means used,
          including the security measures concerning the operation and use of
          this Application. The Data Controller, unless otherwise specified, is
          the Owner of this Application.
        </p>
        <p>This Application</p>
        <p>
          The hardware or software tool by which the Personal Data of the User
          is collected.
        </p>
        <p>Legal information</p>
        <p>
          Notice to European Users: this privacy statement has been prepared in
          fulfillment of the obligations under Art. 10 of EC Directive n.
          95/46/EC, and under the provisions of Directive 2002/58/EC, as revised
          by Directive 2009/136/EC, on the subject of Cookies.
        </p>
        <p>This privacy policy relates solely to this Website.</p>
      </Container>
      <Footer />
    </>
  );
};

export default Privacy;
