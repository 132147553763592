export interface RProduct {
  asset_contract: {
    address: string;
    asset_contract_type: string;
    created_date: string;
    name: string;
  };
  opensea_id: number;
  token_id: string;
  image_url: string;
  image_preview_url: string;
  image_thumbnail_url: string;
  description: string;
  name: string;
  creator: {
    username: string;
    profile_img_url: string;
    address: string;
  };
  owner: {
    profile_img_url: string;
    address: string;
    user: {
      username: string;
    };
  };
  transfer_fee: number;
  contract: {
    address: string;
    asset_contract_type: string;
    created_date: string;
    name: string;
  };
  price: {
    symbol: string;
    address: string;
    eth_price: number;
    usd_price: number;
  };
  collection: {
    banner_img_url: string;
    description: string;
    name: string;
    image_url: string;
    external_url: string;
    twitter_username: string;
    instagram_username: string;
    discord_url: string;
    stats: {
      total_supply: number;
    };
  };
  price_history: {
    date: string;
    price: {
      symbol: string;
      address: string;
      eth_price: number;
      usd_price: number;
    };
  }[];
  item_activity: {
    event: string;
    price: {
      symbol: string;
      address: string;
      eth_price: number;
      usd_price: number;
    };
    acc_from: string;
    acc_to: string;
    date: string;
  }[];
  traits: {
    trait_type: string;
    trait_count: number;
    value: string;
  }[];
  score: number;
  weekly_score: number;
  orders: {
    created_date: string;
    current_price: number;
    payment_token_contract: {
      symbol: string;
      address: string;
      decimals: number;
      eth_price: number;
      usd_price: number;
    };
  }[];
}

export interface Product {
  assetContract: {
    address: string;
    assetContractType: string;
    createdDate: string;
    name: string;
  };
  assetContractAddress: string;
  openseaId: number;
  tokenId: string;
  imageUrl: string;
  imagePreviewUrl: string;
  imageThumbnailUrl: string;
  description: string;
  name: string;
  creator: {
    username: string;
    profileImgUrl: string;
    address: string;
  };
  topOwnerships: [
    {
      owner: {
        profileImgUrl: string;
        address: string;
        user: {
          username: string;
        };
      };
    }
  ];
  transferFee: number;
  contract: {
    address: string;
    assetContractType: string;
    createdDate: string;
    name: string;
  };
  price: {
    symbol: string;
    address: string;
    ethPrice: number;
    usdPrice: number;
    sessionPrice: number;
  };
  collection: {
    bannerImgUrl: string;
    description: string;
    name: string;
    imageUrl: string;
    externalUrl: string;
    twitterUsername: string;
    instagramUsername: string;
    discordUrl: string;
    stats: {
      totalSupply: number;
    };
  };
  priceHistory: {
    date: string;
    price: {
      symbol: string;
      address: string;
      ethPrice: number;
      usdPrice: number;
    };
  }[];
  itemActivity: {
    event: string;
    price: {
      symbol: string;
      address: string;
      ethPrice: number;
      usdPrice: number;
    };
    accFrom: string;
    accTo: string;
    date: string;
  }[];
  traits: {
    traitType: string;
    traitCount: number;
    value: string;
  }[];
  score: number;
  favorites: number;
  weeklyScore: number;
  orders: {
    createdDate: string;
    currentPrice: number;
    paymentTokenContract: {
      symbol: string;
      address: string;
      decimals: number;
      ethPrice: number;
      usdPrice: number;
    };
  }[];
}

export const emptyProduct: Product = {
  assetContract: {
    address: '',
    assetContractType: '',
    createdDate: '',
    name: '',
  },
  assetContractAddress: '',
  openseaId: 0,
  tokenId: '',
  imageUrl: '',
  imagePreviewUrl: '',
  imageThumbnailUrl: '',
  description: '',
  name: '',
  creator: {
    username: '',
    profileImgUrl: '',
    address: '',
  },
  topOwnerships: [
    {
      owner: {
        profileImgUrl: '',
        address: '',
        user: {
          username: '',
        },
      },
    },
  ],
  transferFee: 0,
  contract: {
    address: '',
    assetContractType: '',
    createdDate: '',
    name: '',
  },
  price: {
    symbol: '',
    address: '',
    ethPrice: 0,
    usdPrice: 0,
    sessionPrice: 0,
  },
  collection: {
    bannerImgUrl: '',
    description: '',
    name: '',
    imageUrl: '',
    externalUrl: '',
    twitterUsername: '',
    instagramUsername: '',
    discordUrl: '',
    stats: {
      totalSupply: 0,
    },
  },
  priceHistory: [
    {
      date: '',
      price: {
        symbol: '',
        address: '',
        ethPrice: 0,
        usdPrice: 0,
      },
    },
  ],
  itemActivity: [
    {
      event: '',
      price: {
        symbol: '',
        address: '',
        ethPrice: 0,
        usdPrice: 0,
      },
      accFrom: '',
      accTo: '',
      date: '',
    },
  ],
  traits: [
    {
      traitType: '',
      traitCount: 0,
      value: '',
    },
  ],
  score: 0,
  favorites: 0,
  weeklyScore: 0,
  orders: [
    {
      createdDate: '',
      currentPrice: 0,
      paymentTokenContract: {
        symbol: '',
        address: '',
        decimals: 0,
        ethPrice: 0,
        usdPrice: 0,
      },
    },
  ],
};
