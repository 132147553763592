import {
  Avatar,
  Box,
  Grid,
  List,
  ListItemButton,
  Typography,
  alpha,
  Button,
} from '@mui/material';
import TopCollectionsService, {
  TopCollectionItem,
} from '../../../services/top-collection-service';
import { cleanExploreFilters, setExploreOrder } from '../../../redux/filters';
import { currencyFormat } from '../../../config/helpers/formatter';
import { theme } from '../../../styles/material-theme';
import { useAppDispatch } from '../../../redux/hooks';
import { gradient } from '../../../styles/keyframes';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';

const TopCollections = () => {
  const [items, setItems] = useState<TopCollectionItem[]>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    async function getTopCollections() {
      try {
        const response = await TopCollectionsService.getTopCollectionsByWeek();
        if (response) {
          setItems(response.items);
        }
      } catch (e) {}
    }
    getTopCollections();
  }, []);

  return (
    <Grid
      container
      marginY={{ xs: 4, md: 16 }}
      sx={{
        backgroundImage: `linear-gradient(${alpha(
          theme.palette.secondary.main,
          0.5
        )}, ${alpha(theme.palette.common.black, 0.5)})`,
        backdropFilter: 'blur(2px)',
        padding: { xs: 0, md: 6 },
        paddingY: { xs: 6 },
        borderRadius: 1.5,
      }}
    >
      <Grid item xs={12}>
        <Box textAlign='center' marginBottom={4}>
          <Typography
            variant='h4'
            color='textPrimary'
            fontWeight='bold'
            paddingX={2}
          >
            {t('last_week_s_top_collections')}
          </Typography>
        </Box>
      </Grid>

      <List
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'wrap',
          flexDirection: 'column',
          height: { xs: '920px', lg: '420px' },
          backgroundImage: `linear-gradient(45deg, ${alpha(
            theme.palette.secondary.main,
            0.7
          )}, ${theme.palette.common.black}, ${theme.palette.secondary.main}, ${
            theme.palette.common.black
          })`,
          backgroundSize: '400% 400%',
          animation: `${gradient} 15s ease infinite`,
          backdropFilter: 'blur(2px)',
          padding: { xs: 0, md: 4 },
          borderRadius: 1.5,
        }}
      >
        {items &&
          items.map((item, i) => (
            <Link
              to={`/product/${item.assetContractAddress}/${item.tokenId}`}
              style={{ textDecoration: 'none' }}
              key={i}
            >
              <ListItemButton
                sx={{
                  maxWidth: { xs: '100%', lg: '474px' },
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 auto',
                  paddingX: { xs: 2, md: 2 },
                  paddingY: 2,
                  overflow: 'hidden',
                }}
              >
                <Box
                  display='flex'
                  flex='auto'
                  alignItems='center'
                  maxWidth='440px'
                  margin='0 auto'
                >
                  <Typography
                    color={theme.palette.text.primary}
                    fontWeight='bold'
                  >
                    {i + 1}
                  </Typography>
                  <Avatar
                    src={item.imageUrl}
                    alt='logo'
                    sx={{ width: 50, height: 50, marginX: 2 }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignSelf: 'stretch',
                      flex: '1 1 auto',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <Typography
                      color={theme.palette.text.secondary}
                      fontSize={14}
                    >
                      {t('name')}
                    </Typography>
                    <Typography
                      color={theme.palette.text.primary}
                      fontWeight='bold'
                      marginTop='1px'
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'break-spaces',
                        marginRight: { xs: 0, md: 2 },
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      alignSelf: 'stretch',
                      display: 'flex',
                      flex: '0 0 auto',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      maxWidth: '40%',
                      overflow: 'hidden',
                      textAlign: 'right',
                    }}
                  >
                    <Typography
                      color={theme.palette.text.secondary}
                      fontSize={14}
                    >
                      {t('price')}
                    </Typography>
                    <Typography
                      color={theme.palette.text.primary}
                      fontWeight='bold'
                    >
                      {currencyFormat(item.price.sessionPrice)}{' '}
                      {item.price.symbol}
                    </Typography>
                  </Box>
                </Box>
              </ListItemButton>
            </Link>
          ))}
      </List>

      <Box display='flex' flexGrow='1' justifyContent='center' marginTop={5}>
        <Link to='/explore' style={{ textDecoration: 'none' }}>
          <Button
            variant='contained'
            onClick={() => {
              dispatch(cleanExploreFilters());
              dispatch(setExploreOrder('pop'));
            }}
          >
            {t('go_to_most_viewed')}
          </Button>
        </Link>
      </Box>
    </Grid>
  );
};

export default TopCollections;
