import { theme } from '../../../styles/material-theme';
import bgShape from '../../../assets/img/shape-1.png';
import { alpha, Box } from '@mui/material';
import React from 'react';

export interface BackgroundProps {
  fixed?: boolean;
}

const Background = ({ fixed }: BackgroundProps) => {
  return (
    <Box
      sx={{
        position: fixed ? 'fixed' : 'absolute',
        backdropFilter: 'blur(2px)',
        width: '100%',
        height: '100vh',
        zIndex: '-1',
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          width: '60%',
          height: '100vh',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          backgroundImage: `radial-gradient(farthest-corner at 15px 36px, ${alpha(
            theme.palette.secondary.main,
            0.8
          )} 0%, #4433ee00 60%)`,
        }}
      ></Box>
      <Box
        sx={{
          width: '80%',
          height: '100vh',
          position: 'absolute',
          top: '10vh',
          right: '0',
          zIndex: -1,
          backgroundImage: `radial-gradient(farthest-corner at 77vw 60vh, ${alpha(
            theme.palette.secondary.light,
            0.6
          )} 0%, transparent 40%)`,
        }}
      ></Box>
      <Box
        sx={{
          backgroundImage: `url(${bgShape})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: '80vh',
          position: 'absolute',
          top: { xs: '70vh', md: '85vh' },
          left: { xs: '-50vw', md: '-30vw' },
          width: '100%',
          zIndex: -1,
          transform: 'rotate(45deg)',
          opacity: 0.2,
        }}
      ></Box>
    </Box>
  );
};

export default Background;
