import { Container } from '@mui/material';
import React from 'react';
import Footer from '../../../components/footer/footer';
import { makeStyles } from '@mui/styles';
import Background from '../../home/components/background';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    paddingTop: 100,
    paddingBottom: 100,
  },
  li: {
    marginBottom: 10,
  },
}));

const Cookies = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <Background fixed={true} />
        <h1>Cookies Policy</h1>
        <h4>About this Cookie Policy</h4>
        <p>
          This Cookie Policy explains what cookies are and how we use them. You
          should read this policy so you can understand what type of cookies we
          use, the information we collect using cookies and how that information
          is used. For further information on how we use, store and keep your
          personal data secure, see our Privacy Policy
        </p>
        <h4>What are cookies?</h4>
        <p>
          Cookies are little text files which are stored on the browser or hard
          drive of your computer or mobile device when you visit a webpage or
          application. Cookies work to make your experience browsing our site as
          smooth as possible and they remember your preferences so you donâ€™t
          have to insert your details again and again, for example.
        </p>
        <p>
          There are different types of cookies. Some cookies come directly from
          our website and others come from authorized third parties which place
          cookies on our site.
        </p>
        <p>
          Cookies can be stored for varying lengths of time on your browser or
          device. Session cookies are deleted from your computer or device when
          you close your web-browser. Persistent cookies will remain stored on
          your computer or device until deleted or until they reach their expiry
          date.
        </p>
        <h4>There are 4 varieties of cookies which operate on our website:</h4>
        <ol>
          <li className={classes.li}>
            The essentials Some cookies are essential for you to be able to
            experience the full functionality of our site. For example, these
            cookies allow you to log-in to your 4 All De Gens account and access
            the NFT Marketplace securely. Without these cookies, most parts of
            our site just won’t work as they should.
          </li>
          <li className={classes.li}>
            The customizers These cookies (also called functional cookies) allow
            our site to remember your preferences, helping you to customize your
            experience. That way we can remember you when you return to our
            site, avoid showing you notifications you have already seen and
            dismissed, show you payment options and offers available in your
            country or specifically for you, provide you with live chat support
            and other services of the sort.
          </li>
          <li className={classes.li}>
            The performance improvers These cookies tell us about how you use
            the site and they help us in making it better. For example, these
            cookies count the number of visitors to our website and see how
            visitors move around when they are using it. This gives us
            invaluable information to understand user preferences and design to
            improve our site accordingly. These are the cookies that allow us to
            give you a better service every day.
          </li>
          <li className={classes.li}>
            The advertisers These cookies (also called advertising cookies) are
            used to track the effectiveness of our ad campaigns on third party
            websites, which you may have seen or even got to know about 4 All De
            Gens through them. These cookies are placed on our site by our third
            party service providers, may remember your web browsing activity and
            may be used to understand your demographics, such as age and gender.
            Rest assured that only anonymized information is captured and used
            and the purpose is to provide you with content which is relevant to
            you and to improve our marketing efforts for promoting the 4 All De
            Gens brand. None of our cookies, not even the advertisers, capture
            any personal data from you, only browsing behavior such as referral
            site, associated sites you may have visited, time spent on them,
            etc.
          </li>
        </ol>
      </Container>
      <Footer />
    </>
  );
};

export default Cookies;
