import React from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonAssetForm = () => (
  <Box>
    <Box marginTop={3}>
      <Skeleton variant="rectangular" width={200} height={200} />
    </Box>
    <Box sx={{ position: "relative" }} marginTop={3}>
      <Skeleton height={40} />
    </Box>
    <Box sx={{ position: "relative" }} marginTop={3}>
      <Skeleton height={80}/>
    </Box>

    <Box sx={{ position: "relative" }} display="flex" gap={0.7} marginTop={3}>
      <Skeleton variant="rectangular" width={100} height={100} />
      <Skeleton variant="rectangular" width={100} height={100} />
      <Skeleton variant="rectangular" width={100} height={100} />
    </Box>

    <Box sx={{ position: "relative" }} marginTop={3}>
      <Skeleton height={40}/>
    </Box>
  </Box>
);

export default SkeletonAssetForm;
