import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  alpha,
  InputAdornment,
} from "@mui/material";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { errorMessage, toSnakeCaseObject } from "../../../config/helpers/utils";
import Background from "../../home/components/background";
import MintService, { CollectionAssets } from "../../../services/mint-service";
import { theme } from "../../../styles/material-theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { ITrait } from "./add-properties";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import "antd/es/upload/style/index.css";
import { TextField } from "formik-mui";
import Properties from "./properties";
import { Upload } from "antd";
import * as Yup from "yup";
import CollectionNotOpenCard from "../../error/collection-not-open";
import SkeletonAssetForm from "./skeleton-asset-form";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "108px",
  },
  headerTitle: {
    textAlign: "center",
    fontSize: 28,
    color: "#fff",
    marginBottom: 50,
  },
  upload: {
    "& .ant-upload.ant-upload-select-picture-card": {
      width: 200,
      height: 200,
    },
    "& .ant-upload-list-picture-card-container": {
      width: 200,
      height: 200,
    },
    "& .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item":
      {
        borderRadius: 10,
        padding: 0,
        overflow: "hidden",
      },
    "& .ant-upload-list .ant-upload-select-picture-card": {
      backgroundColor: "transparent",
      border: "1px solid rgba(255, 255, 255, 0.23)",
      borderRadius: 10,
      "&:hover": {
        border: "1px solid #fff",
      },
    },
    "& .ant-upload": {
      color: "rgb(255, 255, 255)",
    },
  },
}));

interface ICreateAsset {
  assetContractAddress: string;
  price: string;
  currency: string;
  metadata: IMetadata;
}

interface IMetadata {
  name: string;
  description: string;
  attributes: ITrait[];
}

const emptyAsset: ICreateAsset = {
  assetContractAddress: "",
  price: "",
  currency: "",
  metadata: {
    name: "",
    description: "",
    attributes: [],
  },
};

const emptyCollectionAssets: CollectionAssets = {
  slug: "",
  status: "",
  image: "",
  items: [],
};

const CreateAsset = () => {
  const [assets, setAssets] = useState<CollectionAssets>(emptyCollectionAssets);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [traits, setTraits] = useState<ITrait[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  const params = location.pathname.split("/");
  const assetContractAddress = params[3];

  const initialValues = {
    name: "",
    description: "",
    attributes: [],
    price: "",
    currency: "BRL",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t("this_field_is_required")),
    description: Yup.string().trim().required(t("this_field_is_required")),
    price: Yup.string().trim().required(t("this_field_is_required")),
  });

  useEffect(() => {
    async function getInfo() {
      try {
        setLoading(true);

        const response = await MintService.getCollectionAssets(
          decodeURIComponent(assetContractAddress)
        );
        if (response) {
          setAssets(response);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    getInfo();
  }, [enqueueSnackbar]);

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onSubmit = async (values: typeof initialValues) => {
    const createAsset: ICreateAsset = emptyAsset;

    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file.originFileObj as RcFile);
    });

    createAsset.assetContractAddress = assetContractAddress;
    createAsset.metadata.name = values.name;
    createAsset.metadata.description = values.description;
    createAsset.metadata.attributes = traits;
    createAsset.price = values.price;
    createAsset.currency = values.currency;

    formData.append("body", JSON.stringify(toSnakeCaseObject(createAsset)));

    try {
      const response = await MintService.createAsset(formData);
      if (response) {
        setLoading(false);
        setFileList([]);
        enqueueSnackbar(t("Asset Created Successfully"), {
          variant: "success",
        });
        navigate(`/assets/${assetContractAddress}`);
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(errorMessage(e), {
        variant: "error",
      });
    }
  };

  if (!loading && assets.status != "open") {
    return (
      <Container className={classes.root} maxWidth="lg">
        <CollectionNotOpenCard slug={assetContractAddress} />
      </Container>
    );
  }

  return (
    <Box display="flex" className={classes.root}>
      <Container maxWidth="sm">
        <Background fixed={true} />

        <Box
          sx={{
            background: alpha(theme.palette.background.paper, 0.5),
            backdropFilter: "blur(2px)",
            padding: 4,
            borderRadius: 4,
            marginBottom: 10,
          }}
        >
          <Typography
            variant="h1"
            fontWeight="500"
            className={classes.headerTitle}
          >
            {t("create_new_item")}
          </Typography>
          {loading ? (
            <SkeletonAssetForm />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {({ dirty, isValid }) => (
                <Form
                  style={{
                    width: "calc(100% - 24px)",
                    minWidth: "300px",
                    margin: "0 auto",
                  }}
                >
                  <Box marginTop={3}>
                    <Upload
                      className={classes.upload}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                      beforeUpload={() => false}
                      accept="image/*"
                    >
                      {fileList.length < 1 && t("image") + " *"}
                    </Upload>
                  </Box>
                  <Typography color="textSecondary" fontSize={13}>
                    {t("file_type_supported")}
                  </Typography>
                  <Box sx={{ position: "relative" }} marginTop={3}>
                    <Field
                      required
                      component={TextField}
                      type="text"
                      label={t("item_name")}
                      name="name"
                      autoComplete="none"
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ position: "relative" }} marginTop={3}>
                    <Field
                      required
                      component={TextField}
                      type="text"
                      label={t("description")}
                      name="description"
                      multiline
                      rows={4}
                      placeholder=""
                      autoComplete="none"
                      fullWidth
                    />
                  </Box>

                  <Box sx={{ position: "relative" }} marginTop={3}>
                    <Properties onSubmit={(traits) => setTraits(traits)} />
                  </Box>

                  <Box sx={{ position: "relative" }} marginTop={3}>
                    <Field
                      required
                      component={TextField}
                      type="number"
                      label={t("price")}
                      name="price"
                      placeholder=""
                      autoComplete="none"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">BRL</InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box
                    marginTop={4}
                    marginBottom={2}
                    display="flex"
                    justifyContent="center"
                  >
                    <LoadingButton
                      variant="contained"
                      size="large"
                      type="submit"
                      loading={loading}
                      disabled={
                        !isValid || !dirty || loading || fileList.length !== 1
                      }
                    >
                      {t("confirm")}
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default CreateAsset;
