import SmallNftCard from '../../../../../components/common/small-nft-card';
import { Collection } from '../../../../../services/products-service';
import { theme } from '../../../../../styles/material-theme';
import { useAppDispatch } from '../../../../../redux/hooks';
import { Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import {
  cleanExploreFilters,
  setExploreFilterCollection,
} from '../../../../../redux/filters';

interface Props {
  collections: Collection[];
}

const NoItems = ({ collections }: Props) => {
  const [collectionFiltered, setCollectionFiltered] = useState<Collection[]>(
    []
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const collectionFiltered = collections.filter((c) => {
      return c.imageUrl;
    });

    setCollectionFiltered(collectionFiltered);
  }, [collections]);


  return (
    <>
      <Box
        sx={{
          p: 3,
          textAlign: 'center',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: 3,
        }}
      >
        <Typography color='textPrimary' variant='h4'>
          {t('no_items_to_display')}
        </Typography>

        <Typography color='textSecondary' marginTop={2}>
          {t('try_adjusting_your_search')}
        </Typography>

        <Typography color='textPrimary' marginTop={2} fontSize='0.8rem'>
          {t('not_sure_where_to_start')}
        </Typography>
      </Box>

      <Grid container spacing={2} marginTop={2}>
        {collectionFiltered.slice(0, 12).map((collection, i) => (
          <Grid item xs={6} md={4} lg={2} key={i}>
            <SmallNftCard
              img={collection.imageUrl}
              description={collection.name}
              onClick={() => {
                dispatch(cleanExploreFilters());
                dispatch(setExploreFilterCollection(collection.name));
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NoItems;
