/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { INotification } from "../../services/notifications-service";
import { theme } from "../../styles/material-theme";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("md")]: { minWidth: 600, minHeight: 350 },
  },
}));

interface Props {
  notification: INotification;
  onClose: () => void;
  isOpen: boolean;
}

const NotificationDialog: React.FC<Props> = (props) => {
  const { onClose, isOpen, notification } = props;
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth={fullScreen}
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="notification-dialog-title"
      aria-describedby="notification-dialog-description"
    >
      <Box className={classes.root}>
        <DialogTitle>{notification.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: notification.content || "" }}></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NotificationDialog;
