import { alpha, Button, Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../styles/material-theme';

export interface MultilineBtnProps {
  title: string;
  subtitle: string;
  description?: string;
}

const MultilineBtn = ({ title, subtitle, description }: MultilineBtnProps) => {
  return (
    <Button
      variant='outlined'
      fullWidth
      sx={{
        display: 'block',
        textTransform: 'none',
        background: alpha(theme.palette.primary.main, 0.1),
      }}
    >
      <Typography
        color={theme.palette.primary.main}
        fontSize={12}
        textTransform='uppercase'
      >
        {title}
      </Typography>
      <Typography
        color='textPrimary'
        display='block'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {subtitle}
      </Typography>
      {description && (
        <Typography color='textSecondary' display='block' fontSize={12}>
          {description}
        </Typography>
      )}
    </Button>
  );
};

export default MultilineBtn;
