import CollectionPrice from './components/collection-price';
import ItemActivityTable from './components/item-activity';
import PriceHistoryChart from './components/price-history';
import CollectionName from './components/collection-name';
import { Product } from '../../../../types/product';
import { Box, Grid } from '@mui/material';
import React from 'react';

interface MainColumnProps {
  loading: boolean;
  product: Product;
  owner: string;
}

const MainColumn = ({ loading, product, owner }: MainColumnProps) => {
  return (
    <Grid item xs={12} md={7}>
      <Box
        sx={{ flexDirection: 'column', display: { xs: 'none', md: 'flex' } }}
      >
        <CollectionName loading={loading} product={product} />
        <CollectionPrice loading={loading} product={product} owner={owner} />
      </Box>

      <PriceHistoryChart />
      <ItemActivityTable />
    </Grid>
  );
};

export default MainColumn;
