import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../../../../styles/material-theme';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { Product } from '../../../../../types/product';
import { useAppDispatch } from '../../../../../redux/hooks';
import { cleanExploreFilters, setExploreFilterCollection } from '../../../../../redux/filters';
import { useTranslation } from "react-i18next";

interface CollectionDescriptionProps {
  loading: boolean;
  product: Product;
}

const CollectionDescription = ({
  loading,
  product,
}: CollectionDescriptionProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Accordion
      expanded={true}
      disabled={true}
      sx={{ marginBottom: '24px!important' }}
    >
      <AccordionSummary>
        <FormatAlignLeftIcon sx={{ marginRight: 1 }} />
        <Typography color='textPrimary' fontWeight='bold'>
          {t("description")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          color='textSecondary'
          paddingTop={2}
          display='inline-flex'
          marginBottom={1}
          component='div'
        >
          {t("created")} by&nbsp;
          <Link
            to={`/explore`}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              dispatch(cleanExploreFilters());
              dispatch(setExploreFilterCollection(product.collection.name));
            }}
          >
            <Typography
              color={theme.palette.primary.main}
              display='inline-flex'
            >
              {loading ? <Skeleton width={120} /> : product.collection.name}
            </Typography>
          </Link>
        </Typography>
        <Typography color='textPrimary'>
          {loading ? (
            <Skeleton width={'100%'} height={100} />
          ) : (
            product.description
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default CollectionDescription;
