import { ObjectOf, ObjectString } from '../../types/global';
import { resources } from '../constants/urls';

const buildPathWithParams = (path: string, params: ObjectOf<string>) => {
  let finalPath = path;
  Object.keys(params).forEach((key) => {
    finalPath = finalPath.replace(`:${key}`, params[key]);
  });
  return finalPath;
};

const buildPathOptionalParams = (path: string, params: ObjectString) => {
  const optionalParams = new URLSearchParams(params.params);
  const finalPath = path + '?' + optionalParams.toString();
  return finalPath;
};

const buildPathStringParams = (path: string, params: string) => {
  const optionalParams = new URLSearchParams(params);
  const finalPath = path + '?' + optionalParams.toString();
  return finalPath;
};

const buildPathWithKeyAndParams = (
  path: string,
  keys: ObjectOf<string>,
  params: string
) => {
  let paramsPath = path;
  Object.keys(keys).forEach((key) => {
    paramsPath = paramsPath.replace(`:${key}`, keys[key]);
  });
  const optionalParams = new URLSearchParams(params);
  const finalPath = paramsPath + '?' + optionalParams.toString();
  return finalPath;
};

const getQueryParam = (key: string): string | null => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  } catch {
    return null;
  }
};

const getMerchantImage = (merchantImage: string | number) =>
  merchantImage ? `${resources.merchants}${merchantImage}.svg` : defaultImg;

const getCountryImage = (countryCode: string) =>
  countryCode
    ? `${resources.flags}${countryCode.toUpperCase()}.svg`
    : defaultImg;

const getPaymentMethodImage = (paymentMethodCode: string) =>
  paymentMethodCode
    ? `${resources.paymentMethodsMobile}${paymentMethodCode}.svg`
    : defaultImg;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleErrorImage = (e: any) => {
  e.target.src = defaultImg;
};

const defaultImg = '';
// const defaultImg = `${process.env.PUBLIC_URL}/img/default-image.svg`;

export {
  buildPathWithParams,
  buildPathOptionalParams,
  buildPathStringParams,
  buildPathWithKeyAndParams,
  getQueryParam,
  getMerchantImage,
  getCountryImage,
  getPaymentMethodImage,
  handleErrorImage,
};
