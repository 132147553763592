import MintService, { ICollectionLite } from '../../services/mint-service';
import CollectionCard from '../../components/common/collection-card';
import { Divider, Skeleton, Box, Typography } from '@mui/material';
import { errorMessage } from '../../config/helpers/utils';
import { theme } from '../../styles/material-theme';
import imgPlus from './../../assets/img/plus.png';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: 20,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  newCollection: {
    minWidth: 130,
    height: 261,
    width: '100%',
    backgroundColor: 'rgba(255,255,255, 0.2)',
    backgroundImage: `url(${imgPlus})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: '1px solid rgba(255,255,255, 0.05)',
    borderRadius: 15,
    marginBottom: 10,
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #fff',
      backgroundColor: 'rgba(255,255,255, 0.3)',
    },
  },
  label: {
    fontWeight: '500',
    color: '#fff',
  },
}));

interface IMyCollectionProps {
  username: string;
}

const MyCollections = ({ username }: IMyCollectionProps) => {
  const [myCollections, setMyCollections] = useState<ICollectionLite[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    async function getMycollections() {
      try {
        const response = await MintService.getCollectionsByUser(
          username,
          'all',
          'lite'
        );
        if (response) {
          setMyCollections(response.collections);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        enqueueSnackbar(errorMessage(e), {
          variant: 'error',
        });
      }
    }
    getMycollections();
  }, [enqueueSnackbar]);

  if (loading)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Skeleton width={'100%'} height={240} />
        <Divider />
      </div>
    );

  return (
    <Box className={classes.root}>
      <Link to='/create/collection' style={{ textDecoration: 'none' }}>
        <div>
          <div className={classes.newCollection}></div>
          <Typography className={classes.label}>Create Collection</Typography>
        </div>
      </Link>

      {myCollections !== [] &&
        myCollections.map((collection, i) => (
          <Link
            key={i}
            to={`/assets/${collection.contractAddress}`}
            style={{ textDecoration: 'none' }}
          >
            <CollectionCard
              loading={loading}
              img={collection.imageUrl}
              name={collection.name}
              items={collection.items}
              status={collection.status}
            />
          </Link>
        ))}
    </Box>
  );
};

export default MyCollections;
