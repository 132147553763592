import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  getExplorerData,
  setExplorerSettingDisplay,
} from '../../../../../redux/global';
import {
  getExploreFilter,
  setExploreOrder,
} from '../../../../../redux/filters';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  displaySettings: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sortInput: {
    minWidth: 130,
    marginRight: 10,
  },
  totalLabel: {
    color: '#fff',
  },
}));

interface Props {
  total: number;
}

export const SortFilters: React.FC<Props> = ({ total }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const explorer = useAppSelector(getExplorerData);
  const filters = useAppSelector(getExploreFilter);
  const [sort, setSort] = useState('');
  const { t } = useTranslation();

  const sortHandleChange = (event: SelectChangeEvent) => {
    dispatch(setExploreOrder(event.target.value));
    setSort(event.target.value as string);
  };

  const handleDisplay = (
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string
  ) => {
    dispatch(setExplorerSettingDisplay(newDisplay));
  };

  useEffect(() => {
    setSort(filters.order || '');
  }, [filters]);

  return (
    <Box className={classes.root}>
      <Typography className={classes.totalLabel}>{`${total} items`}</Typography>
      <div className={classes.displaySettings}>
        <FormControl className={classes.sortInput}>
          <InputLabel id='sort'>{t('sort_by')}</InputLabel>
          <Select
            labelId='sort'
            value={sort}
            label={t('sort_by')}
            onChange={sortHandleChange}
          >
            <MenuItem value='asc'>{t('price_low_to_hight')}</MenuItem>
            <MenuItem value='desc'>{t('price_high_to_low')}</MenuItem>
            <MenuItem value='pop'>{t('label_pop')}</MenuItem>
            <MenuItem value='fav'>{t('label_fav')}</MenuItem>
          </Select>
        </FormControl>

        <ToggleButtonGroup
          value={explorer.settings.display}
          exclusive
          onChange={handleDisplay}
        >
          <ToggleButton value='large' aria-label='left aligned'>
            <GridViewRoundedIcon />
          </ToggleButton>
          <ToggleButton value='small' aria-label='centered'>
            <ViewModuleRoundedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </Box>
  );
};

export default SortFilters;
