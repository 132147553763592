import { createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#b9fa51',
    },
    secondary: {
      main: '#6f33ff',
      light: '#51a6f2,',
    },
    text: {
      primary: '#fff',
      secondary: '#ffffffb3',
    },
    success: {
      main: '#34c77b',
    },
    background: {
      default: '#04111d',
    },
    grey: {
      A400: '#303338',
      A700: '#262b2f',
    },
    common: {
      black: '#151b22',
    },
  },
});

theme.components = {
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 10,
        '&:hover': {
          img: {
            transform: 'scale(1.1)',
          },
        },
        img: {
          transition: 'all 0.3s',
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: 10,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        background: theme.palette.grey.A700,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.common.black}`,
        borderRadius: 0,
        '&.MuiAccordion-rounded.Mui-expanded': {
          borderRadius: 10,
        },
        '&.Mui-expanded': {
          margin: 0,
        },
        '&.MuiAccordion-rounded': {
          borderRadius: 10,
          '& .MuiAccordionDetails-root': {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
        },
        '&.Mui-disabled': {
          background: theme.palette.grey.A700,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: 64,
        borderBottom: `1px solid ${theme.palette.common.black}`,
        '&.Mui-disabled': {
          opacity: 1,
        },
      },
      content: {
        fontWeight: 'bold',
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        background: theme.palette.grey.A400,
        padding: 16,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 10,
        paddingRight: 0,
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 10,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: 10,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        background: theme.palette.grey.A400,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeLarge: {
        padding: '12px 30px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #0b2233 inset!important',
        },
        '&::placeholder': {
          color: theme.palette.grey[400],
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 10,
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      grouped: {
        borderRadius: 10,
        padding: 12,
        background: theme.palette.grey.A700,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          borderTopLeftRadius: 10,
        },
        '&:last-child': {
          borderTopRightRadius: 10,
        },
      },
      stickyHeader: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        background: theme.palette.grey.A700,
        borderBottom: `1px solid ${theme.palette.common.black}`,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiSkeleton: {
    defaultProps: {
      animation: 'wave',
    },
    styleOverrides: {
      root: {
        transform: 'scale(1)',
        borderRadius: 10,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: 16,
        paddingBottom: 16,

        '&+.MuiDivider-root': {
          margin: 0,
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
};

const useStyles = makeStyles(() => ({
  primary: {
    color: theme.palette.common.white,
    backgroundColor: '#2799f0',
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: '#3bd5a9',
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  default: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
  },
}));

/**
 * Returns the classes of `status`
 *
 *
 * @param {string} originalStatus Status string
 * @returns {string} Returns the classes of the status
 */
export const GetStatusColor = (status: string) => {
  const classes = useStyles();
  switch (status) {
    case 'PENDING':
      return classes.primary;
    case 'COMPLETED':
    case 'CONFIRMED':
    case 'APPROVED':
    case 'NEW':
    case 'ASSIGNED':
      return classes.success;
    case 'ACTIVE':
      return classes.success;
    case 'CANCELED':
    case 'CANCELLED':
    case 'FAILED':
    case 'DENIED':
      return classes.danger;
    case 'INACTIVE':
      return classes.danger;
    default:
      return classes.default;
  }
};
