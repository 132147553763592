import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterPrice from '../../../../components/filters/explore/filter-price';
import FilterCollection from '../../../../components/filters/explore/filter-collection';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../styles/material-theme';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Collection } from '../../../../services/products-service';

const useStyles = makeStyles(() => ({
  root: {
    background: theme.palette.grey.A700,
    width: '100%',
    height: 'calc(100vh - 134px)',
  },
  accordion: {
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#33393e',
    },
  },
}));

interface Props {
  collections: Collection[];
}

const ExploreSideBar = ({ collections }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Accordion square={true} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t('price')}
        </AccordionSummary>
        <AccordionDetails>
          <FilterPrice />
        </AccordionDetails>
      </Accordion>
      <Accordion square={true} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t('collections')}
        </AccordionSummary>
        <AccordionDetails>
          <FilterCollection collections={collections} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExploreSideBar;
