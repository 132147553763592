import { Avatar, Box, Button, Typography } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SignUpService from '../../services/auth-services/signup-service';
import { useSnackbar } from 'notistack';
import LoadingBtn from '../../components/common/loading-btn';

interface Props {
  email: string;
}

const ConfirmEmail = ({ email }: Props) => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const resendValidation = async () => {
    setLoadingBtn(true);
    try {
      const response = await SignUpService.resendValidation(email);
      if (response) {
        setLoadingBtn(false);
        setEmailSent(true);
        enqueueSnackbar(t('sign_up_successfully'), {
          variant: 'success',
        });
      }
    } catch (e) {
      setLoadingBtn(false);
    }
  };

  return (
    <Box textAlign='center'>
      <Avatar sx={{ m: '24px auto', width: 48, height: 48 }}>
        <EmailOutlinedIcon />
      </Avatar>
      <Typography variant='h4' marginBottom={2}>
        {t('confirm_your_email')}
      </Typography>
      <Typography variant='body1' marginBottom={3}>
        {t('please_check_your_inbox')}
      </Typography>

      {emailSent === false ? (
        <>
          <Typography variant='body1' marginTop={6}>
            {t('did_not_get_a_confirmation_mail')}
          </Typography>
          <Box mt={2}>
            <Button
              variant='outlined'
              disabled={timeLeft !== 0 || loadingBtn}
              onClick={resendValidation}
            >
              <LoadingBtn showLoading={loadingBtn} />
              {t('send_again')}
            </Button>
          </Box>
          <Typography marginTop={1}>
            00:{timeLeft < 10 ? '0' + timeLeft : timeLeft}
          </Typography>
        </>
      ) : (
        <>
          <Typography marginTop={6}>
            {t('if_you_continue_to_have_problems_confirmation')}
          </Typography>
          <Typography color='primary'>ola@4alldegens.com</Typography>
        </>
      )}
    </Box>
  );
};

export default ConfirmEmail;
