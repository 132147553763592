import MoreFromThisCollection from './components/more-from-this-collection';
import SummaryColumn from './components/summary-column/summary-column';
import ProductsService from '../../services/products-service';
import MainColumn from './components/main-column/main-column';
import { emptyProduct, Product } from '../../types/product';
import { errorMessage } from '../../config/helpers/utils';
import { getIsAuthenticated } from '../../redux/session';
import Footer from '../../components/footer/footer';
import { useAppSelector } from '../../redux/hooks';
import React, { useEffect, useState } from 'react';
import SellAsset from './components/sell-asset';
import { Container, Grid } from '@mui/material';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';

const ProductDetail = () => {
  const [productDetail, setProductDetail] = useState<Product>(emptyProduct);
  const [reloadProduct, setReloadProduct] = useState<boolean>(false);
  const [ownershipStatus, setOwnershipStatus] = useState<string>('');
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const path = location.pathname;

  const addressContractId = path.substring(9, path.lastIndexOf('/'));
  const tokenId = path.substring(path.lastIndexOf('/') + 1);

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      try {
        const response = await ProductsService.getProduct(
          addressContractId,
          tokenId
        );
        if (response) {
          setProductDetail(response);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log('Product Error');
        enqueueSnackbar(errorMessage(e), {
          variant: 'error',
        });
      }
    };

    getProduct();
  }, [addressContractId, tokenId, enqueueSnackbar, reloadProduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const checkOwnership = async () => {
      if (isAuthenticated) {
        try {
          setOwnershipStatus('');
          const response = await ProductsService.checkOwnership(
            addressContractId,
            tokenId
          );
          if (response) {
            setOwnershipStatus(response);
          }
        } catch (e) {
          setOwnershipStatus('');
          console.log('Ownership Error');
        }
      }
    };

    checkOwnership();
  }, []);

  return (
    <>
      <SellAsset
        product={productDetail}
        onReloadProduct={() => setReloadProduct(true)}
        owner={ownershipStatus}
      />
      <Container maxWidth='lg' sx={{ marginTop: '100px' }}>
        <Grid container spacing={3}>
          <SummaryColumn
            loading={loading}
            product={productDetail}
            owner={ownershipStatus}
          />
          <MainColumn
            loading={loading}
            product={productDetail}
            owner={ownershipStatus}
          />
          <MoreFromThisCollection
            collection={productDetail?.collection?.name}
          />
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default ProductDetail;
