import { theme } from '../../styles/material-theme';
import { Box, alpha } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
      style={{
        background: alpha(theme.palette.background.paper, 0.5),
        backdropFilter: 'blur(2px)',
        borderRadius: 8,
      }}
    >
      {value === index && (
        <Box sx={{ p: '2px 24px 24px', borderRadius: 8 }}>{children}</Box>
      )}
    </div>
  );
};

export default TabPanel;
