import { camelCase, snakeCase } from './string';

export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const isObject = (value: unknown): boolean => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const toCamelCaseObject = <T1 extends {}, T2 = {}>(
  obj: T2,
  indexs: { [key: string]: keyof T1 } = {}
): T1 =>
  Object.entries(obj).reduce(
    (u, [attr, value]) => ({
      ...u,
      [attr in indexs ? indexs[attr] : camelCase(attr)]:
        isObject(value) && !Array.isArray(value)
          ? // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            toCamelCaseObject<any>(value, indexs)
          : Array.isArray(value)
          ? value.map((v) => (isObject(v) ? toCamelCaseObject(v, indexs) : v))
          : value,
    }),
    {} as T1
  );


// eslint-disable-next-line @typescript-eslint/ban-types
export const toSnakeCaseObject = <T1 extends {}, T2 = {}>(
  obj: T2,
  indexs: { [key: string]: keyof T1 } = {}
): T1 =>
  Object.entries(obj).reduce(
    (u, [attr, value]) => ({
      ...u,
      [attr in indexs ? indexs[attr] : snakeCase(attr)]:
        isObject(value) && !Array.isArray(value)
          ? // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            toSnakeCaseObject<any>(value, indexs)
          : Array.isArray(value)
          ? value.map((v) => (isObject(v) ? toSnakeCaseObject(v, indexs) : v))
          : value,
    }),
    {} as T1
  );

export const getInitials = (string: string) => {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const errorMessage = (e: any) => {
  switch (e) {
    case e.message:
      return e.message;
    case e.error:
      return e.error;
    default:
      return 'Something went wrong. Please try again later.';
  }
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const objectToArray = (object: any) => {
  return Object.keys(object).map((o) => object[o]);
};
