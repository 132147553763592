import React from "react";
import { Box, Button, CardMedia, Paper, Typography } from "@mui/material";
import notFoundImg from "../../assets/img/404-2.png";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { cleanExploreFilters } from "../../redux/filters";
import { useAppDispatch } from "../../redux/hooks";

const Card404 = () => {
  const dispatch = useAppDispatch();

  return (
    <Box textAlign="center">
      <Paper elevation={5} sx={{ marginTop: 16, p: 8 }}>
        <Box width={"50%"} maxWidth={"400px"} margin="0 auto">
          <CardMedia component="img" image={notFoundImg} alt="404" />
        </Box>
        <Typography variant="h4" paddingY={3} color="textSecondary">
          {t("page_not_found")}
        </Typography>
        <Link to={"/explore"} style={{ textDecoration: "none" }} onClick={() => dispatch(cleanExploreFilters())}>
          <Button variant="contained" sx={{ margin: "24px auto", display: "block" }}>
            {t("go_to_explore")}
          </Button>
        </Link>
      </Paper>
    </Box>
  );
};

export default Card404;
