import {
  Box,
  Container,
  Paper,
  Typography,
  alpha,
  Button,
  Avatar,
  Grid,
  Skeleton,
} from '@mui/material';
import MintService, {
  CollectionAssets,
  IMintCollection,
} from '../../services/mint-service';
import MessageDialog from '../../components/common/message-dialog';
import SimpleDialog from '../../components/common/simple-dialog';
import PublishLabel from '../../components/common/publish-label';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShareBtn from '../../components/common/share-btn';
import Background from '../home/components/background';
import { Link, useLocation } from 'react-router-dom';
import { theme } from '../../styles/material-theme';
import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppSelector } from '../../redux/hooks';
import imgPlus from './../../assets/img/plus.png';
import CollectionAssetCard from './asset-card';
import { getUser } from '../../redux/session';
import { makeStyles } from '@mui/styles';
import Card404 from '../error/404-card';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import SelectPaymentMethod from '../../components/common/select-payment-method';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 80,
  },
  info: {
    marginBottom: '20px',
    position: 'relative',
    background: alpha(theme.palette.background.paper, 0.5),
    backdropFilter: 'blur(2px)',
    padding: 20,
  },
  content: {
    position: 'relative',
    background: alpha(theme.palette.background.paper, 0.5),
    backdropFilter: 'blur(2px)',
    padding: 20,
    marginBottom: '20px',
  },
  newAsset: {
    minHeight: 220,
    width: '100%',
    backgroundColor: 'rgba(255,255,255, 0.2)',
    backgroundImage: `url(${imgPlus})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: '1px solid rgba(255,255,255, 0.05)',
    borderRadius: 15,
    marginBottom: 10,
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #fff',
      backgroundColor: 'rgba(255,255,255, 0.3)',
    },
  },
  label: {
    fontWeight: '500',
    color: '#fff',
    padding: 6,
  },
}));

const emptyCollectionAssets: CollectionAssets = {
  slug: '',
  status: '',
  image: '',
  items: [],
};

const DetailsCollectionPage = () => {
  const [showSelectPaymentDialog, setShowSelectPaymentDialog] =
    useState<boolean>(false);
  const [assets, setAssets] = useState<CollectionAssets>(emptyCollectionAssets);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showNotFoundPage, setShowNotFoundPage] = useState<boolean>(false);
  const [forceReload, setForceReload] = React.useState(false);
  const [loadingMint, setLoadingMint] = useState<boolean>(false);
  const [collectionName, setCollectionName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [astroLoaded, setAstroLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const appEnv = process.env.REACT_APP_ENV;
  const user = useAppSelector(getUser);
  const location = useLocation();
  const classes = useStyles();

  const params = location.pathname.split('/');
  const slug = params[2];

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (forceReload) {
      timeout = setTimeout(() => {
        setForceReload(false);
      }, 100);
    }
    return () => {
      if (!!timeout) {
        clearTimeout(timeout);
      }
    };
  }, [forceReload]);

  useEffect(() => {
    async function getInfo() {
      try {
        setLoading(true);
        setCollectionName(params[2]);
        const response = await MintService.getCollectionAssets(
          decodeURIComponent(params[2])
        );
        if (response) {
          setAssets(response);
          setShowNotFoundPage(false);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        setShowNotFoundPage(true);
      }
    }

    if (params.length == 3) {
      getInfo();
    } else {
      setShowNotFoundPage(true);
    }
  }, [enqueueSnackbar, forceReload]);

  const mintCollection = async (payment: 'IX' | 'WA') => {
    const mintObj: IMintCollection = {
      slug: slug,
      smartContractAddress: slug,
      paymentMethodCode: payment,
    };
    try {
      setLoadingMint(true);
      const response = await MintService.mintCollection(mintObj);
      if (response) {
        setLoadingMint(false);
        showDeposit(response.payment.externalId);
      }
    } catch (e) {
      setLoadingMint(false);
    }
  };

  // INIT SDK
  useEffect(() => {
    if (!astroLoaded) {
      const APP_ID = '4AllDegens';
      const ASTROPAY_CONFIG = {
        environment: appEnv === 'PROD' ? 'production' : 'sandbox', // defaults to production
        zIndex: 9999,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        onClose: (e: any) => {
          if (e.status === 2 || e.status === 4) {
            setShowSuccessDialog(true);
          }
        },
      };
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (window as any).AstropaySDK.init(APP_ID, ASTROPAY_CONFIG);
      setAstroLoaded(true);
    }
  }, [astroLoaded, appEnv]);

  const showDeposit = async (depositExternalId: string) => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (window as any).AstropaySDK.showDeposit(depositExternalId);
  };

  return (
    <Container className={classes.root} maxWidth='lg'>
      <Background fixed={true} />
      {showNotFoundPage ? (
        <Card404 />
      ) : loading ? (
        <Skeleton sx={{ marginTop: 15 }} height={700} />
      ) : (
        <>
          <Link
            to={`/user/${user.username}`}
            style={{ textDecoration: 'none' }}
          >
            <Button
              size='small'
              sx={{ textTransform: 'none', marginBottom: 1 }}
            >
              <ArrowBackIosIcon />
              <Typography>{t('profile')}</Typography>
            </Button>
          </Link>
          <Paper className={classes.info} elevation={5}>
            <Grid container>
              <Grid item xs={12} md={8} container>
                <Grid margin={{ xs: '0 auto', md: '0' }}>
                  <Avatar
                    src={assets.image}
                    alt='collection-logo'
                    sx={{ width: 170, height: 170 }}
                  />
                </Grid>
                <Grid
                  marginTop={4}
                  marginX={{ xs: 'auto', md: 3 }}
                  textAlign={{ xs: 'center', md: 'left' }}
                >
                  <Typography variant='h5' color='primary' fontSize={36}>
                    {collectionName}
                  </Typography>
                  <Typography>{assets.items.length} items</Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                mt={5}
                sx={{ textAlign: { xs: 'center', md: 'right' } }}
              >
                <Box
                  mb={2}
                  display='flex'
                  alignItems='center'
                  justifyContent={{ xs: 'center', md: 'end' }}
                >
                  <Typography
                    color='textSecondary'
                    marginRight={2}
                    fontSize='14'
                  >
                    {t('collection_status')}
                  </Typography>
                  <PublishLabel status={assets.status} />
                </Box>
                {assets.status == 'open' ? (
                  <LoadingButton
                    size='large'
                    variant='contained'
                    loading={loadingMint}
                    disabled={assets.items.length === 0}
                    onClick={() => setShowSelectPaymentDialog(true)}
                  >
                    {t('publish_your_collection')}
                  </LoadingButton>
                ) : (
                  <ShareBtn
                    text={'Share Your Collection'}
                    variant='contained'
                    size='medium'
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.content} elevation={5}>
            <Grid container spacing={3}>
              {assets.status == 'open' && (
                <Grid item xs={12} md={3}>
                  <Link
                    to={`/create/asset/${assets.slug}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Box className={classes.newAsset}></Box>
                    <Typography className={classes.label}>
                      {t('add_asset')}
                    </Typography>
                  </Link>
                </Grid>
              )}
              {assets.items.map((item, i) => (
                <Grid item xs={12} md={3} key={i}>
                  {assets.status === 'open' ? (
                    <CollectionAssetCard asset={item} />
                  ) : (
                    <Link
                      to={`/product/${item.assetContractAddress}/${item.tokenId}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <CollectionAssetCard asset={item} />
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
          </Paper>
        </>
      )}

      <SimpleDialog
        isOpen={showSelectPaymentDialog}
        onClose={() => setShowSelectPaymentDialog(false)}
        content={
          <SelectPaymentMethod
            paymentSelected={(payment) => mintCollection(payment)}
          />
        }
        actions={[]}
        maxWidth={'sm'}
      />

      <SimpleDialog
        isOpen={showSuccessDialog}
        onClose={() => {
          setShowSuccessDialog(false);
          setForceReload(true);
        }}
        content={
          <MessageDialog
            onClose={() => {
              setShowSuccessDialog(false);
              setForceReload(true);
            }}
            icon={
              <img
                src={assets.image}
                width='220'
                style={{ borderRadius: 10 }}
              />
            }
            title={'Você cunhou sua NFT'}
            description={`Seu pagamento está sendo compensado. Você receberá um e-mail com a confirmação quando terminar e poderá ver os detalhes do pedido em seu perfil. Tenha em mente que o processo de liberação pode levar até 24 hs.`}
          />
        }
        actions={[]}
        maxWidth={'sm'}
      />
    </Container>
  );
};

export default DetailsCollectionPage;
