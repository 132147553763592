import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  alpha,
  MenuItem,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import React, { useState } from 'react';
import Background from '../home/components/background';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { theme } from '../../styles/material-theme';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Select, TextField } from 'formik-mui';
import SignUpService, {
  SignUpRequest,
} from '../../services/auth-services/signup-service';
import { useSnackbar } from 'notistack';
import { countries } from '../../services/country-service';
import LoadingBtn from '../../components/common/loading-btn';
import { errorMessage } from '../../config/helpers/utils';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ConfirmEmail from './confirm-email';
import { getQueryParam } from '../../config/helpers/url';
import InjectScript from '../../components/common/script-injected';

const useStyles = makeStyles(() => ({
  inputError: {
    '& fieldset': {
      borderColor: theme.palette.error.main,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main + '!important',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.error.main,
    },
    '& p': {
      color: theme.palette.error.main,
    },
  },
}));

export interface OptionTypeBase {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const SignUp = () => {
  const [loadingVerifyEmail, setLoadingVerifyEmail] = useState<boolean>(false);
  const [loadingVerifyUser, setLoadingVerifyUser] = useState<boolean>(false);
  const [showConfirmEmail, setShowConfirmEmail] = useState<boolean>(false);
  const [emailExist, setEmailExists] = useState<boolean | null>(null);
  const [passwordOk, setPasswordOk] = useState<boolean | null>(null);
  const [userExist, setUserExists] = useState<boolean | null>(null);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const initialValues = {
    username: '',
    email: '',
    password: '',
    country: '',
  };

  const script = `<script language="javascript" type="text/javascript">function sc(v){let d=new Date();d.setTime(d.getTime()+(365*24*60*60*1000));document.cookie="affclick="+v+";"+"expires="+d.toUTCString()+";path=/";localStorage.setItem('affclick',v)}sc((new URLSearchParams(window.location.search)).get("clickid"))</script>`;

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .min(4, t('username_between_4_20'))
      .max(20, t('username_between_4_20'))
      .required(t('this_field_is_required'))
      .matches(/^[a-zA-Z0-9]+$/, t('is_not_correct_format')),
    email: Yup.string()
      .trim()
      .email(t('please_enter_a_valid_email_address'))
      .required(t('this_field_is_required')),
    password: Yup.string()
      .min(8, t('password_must_have_at_least_8_characters'))
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        t('password_must_contain_upper_case_lower_case_and_numbers')
      )
      .required(t('this_field_is_required')),
    country: Yup.string().required(t('this_field_is_required')),
  });

  const onSubmit = async (values: typeof initialValues) => {
    setLoadingBtn(true);
    setUserEmail(values.email);
    const req: SignUpRequest = {
      username: values.username,
      email: values.email,
      password: values.password,
      country: values.country,
      redirect_url: getQueryParam('redirect') || '',
    };
    try {
      const response = await SignUpService.signUp(req);
      if (response) {
        setLoadingBtn(false);
        setShowConfirmEmail(true);
        enqueueSnackbar(t('sign_up_successfully'), {
          variant: 'success',
        });
      }
    } catch (e) {
      setLoadingBtn(false);
      enqueueSnackbar(errorMessage(e), {
        variant: 'error',
      });
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const verifyUsername = async (e: any) => {
    setLoadingVerifyUser(true);
    setUserExists(null);

    if (
      e.target.value === '' ||
      e.target.value.length < 4 ||
      e.target.value.length > 20
    ) {
      setLoadingVerifyUser(false);
      return;
    } else {
      try {
        const response = await SignUpService.userExists(e.target.value);
        if (response) {
          setUserExists(response.exists);
          setLoadingVerifyUser(false);
        }
      } catch (e) {
        setLoadingVerifyUser(false);
        setUserExists(null);
      }
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const verifyEmail = async (e: any, errors: any) => {
    setLoadingVerifyEmail(true);
    setEmailExists(null);

    if (e.target.value === '' || errors !== undefined) {
      setLoadingVerifyEmail(false);
      return;
    } else {
      try {
        const response = await SignUpService.emailExists(e.target.value);
        if (response) {
          setEmailExists(response.exists);
          setLoadingVerifyEmail(false);
        }
      } catch (e) {
        setLoadingVerifyEmail(false);
        setEmailExists(null);
      }
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const verifyPassword = async (e: any, errors: any) => {
    if (errors === undefined) {
      setPasswordOk(true);
    } else {
      setPasswordOk(false);
    }
  };

  const handleValidateUserName = (
    userExist: boolean | null,
    userNameErrors: string | undefined
  ) => {
    if (userExist) return t('username_exists');
    if (!!userNameErrors) return userNameErrors;
    return '';
  };

  return (
    <>
      <Background fixed={true} />
      <Box>
        <Container maxWidth='sm'>
          <Paper
            sx={{
              marginTop: 16,
              marginBottom: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: { xs: 4, md: 8 },
              paddingY: { xs: 12, md: 8 },
              background: alpha(theme.palette.background.paper, 0.75),
            }}
          >
            {!showConfirmEmail ? (
              <>
                <Avatar sx={{ m: 1 }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5' color='textPrimary'>
                  {t('sign_up')}
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ touched, isValid, errors }) => (
                    <Form style={{ width: '100%' }}>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='text'
                          label={t('username')}
                          name='username'
                          fullWidth
                          autoComplete='nope'
                          onKeyUp={verifyUsername}
                          helperText={handleValidateUserName(
                            userExist,
                            errors.username
                          )}
                          className={
                            handleValidateUserName(userExist, errors.username)
                              ? classes.inputError
                              : ''
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                {userExist === true || !!errors.username ? (
                                  <HighlightOffIcon color='error' />
                                ) : userExist === null ? (
                                  ''
                                ) : userExist === false ? (
                                  <CheckCircleIcon color='success' />
                                ) : null}
                                <LoadingBtn
                                  showLoading={
                                    loadingVerifyUser && userExist === null
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='email'
                          label={t('email')}
                          name='email'
                          fullWidth
                          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                          onKeyUp={(e: any) => verifyEmail(e, errors.email)}
                          className={emailExist ? classes.inputError : ''}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                {emailExist === true ? (
                                  <HighlightOffIcon color='error' />
                                ) : emailExist === null ? (
                                  ''
                                ) : emailExist === false ? (
                                  <CheckCircleIcon color='success' />
                                ) : null}
                                <LoadingBtn
                                  showLoading={
                                    loadingVerifyEmail && emailExist === null
                                  }
                                />
                              </InputAdornment>
                            ),
                            autoComplete: 'new-password',
                          }}
                        />
                        {emailExist ? (
                          <FormHelperText error sx={{ marginLeft: 2 }}>
                            {t('email_exists')}
                          </FormHelperText>
                        ) : null}
                      </Box>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='password'
                          label={t('password')}
                          name='password'
                          fullWidth
                          autoComplete='new-password'
                          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                          onKeyUp={(e: any) =>
                            verifyPassword(e, errors.password)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                {passwordOk === true ? (
                                  <CheckCircleIcon color='success' />
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box marginTop={3} width='100%'>
                        <Field
                          component={Select}
                          id='country'
                          name='country'
                          labelId='Country'
                          label={t('country')}
                          style={{ width: '100%' }}
                        >
                          {countries.map((country, i) => (
                            <MenuItem value={country.isocode} key={i}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Box>
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        disabled={
                          !isValid ||
                          !touched ||
                          loadingBtn ||
                          loadingVerifyUser ||
                          loadingVerifyEmail ||
                          userExist === true ||
                          emailExist === true ||
                          emailExist === null
                        }
                        sx={{ mt: 3, mb: 2 }}
                        size='large'
                      >
                        <LoadingBtn showLoading={loadingBtn} />
                        {t('create_account')}
                      </Button>

                      <Grid container>
                        <Grid item xs={12} textAlign='right'>
                          <Link
                            to='/login'
                            style={{
                              color: theme.palette.primary.main,
                              fontSize: 14,
                            }}
                          >
                            {t('you_already_have_an_account_sign_in')}
                          </Link>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <ConfirmEmail email={userEmail} />
            )}
          </Paper>
        </Container>
      </Box>

      <InjectScript script={script} />
    </>
  );
};

export default SignUp;
