import {
  cleanExploreFilters,
  setExploreFilterCollection,
} from '../../../../../redux/filters';
import TrendTag from '../../../../../components/common/trend-tag';
import FavoriteBtn from '../../../../../components/common/favorite-btn';
import ShareBtn from '../../../../../components/common/share-btn';
import { theme } from '../../../../../styles/material-theme';
import { useAppDispatch } from '../../../../../redux/hooks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Skeleton, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Product } from '../../../../../types/product';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

interface CollectionNameProps {
  loading: boolean;
  product: Product;
}

const CollectionName = ({ loading, product }: CollectionNameProps) => {
  const [favorites, setFavorites] = useState<number>(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFavorites(product.favorites);
  }, [product]);

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Link
          to={`/explore`}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            dispatch(cleanExploreFilters());
            dispatch(setExploreFilterCollection(product.collection.name));
          }}
        >
          <Typography color={theme.palette.primary.main} paddingTop={2}>
            {loading ? <Skeleton width={120} /> : product.collection.name}
          </Typography>
        </Link>

        <Box display='flex' style={{ gap: 16 }}>
          <TrendTag
            weeklyScore={product?.weeklyScore}
            height={18}
            marginTop={1.2}
            fontSize={12}
          />
          <ShareBtn />
          <FavoriteBtn
            product={product}
            onAdd={() => setFavorites((favorite) => favorite + 1)}
            onRemove={() => setFavorites((favorite) => favorite - 1)}
          />
        </Box>
      </Box>
      <Typography
        variant='h4'
        color='textPrimary'
        fontWeight='bold'
        paddingTop={2}
      >
        {loading ? <Skeleton width={350} /> : product.name}
      </Typography>

      <Box display='flex' alignItems='center'>
        <Typography
          color='textSecondary'
          paddingTop={2}
          display='inline-flex'
          marginBottom={3}
          component='div'
        >
          {t('owned_by')}&nbsp;
          {loading ? (
            <Skeleton width={120} />
          ) : product.topOwnerships[0].owner.address === 'INTERNAL' ? (
            <Link
              to={`/user/${product.topOwnerships[0].owner.user?.username}`}
              style={{
                display: 'inline-flex',
                textDecoration: 'none',
                color: theme.palette.primary.main,
              }}
            >
              {product.topOwnerships[0].owner.user?.username}
            </Link>
          ) : (
            <Typography display='inline-flex'>
              {product.topOwnerships[0].owner.user?.username}
            </Typography>
          )}
        </Typography>
        <Typography
          color='textSecondary'
          display='inline-flex'
          marginBottom={1}
          marginLeft={3}
        >
          <VisibilityIcon />
          <div style={{ marginLeft: 4 }}>{product.score}</div>
          <div style={{ marginLeft: 4 }}>{t('views')}</div>
        </Typography>

        {favorites > 0 && (
          <Typography
            color='textSecondary'
            display='inline-flex'
            marginBottom={1}
            marginLeft={3}
          >
            <FavoriteIcon />
            <div style={{ marginLeft: 4 }}>{favorites}</div>
            <div style={{ marginLeft: 4 }}>{t('favorites')}</div>
          </Typography>
        )}
      </Box>
    </>
  );
};

export default CollectionName;
