import urls from '../config/constants/urls';
import { toCamelCaseObject, toSnakeCaseObject } from '../config/helpers/utils';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export interface Sale {
  tokenId: string;
  assetContractAddress: string;
  currency: string;
  price: number;
}

export interface SellNFTCollection {
  body: SellBody;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  file: any;
}

interface SellBody {
  price: number;
  currency: string;
  collection_name: string;
  image_extension: string;
  copies: number;
  exclusive: boolean;
}

export default class SaleService {
  static saleNFT = async (sale: Sale) => {
    try {
      const response = await Api.post<string>(
        urls.sale,
        toSnakeCaseObject(sale)
      );
      return toCamelCaseObject(response);
    } catch (e) {
      throw e;
    }
  };

  static editNFTSale = async (sale: Sale) => {
    try {
      const response = await Api.patch<string>(
        urls.sale,
        toSnakeCaseObject(sale)
      );
      return toCamelCaseObject(response);
    } catch (e) {
      throw e;
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  static sellNFTCollection = async (NFTCollection: any) => {
    const headers = {
      'content-type': 'multipart/form-data',
    };
    try {
      const response = await Api.post<string>(
        urls.sellNFTCollection,
        NFTCollection,
        { headers: headers }
      );
      return toCamelCaseObject(response);
    } catch (e) {
      throw e;
    }
  };
}
