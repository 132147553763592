const urlBaseResources = process.env.REACT_APP_RESOURCES_BASE_URL;
const urlBaseResourcesMobile = process.env.REACT_APP_RESOURCES_MOBILE_BASE_URL;

export const resources = {
  banksImg: `${urlBaseResources}/img/banks/`,
  logos: `${urlBaseResources}/img/logos/`,
  flags: `${urlBaseResources}/img/flags/`,
  paymentMethods: `${urlBaseResources}/img/payment_methods/wide/`,
  paymentMethodsMobile: `${urlBaseResourcesMobile}/img/payment_methods/`,
  refunds: `${urlBaseResourcesMobile}/img/refund_methods/`,
  banks: `${urlBaseResourcesMobile}/img/banks/`,
  merchants: `${urlBaseResourcesMobile}/img/merchants/`,
  resellers: `${urlBaseResources}/img/resellers/`,
  brands: `${urlBaseResourcesMobile}/img/brands/`,
  regions: `${urlBaseResourcesMobile}/img/regions/`,
};

const urls = {
  // AUTH
  login: '/public/v1/login',
  logout: '/public/v1/logout',
  signUp: '/public/v1/register',
  forgotPassword: '/public/v1/forgotPassword',
  changePassword: '/public/v1/changePassword',
  confirmEmail: '/public/v1/validateAccount',
  userExists: '/public/v1/user/:username/userexists',
  emailExists: '/public/v1/user/:email/emailexists',
  resendValidation: '/public/v1/resendValidation',

  // GENERAL
  getAllCountries: '/public/v1/country',

  // PRODUCTS
  getProduct: '/public/v1/product/:addressContractId/:tokenId',
  getProducts: '/public/v1/product/',
  getProductsSearch: '/public/v1/product/search',
  ownership: '/public/v1/ownership/:addressContractId/:tokenId',

  // SALE
  sale: '/public/v1/sale',
  sellNFTCollection: '/public/v1/sale/sellIntent',

  // ITEM ACTIVITY
  getItemActivity:
    '/public/v1/events/:addressContractId/:tokenId/?page=:page&size=:size',
  getCollections: '/public/v1/collection/list',

  // PRICE HISTORY
  getPriceHistory:
    '/public/v1/events/:addressContractId/:tokenId/?type=price&page=1&size=10',

  // ORDERS
  createOrder: '/public/v1/order',
  getOrderById: '/public/v1/order/:orderId',
  getOrderByUser: '/public/v1/order/?page=:page&size=:size',

  // PROFILE
  getProfile: '/public/v1/user/:username',
  getAssets: '/public/v1/user/:username/assets',
  getOrders: '/public/v1/order',
  updateProfile: '/public/v1/profile',
  isUserLogged: '/public/v1/profile/:userId/islogged',

  // WISHLIST
  addToWishList: '/public/v1/profile/wishlist',
  getUserWishList: '/public/v1/profile/wishlist',
  deleteWishList: '/public/v1/profile/wishlist/:wishlistAddress/:wishlistToken',

  // TOP COLLECTION
  getTopCollectionByWeek: '/public/v1/product/weekly',

  // NOTIFICATIONS
  getNotifications: '/public/v1/profile/notifications',
  setNotificationAsRead: '/public/v1/profile/notifications/seen',

  // MINT
  createCollection: '/public/v1/mint',
  createAsset: '/public/v1/mint/asset',
  slugValidate: '/public/v1/mint/slug-validate/:collectionName',
  getCollectionsByUser:
    '/public/v1/mint/collections?username=:username&status=:status&len=:len',
  getCollectionAssets: '/public/v1/mint/assets/:slug',
  mintCollection: '/public/v1/mint/mint-collection',
};

export default urls;
