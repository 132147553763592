import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { theme } from '../../../styles/material-theme';
import { makeStyles } from '@mui/styles';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Button,
} from '@mui/material';
import {
  setExploreFilterPrice,
  getExploreFilter,
  getExploreFiltersLoading,
} from '../../../redux/filters';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { FilterPriceState } from '../../../redux/filters/types';
import NumberFormatCustom from '../../common/number-format-custom';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useStyles = makeStyles(() => ({
  filterPriceFields: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    marginTop: theme.spacing(2),
  },
  filterPriceApplyButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    padding: '13px 15px',
  },
}));

export const FilterPrice = () => {
  const filtersLoading = useAppSelector(getExploreFiltersLoading);
  const [loading, setLoading] = useState<boolean>(false);
  const filters = useAppSelector(getExploreFilter);
  const [currency, setCurrency] = useState('BRL');
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (filters.min || filters.max) {
      setCurrency(filters.currency || 'BRL');
      setMin(filters.min || '');
      setMax(filters.max || '');
    }
    if (!filters.min && !filters.max) {
      setMin('');
      setMax('');
    }
  }, [filters]);

  useEffect(() => {
    setLoading(filtersLoading || false);
  }, [filtersLoading]);

  const dispatch = useAppDispatch();

  const handleChange = (e: SelectChangeEvent<string>) => {
    setCurrency(e.target.value);
  };

  const handleChangeMin = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setMin(e.target.value);
  };

  const handleChangeMax = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setMax(e.target.value);
  };

  const handleSubmit = () => {
    const payload: FilterPriceState = {
      min,
      max,
      currency,
    };
    dispatch(setExploreFilterPrice(payload));
  };

  return (
    <>
      <FormControl fullWidth>
        <Select value={currency} onChange={handleChange} disabled={loading}>
          <MenuItem value='BRL'>Real Brasileiro (BRL)</MenuItem>
          <MenuItem value='ETH'>Ethereum (ETH)</MenuItem>
        </Select>
      </FormControl>

      <Box className={classes.filterPriceFields}>
        <FormControl>
          <TextField
            id='outlined-number'
            label='Min'
            InputProps={{
              // eslint-disable-next-line  @typescript-eslint/no-explicit-any
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                thousandSeparator: false,
                decimalScale: 0,
                allowNegative: false,
              },
            }}
            value={min}
            disabled={loading}
            onChange={handleChangeMin}
          />
        </FormControl>
        <Typography>{t('to')}</Typography>
        <FormControl>
          <TextField
            id='outlined-number'
            label='Max'
            InputProps={{
              // eslint-disable-next-line  @typescript-eslint/no-explicit-any
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                thousandSeparator: false,
                decimalScale: 0,
                allowNegative: false,
              },
            }}
            value={max}
            disabled={loading}
            onChange={handleChangeMax}
          />
        </FormControl>
      </Box>
      <Button
        className={classes.filterPriceApplyButton}
        variant='outlined'
        onClick={handleSubmit}
        disabled={loading}
      >
        {t('apply')}
      </Button>
    </>
  );
};

export default FilterPrice;
