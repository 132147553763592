export interface User {
  userId: number;
  username: string;
  country: string;
  validity: number;
}

export interface SessionState {
  user: User;
  authenticated: boolean;
  loading: boolean;
}

export const emptyUser: User = {
  userId: -1,
  username: '',
  country: '',
  validity: -1,
};
