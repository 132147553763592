import urls from '../config/constants/urls';
import { buildPathWithParams } from '../config/helpers/url';
import { toCamelCaseObject } from '../config/helpers/utils';
import { Product, RProduct } from '../types/product';
import {
  GenericListWithPagination,
  PaginationResquest,
} from '../types/services';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export interface RProductLite {
  token_id: string;
  asset_contract_address: string;
  image_url: string;
  name: string;
  collection: {
    name: string;
    slug: string;
  };
  price: {
    decimals: number;
    eth_price: number;
    usd_price: number;
    session_price: number;
    symbol: string;
  };
  weekly_score: number;
  tags: { on_sale: boolean; trending: boolean };
}

export interface ProductLite {
  tokenId: string;
  assetContractAddress: string;
  imageUrl: string;
  name: string;
  collection: {
    name: string;
    slug: string;
  };
  price: {
    decimals: number;
    ethPrice: number;
    usdPrice: number;
    sessionPrice: number;
    symbol: string;
  };
  weeklyScore: number;
  tags: { onSale: boolean; trending: boolean };
}

export interface ParamsFilterProduct {
  min?: string;
  max?: string;
  currency?: string;
  collection?: string;
  query?: string;
  order?: string;
}

export type RProducts = GenericListWithPagination<RProductLite>;
export type Products = GenericListWithPagination<ProductLite>;

export interface RCollection {
  image_url: string;
  banner_image_url: string;
  name: string;
  slug: string;
}

export interface Collection {
  imageUrl: string;
  bannerImageUrl: string;
  name: string;
  slug: string;
}

export interface CollectionResponse {
  collections: Collection[];
}

export default class ProductsService {
  static getProducts = async (
    pageAndSize: PaginationResquest,
    params: ParamsFilterProduct
  ) => {
    const page = pageAndSize.page;
    const size = pageAndSize.size;
    try {
      const response = await Api.get<RProducts>(
        buildPathWithParams(urls.getProductsSearch, {}),
        { page, size, ...params }
      );
      return toCamelCaseObject<Products>(response);
    } catch (e) {
      throw e;
    }
  };

  static getProduct = async (addressContractId: string, tokenId: string) => {
    try {
      const response = await Api.get<RProduct>(
        buildPathWithParams(urls.getProduct, { addressContractId, tokenId })
      );
      return toCamelCaseObject<Product>(response);
    } catch (e) {
      throw e;
    }
  };

  static getCollections = async () => {
    try {
      const response = await Api.get<RCollection[]>(urls.getCollections);
      return toCamelCaseObject<CollectionResponse>(response);
    } catch (e) {
      throw e;
    }
  };

  static checkOwnership = async (
    addressContractId: string,
    tokenId: string
  ) => {
    try {
      const response = await Api.get<string>(
        buildPathWithParams(urls.ownership, { addressContractId, tokenId })
      );
      return response;
    } catch (e) {
      throw e;
    }
  };
}
