import { Box, Typography, Button, Divider, Grid } from '@mui/material';
import SimpleDialog from '../../../components/common/simple-dialog';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import AddProperties, { ITrait } from './add-properties';
import MultilineBtn from '../../../components/common/multiline-btn';

interface PropertiesProps {
  onSubmit: (items: ITrait[]) => void;
}

const Properties = ({ onSubmit }: PropertiesProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [traits, setTraits] = useState<ITrait[]>([]);
  const { t } = useTranslation();

  return (
    <Box paddingY={1}>
      <Box display='flex' justifyContent='space-between' marginBottom={2}>
        <Box display='flex' flexDirection='column'>
          <Typography color='textPrimary' fontWeight='bold'>
            {t('properties')}
          </Typography>
          <Typography color='textPrimary'>
            Textual traits that show up as rectangles
          </Typography>
        </Box>
        <Box display='flex'>
          <Button
            variant='outlined'
            onClick={() => setShowDialog(true)}
            sx={{ height: 50 }}
          >
            <AddIcon />
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {traits &&
          traits.map((trait, index) => (
            <Grid item xs={6} md={4} key={index}>
              <MultilineBtn title={trait.traitType} subtitle={trait.value} />
            </Grid>
          ))}
      </Grid>

      <Divider sx={{ paddingY: 2 }} />

      <SimpleDialog
        title={'Add Properties'}
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        content={
          <AddProperties
            onSubmit={(items) => {
              setTraits(items);
              onSubmit(items);
              setShowDialog(false);
            }}
            traits={traits}
          />
        }
        maxWidth={'md'}
        actions={[]}
      />
    </Box>
  );
};

export default Properties;
