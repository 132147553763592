import { Box, Grid } from '@mui/material';
import React from 'react';
import AboutCollection from './components/about-collection';
import CollectionProperties from './components/collection-properties';
import CollectionDescription from './components/collection-description';
import CollectionImage from './components/collection-image';
import { Product } from '../../../../types/product';
import CollectionPrice from '../main-column/components/collection-price';
import CollectionName from '../main-column/components/collection-name';

interface SummaryColumnProps {
  loading: boolean;
  product: Product;
  owner: string;
}

const SummaryColumn = ({ loading, product, owner }: SummaryColumnProps) => {
  return (
    <Grid item xs={12} md={5}>
      <CollectionImage loading={loading} product={product} />
      {/* Only Mobile */}
      <Box
        sx={{ flexDirection: 'column', display: { xs: 'flex', md: 'none' } }}
      >
        <CollectionName loading={loading} product={product} />
        <CollectionPrice loading={loading} product={product} owner={owner} />
      </Box>
      <CollectionDescription loading={loading} product={product} />
      <CollectionProperties loading={loading} product={product} />
      <AboutCollection loading={loading} product={product} />
    </Grid>
  );
};

export default SummaryColumn;
