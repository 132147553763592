import moment, { MomentInput } from 'moment';
import { ValueOfObject } from '../../types/global';

const convertSecondsToTime = (seconds: string) => {
  const parsed = parseInt(seconds, 10);
  return moment.duration(parsed, 'seconds');
};

const DateFormats = {
  RegularDate: 'L',
  RegularDateTime: 'L H:mm:ss',
  TextDateTime: 'll LTS',
  TextDate: 'll',
  MonthAndYear: 'MM/YYYY',
};

const formatDateFromNow = (dateObject: MomentInput) => {
  return moment.utc(dateObject).fromNow();
};

const formatDateUTC2Calendar = (dateObject: MomentInput) => {
  return moment.utc(dateObject).calendar();
};

const formatDate2Calendar = (dateObject: MomentInput) => {
  return moment(dateObject).calendar();
};

const formatDateUTCAs = (dateObject: MomentInput, format: ValueOfObject<typeof DateFormats>) => {
  return moment.utc(dateObject).format(format);
};

const formatDateAs = (dateObject: MomentInput, format: ValueOfObject<typeof DateFormats>) => {
  return moment(dateObject).format(format);
};

export {
  convertSecondsToTime,
  formatDateAs,
  formatDateFromNow,
  formatDate2Calendar,
  formatDateUTCAs,
  formatDateUTC2Calendar,
  DateFormats,
};
