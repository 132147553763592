import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Skeleton,
  Typography,
} from '@mui/material';
import OrdersService, {
  CreateOrderRequest,
  Order,
} from '../../../../../services/orders-service';
import {
  errorMessage,
  objectToArray,
} from '../../../../../config/helpers/utils';
import {
  currencyFormat,
  statusFormatter,
} from '../../../../../config/helpers/formatter';
import SelectPaymentMethod from '../../../../../components/common/select-payment-method';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { eventGATracker } from '../../../../../config/helpers/analyticsEvents';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MessageDialog from '../../../../../components/common/message-dialog';
import { getIsAuthenticated, getUser } from '../../../../../redux/session';
import SimpleDialog from '../../../../../components/common/simple-dialog';
import LoadingBtn from '../../../../../components/common/loading-btn';
import { useAppSelector } from '../../../../../redux/hooks';
import peleImg from '../../../../../assets/img/pele.png';
import { Product } from '../../../../../types/product';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

interface CollectionPriceProps {
  loading: boolean;
  product: Product;
  owner: string;
}

declare global {
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    AstropaySDK?: any;
  }
}

const CollectionPrice = ({ loading, product, owner }: CollectionPriceProps) => {
  const [showSelectPaymentDialog, setShowSelectPaymentDialog] =
    useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [astroLoaded, setAstroLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector(getUser);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showExcedeedsDialog, setShowExcedeedsDialog] =
    useState<boolean>(false);

  const appEnv = process.env.REACT_APP_ENV;

  // INIT SDK
  useEffect(() => {
    if (!astroLoaded) {
      const APP_ID = '4AllDegens';
      const ASTROPAY_CONFIG = {
        environment: appEnv === 'PROD' ? 'production' : 'sandbox', // defaults to production
        zIndex: 9999,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        onClose: (e: any) => {
          setLoadingBtn(false);
          if (e.status === 2 || e.status === 4) {
            setShowSuccessDialog(true);
            eventGATracker('payment', 'Payment Process Created');
          }
        },
      };
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (window as any).AstropaySDK.init(APP_ID, ASTROPAY_CONFIG);
      setAstroLoaded(true);
    }
  }, [astroLoaded, appEnv]);

  const showDeposit = async (depositExternalId: string) => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (window as any).AstropaySDK.showDeposit(depositExternalId);
  };

  // GET ORDERS
  useEffect(() => {
    async function getOrders() {
      if (isAuthenticated) {
        if (product) {
          try {
            const response = await OrdersService.getOrders();
            if (response) {
              const result = objectToArray(response).filter((order: Order) =>
                product.tokenId.includes(order.tokenId)
              );
              setOrderStatus(result[0].status);
            }
          } catch (e) {
            console.log('Orders Error');
          }
        }
      } else return;
    }
    getOrders();
  }, [enqueueSnackbar, product, showSuccessDialog]);

  // BUY NFT
  const buyNFT = async (type: 'IX' | 'WA') => {
    if (!isAuthenticated) {
      navigate(`/login?redirect=${window.location.pathname}`);
      return;
    }

    setLoadingBtn(true);
    try {
      const orderReq: CreateOrderRequest = {
        tokenId: product.tokenId,
        assetContractAddress: product.assetContractAddress,
        sellerWalletAddress:
          product.topOwnerships[0].owner.address || product.creator.address || '',
        currency: product.price.symbol,
        paymentMethodCode: type,
      };

      const response = await OrdersService.createOrder(orderReq);
      if (response) {
        if (response.payment.externalId !== undefined) {
          showDeposit(response.payment.externalId);
        } else if (response.payment.externalId === undefined) {
          setLoadingBtn(false);
          setShowExcedeedsDialog(true);
        }
      }
    } catch (e) {
      setLoadingBtn(false);
      enqueueSnackbar(errorMessage(e), {
        variant: 'error',
      });
    }
  };

  const handleCloseBuyingSuccess = () => {
    setShowSuccessDialog(false);
    navigate(`/user/${user.username}?tab=orders`);
  };

  return (
    <>
      <Accordion
        expanded={true}
        disabled={true}
        sx={{ marginBottom: '24px !important' }}
      >
        <AccordionDetails sx={{ borderRadius: '10px' }}>
          <Box display='flex' justifyContent='space-between'>
            <Typography color='textSecondary' marginBottom={1}>
              {t('current_price')}
            </Typography>
          </Box>
          <Typography
            variant='h4'
            color='textPrimary'
            display='inline-flex'
            fontWeight='bold'
          >
            {loading ? (
              <Skeleton width={200} />
            ) : (
              `${
                product?.price?.sessionPrice === 0
                  ? t('not_for_sale')
                  : currencyFormat(product.price.sessionPrice) + ' BRL'
              }`
            )}
          </Typography>
          <Typography color='textPrimary' display='inline-flex' marginLeft={1}>
            {loading ? (
              <Skeleton width={100} />
            ) : (
              `${
                product.price.ethPrice === 0
                  ? ''
                  : '(' + product.price.ethPrice + ' ETH)'
              }`
            )}
          </Typography>
          <Box display='flex'>
            <Box marginTop={2} width={{ xs: '100%', md: '50%' }}>
              <Button
                variant='contained'
                size='large'
                fullWidth
                disabled={
                  product?.price?.sessionPrice === 0 ||
                  loadingBtn ||
                  orderStatus === 'PENDING' ||
                  owner === 'ON_SALE' ||
                  owner === 'OWNED'
                }
                onClick={() => setShowSelectPaymentDialog(true)}
              >
                <AccountBalanceWalletIcon sx={{ marginRight: 1 }} />
                <LoadingBtn showLoading={loadingBtn} />
                {t('buy_now')}
              </Button>
            </Box>
            {orderStatus === 'PENDING' ? (
              <Box
                marginTop={2}
                width={{ xs: '100%', md: '50%' }}
                textAlign='right'
              >
                <Typography color='textSecondary' marginTop={1}>
                  Order Status: {statusFormatter(orderStatus)}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </AccordionDetails>
      </Accordion>

      <SimpleDialog
        isOpen={showSuccessDialog}
        onClose={handleCloseBuyingSuccess}
        content={
          <MessageDialog
            onClose={handleCloseBuyingSuccess}
            icon={<img src={peleImg} width='220' />}
            title={'Seu pedido foi feito'}
            description={`Seu pagamento está sendo compensado. Você receberá um e-mail com a confirmação quando terminar e poderá ver os detalhes do pedido em seu perfil. Tenha em mente que o processo de liberação pode levar até 24 hs.`}
          />
        }
        actions={[]}
        maxWidth={'sm'}
      />

      <SimpleDialog
        isOpen={showSelectPaymentDialog}
        onClose={() => setShowSelectPaymentDialog(false)}
        content={
          <SelectPaymentMethod paymentSelected={(payment) => buyNFT(payment)} />
        }
        actions={[]}
        maxWidth={'sm'}
      />

      <SimpleDialog
        isOpen={showExcedeedsDialog}
        onClose={() => setShowExcedeedsDialog(false)}
        content={
          <MessageDialog
            onClose={() => setShowExcedeedsDialog(false)}
            icon={<AdminPanelSettingsIcon sx={{ fontSize: 128 }} />}
            title={'Sua compra excede $ 5.000'}
            description={`Como sua compra excede $ 5.000, alguém de nossa equipe entrará em
            contato com você por e-mail para ajudá-lo pessoalmente a obter
            esse NFT.`}
          />
        }
        actions={[]}
        maxWidth={'sm'}
      />
    </>
  );
};

export default CollectionPrice;
