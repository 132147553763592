import { Card, CardMedia, Skeleton } from '@mui/material';
import React from 'react';
import { Product } from '../../../../../types/product';

interface CollectionImageProps {
  loading: boolean;
  product: Product;
}

const CollectionImage = ({ loading, product }: CollectionImageProps) => {
  return (
    <Card sx={{ marginBottom: 3 }}>
      {loading ? (
        <Skeleton variant='rectangular' width={466} height={466} />
      ) : (
        <CardMedia
          component='img'
          height='100%'
          image={product.imageUrl}
          alt={product.name}
        />
      )}
    </Card>
  );
};

export default CollectionImage;
