import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from '../../../../../styles/material-theme';
import PanToolIcon from '@mui/icons-material/PanTool';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSnackbar } from 'notistack';
import { errorMessage } from '../../../../../config/helpers/utils';
import ItemActivityService, {
  emptyItemActivityPagination,
  ItemActivity,
  ItemActivityPagination,
} from '../../../../../services/item-activity-service';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import moment from 'moment';
import { Waypoint } from 'react-waypoint';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../../../../config/helpers/formatter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const showIcon = (event: string) => {
  switch (event) {
    case 'offer_entered':
      return <PanToolIcon sx={{ marginRight: 1 }} />;
    case 'transfer':
      return <CompareArrowsIcon sx={{ marginRight: 1 }} />;
    case 'successful':
      return <ShoppingCartIcon sx={{ marginRight: 1 }} />;
    case 'created':
      return <LocalOfferIcon sx={{ marginRight: 1 }} />;
    case 'cancelled':
      return <HighlightOffIcon sx={{ marginRight: 1 }} />;
    case 'bid_withdrawn':
      return <ArrowForwardIcon sx={{ marginRight: 1 }} />;
  }
};

const showEvent = (event: string) => {
  switch (event) {
    case 'offer_entered':
      return 'Offer';
    case 'created':
      return 'List';
    case 'successful':
      return 'Sale';
    case 'transfer':
      return 'Transfer';
    case 'cancelled':
      return 'Cancelled';
    case 'bid_withdrawn':
      return 'Withdrawn';
    default:
      return event;
  }
};

const ItemActivityTable = () => {
  const [itemActivities, setItemActivities] = useState<ItemActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [itemActivitiesPagination, setItemActivitiesPagination] =
    useState<ItemActivityPagination>(emptyItemActivityPagination);

  const location = useLocation();
  const path = location.pathname;
  const tokenId = path.substring(path.lastIndexOf('/') + 1);
  const addressContractId = path.substring(9, path.lastIndexOf('/'));
  const { t } = useTranslation();

  const getItemActivity = async (nextPage: string) => {
    setLoading(true);
    try {
      const response = await ItemActivityService.getItemActivity(
        addressContractId,
        tokenId,
        nextPage,
        '15'
      );
      if (response) {
        setItemActivitiesPagination(response);
        setItemActivities((itemActivities) => {
          if (itemActivities) {
            return [...itemActivities, ...response.items];
          }
          return response.items;
        });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(errorMessage(e), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    getItemActivity('1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculatePrice = (symbol: string, price: number) => {
    if (symbol === undefined || price === 0) {
      return '';
    } else {
      // const result = bid * Math.pow(10, -decimals) * usd;
      return currencyFormat(price) + ' ' + symbol;
    }
  };

  const askForMore = (next: string) => {
    getItemActivity(next);
  };

  return (
    <Grid item xs={12}>
      <Accordion expanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <CompareArrowsIcon sx={{ marginRight: 1 }} />
          <Typography color='textPrimary' fontWeight='bold'>
            {t('item_activity')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ maxHeight: 330 }}>
            <Table
              sx={{ minWidth: 600 }}
              style={{ tableLayout: 'fixed' }}
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t('event')}</TableCell>
                  <TableCell>{t('price')}</TableCell>
                  <TableCell>{t('from')}</TableCell>
                  <TableCell>{t('to')}</TableCell>
                  <TableCell>{t('date')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemActivities !== undefined
                  ? itemActivities?.map((item, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <Box display='flex' alignItems='center'>
                            {showIcon(item.eventType)}
                            {showEvent(item.eventType)}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {calculatePrice(
                            item?.price?.symbol,
                            item?.price?.sessionPrice
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              color: theme.palette.primary.main,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            variant='body2'
                          >
                            {item.fromAccount?.user?.username !== undefined ? (
                              item.fromAccount?.user?.username
                            ) : (
                              <Tooltip title={item.fromAccount?.address}>
                                <span>{item.fromAccount?.address}</span>
                              </Tooltip>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              color: theme.palette.primary.main,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            variant='body2'
                          >
                            {item.toAccount?.user?.username !== undefined ? (
                              item.toAccount?.user?.username
                            ) : (
                              <Tooltip title={item.toAccount?.address}>
                                <span>{item.toAccount?.address}</span>
                              </Tooltip>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(item.createdDate).fromNow()}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
            {loading ? (
              <Box textAlign='center'>
                <CircularProgress size={20} />
              </Box>
            ) : null}
            <Box height={2}>
              <Waypoint
                onEnter={() => {
                  if (!loading) {
                    askForMore(itemActivitiesPagination.page + 1);
                  }
                }}
              />
            </Box>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ItemActivityTable;
