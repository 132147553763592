import ExploreFilters from './components/explore-filters/explore-filters';
import { emptyProductListWithPagination, PaginationResquest } from '../../types/services';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import ExploreGrid from './components/explore-grid/explore-grid';
import { errorMessage } from '../../config/helpers/utils';
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import {
  getExploreFilter,
  setExploreFiltersLoading,
} from '../../redux/filters';
import ProductsService, {
  Collection,
  ParamsFilterProduct,
  ProductLite,
  Products,
} from '../../services/products-service';

const Explore = () => {
  const [productsPagination, setProductsPagination] = useState<Products>(
    emptyProductListWithPagination
  );
  const [cacheExplore, setCacheExplore] = useState<ParamsFilterProduct>();
  const [productsItems, setProductsItems] = useState<ProductLite[]>([]);
  const [page, setPage] = useState<PaginationResquest>({ page: 1, size: 12 });
  const [collections, setCollections] = useState<Collection[]>([]);
  const [loadingAll, setLoadingAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const explore = useAppSelector(getExploreFilter);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setCacheExplore(explore);

    if (cacheExplore !== explore) {
      setPage({ page: 1, size: 12 });
      setLoadingAll(true);
    }

    async function getProducts() {
      if (!loading) {
        try {
          setLoading(true);
          dispatch(setExploreFiltersLoading(true));
          const response = await ProductsService.getProducts(
            cacheExplore !== explore ? { page: 1, size: 12 } : page,
            explore
          );
          if (response) {
            setProductsPagination(response);
            setProductsItems((productItems) => {
              if (productItems && cacheExplore === explore) {
                return [...productItems, ...response.items];
              }
              return response.items;
            });
            setLoading(false);
            setLoadingAll(false);
            dispatch(setExploreFiltersLoading(false));
          }
        } catch (e) {
          setLoading(false);
          setLoadingAll(false);
          dispatch(setExploreFiltersLoading(false));
          enqueueSnackbar(errorMessage(e), {
            variant: 'error',
          });
        }
      } else return;
    }
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [explore, dispatch, enqueueSnackbar, productsPagination, page]);

  useEffect(() => {
    async function getCollections() {
      try {
        const response = await ProductsService.getCollections();
        if (response) {
          setCollections(response.collections);
        }
      } catch (e) {
        enqueueSnackbar('Get Collection Error', {
          variant: 'error',
        });
      }
    }
    getCollections();
  }, [enqueueSnackbar]);

  return (
    <Box display='flex' sx={{ marginTop: '68px' }}>
      <ExploreFilters collections={collections} />
      <ExploreGrid
        loading={loading}
        loadingAll={loadingAll}
        productsItems={productsItems}
        productsPagination={productsPagination}
        collections={collections}
        onAskForMore={() => setPage({ ...page, page: page.page + 1 })}
      />
    </Box>
  );
};

export default Explore;
