import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  Avatar,
  IconButton,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../../styles/material-theme';
import logo from '../../assets/img/logo-white.png';
import brflag from '../../assets/img/br.svg';
import gbflag from '../../assets/img/gb.svg';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLang, setLang } from '../../redux/global';
import TwitterIcon from '@mui/icons-material/Twitter';
import tiktokIcon from '../../assets/img/tiktok-icon.png';

const useStyles = makeStyles(() => ({
  flagContent: {
    display: 'flex',
    gap: 10,
    margin: '24px 0',
  },
  flagAvatar: {
    border: '2px solid transparent',
    filter: 'grayscale(100%)',
    width: 35,
    height: 35,
    '&:hover': {
      border: '2px solid #fff',
      cursor: 'pointer',
      filter: 'grayscale(0%)',
    },
  },
  flagAvatarActive: {
    border: '2px solid #fff',
    filter: 'grayscale(20%)',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const defaultLang = useAppSelector(getLang);
  const [lang, setLanguage] = React.useState(defaultLang);
  const dispatch = useAppDispatch();

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    dispatch(setLang(lang));
    setLanguage(lang);
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        minHeight: 300,
        color: theme.palette.text.primary,
      }}
    >
      <Container maxWidth='xl'>
        <Grid container paddingTop={2} paddingBottom={6} spacing={4}>
          <Grid item xs={12} md={3}>
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
              <img
                src={logo}
                alt='4 All Degens'
                width='159'
                height='45'
                style={{ marginTop: '16px' }}
              />
            </Link>
            <Divider sx={{ marginTop: 3, width: { xs: '100%', md: '80%' } }} />
            <Typography
              color='white'
              variant='h6'
              fontWeight='bold'
              marginTop={3}
            >
              {t('contact_us')}
            </Typography>
            <Typography color='textSecondary' variant='body2' marginTop={1}>
              ola@4alldegens.com
            </Typography>

            <Box>
              <Typography
                color='white'
                variant='h6'
                fontWeight='bold'
                marginTop={3}
              >
                {t('join_the_community')}
              </Typography>
              <IconButton
                href='https://twitter.com/4alldegens'
                target='_blank'
                sx={{ marginLeft: '-8px' }}
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                href='https://www.tiktok.com/@4alldegens'
                target='_blank'
              >
                <img src={tiktokIcon} width='24' />
              </IconButton>
            </Box>

            <Divider sx={{ marginTop: 3, width: { xs: '100%', md: '80%' } }} />

            <Box className={classes.flagContent}>
              <Avatar
                alt='BR Flag'
                src={brflag}
                className={`${classes.flagAvatar} ${
                  lang == 'pt' && classes.flagAvatarActive
                }`}
                onClick={() => handleChangeLanguage('pt')}
              />
              <Avatar
                alt='GB Flag'
                src={gbflag}
                className={`${classes.flagAvatar} ${
                  lang == 'en' && classes.flagAvatarActive
                }`}
                onClick={() => handleChangeLanguage('en')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              color='white'
              variant='h6'
              fontWeight='bold'
              marginTop={2}
            >
              {t('marketplace')}
            </Typography>
            <Link
              to='/explore'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('explore')}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              color='white'
              variant='h6'
              fontWeight='bold'
              marginTop={2}
            >
              {t('my_account')}
            </Typography>
            <Link
              to='/login'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('login')}
              </Typography>
            </Link>
            <Link
              to='/signup'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('register')}
              </Typography>
            </Link>
            <Link
              to='/profile'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('profile')}
              </Typography>
            </Link>
            <Link
              to='/faq'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('faq')}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              color='white'
              variant='h6'
              fontWeight='bold'
              marginTop={2}
            >
              {t('policy')}
            </Typography>
            <Link
              target='_blank'
              to='/terms-of-use'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('terms_of_use')}
              </Typography>
            </Link>
            <Link
              target='_blank'
              to='/privacy-policy'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('privacy')}
              </Typography>
            </Link>
            <Link
              target='_blank'
              to='/cookies-policy'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color='textSecondary' variant='body2' marginTop={2}>
                {t('cookies')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
