import { useSpring, animated, config } from 'react-spring';
import { theme } from '../../styles/material-theme';
import { Link } from 'react-router-dom';
import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

const calc = (x: number, y: number) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1,
];
const trans = (x: number, y: number, s: number) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const AnimatedCard = () => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: config.default,
  }));

  return (
    <animated.div
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{
        transform: props.xys.interpolate(trans),
      }}
    >
      <Link to='/explore' style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            marginTop: { xs: 6, md: 12 },
            background: theme.palette.grey.A400,
            backdropFilter: 'blur(10px)',
            border: '2px solid transparent',
          }}
        >
          <CardActionArea>
            <Box margin={'16px 16px 0 16px'} borderRadius={2} overflow='hidden'>
              <CardMedia
                component='img'
                height='420'
                image='https://lh3.googleusercontent.com/lg45w8ME5wSZqE2aElUnUVXDB4Y-4wpX8Dch2uWG-xs3GjK_r6lDQB2FJfE_1Eif-mMEBJBVm1yvYYMkFarrLCpqEwyKhI_nI9ZGlQ'
                alt='NFT CARD'
                sx={{ borderRadius: 2 }}
              />
            </Box>
            <CardContent>
              <Box display='flex' alignItems='center'>
                <Typography gutterBottom variant='body1' sx={{ marginLeft: 2 }}>
                  3Landers #9880
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </animated.div>
  );
};

export default AnimatedCard;
