import { Box, Button, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  title: string;
  description?: string;
  icon: ReactNode;
}

const MessageDialog: React.FC<Props> = ({
  onClose,
  title,
  description,
  icon,
}) => {
  const { t } = useTranslation();
  
  return (
    <Box textAlign='center' paddingX={4} paddingBottom={4}>
      <Box
        sx={{
          margin: '32px auto 16px',
          fontSize: 128,
        }}
      >
        {icon}
      </Box>

      <Typography fontWeight='bold' marginY={2} fontSize={18}>
        {title}
      </Typography>

      {description && <Typography marginBottom={8}>{description}</Typography>}

      <Button variant="contained" size="large" color="primary" type="submit" onClick={() => onClose()} disableElevation>
        {t("continue")}
      </Button>
    </Box>
  );
};

export default MessageDialog;
