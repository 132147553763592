import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {
  cleanExploreFilters,
  cleanExploreFiltersCollection,
  cleanExploreFiltersOrder,
  cleanExploreFiltersPrice,
  cleanExploreFiltersQuery,
  getExploreFilter,
} from '../../../../redux/filters';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ExploreFilterChips = () => {
  const filters = useAppSelector(getExploreFilter);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getMinMaxLabel = () => {
    if (filters.min && filters.max && filters.currency)
      return `${filters.currency}: ${filters.min} - ${filters.max}`;
    else if (filters.min) return `${filters.currency}: > ${filters.min}`;
    else if (filters.max) return `${filters.currency}: < ${filters.max}`;
  };

  const haveMaxMin = () => {
    if (filters.min || filters.max) return true;
    else return false;
  };

  const deleteAll = () => {
    dispatch(cleanExploreFilters());
  };

  const deletePrices = () => {
    dispatch(cleanExploreFiltersPrice());
  };

  const deleteCollection = () => {
    dispatch(cleanExploreFiltersCollection());
  };

  const deleteOrder = () => {
    dispatch(cleanExploreFiltersOrder());
  };

  const deleteQuery = () => {
    dispatch(cleanExploreFiltersQuery());
  };

  const labelChips = (label: string) => {
    switch (label) {
      case 'asc':
        return t('price_low_to_hight');
      case 'desc':
        return t('price_high_to_low');
      case 'pop':
        return t('label_pop');
      case 'fav':
        return t('label_fav');
    }
  };

  return (
    <Stack direction='row' spacing={1}>
      {filters['query'] && (
        <Chip
          label={filters['query']}
          variant='outlined'
          onClick={deleteQuery}
          onDelete={deleteQuery}
        />
      )}
      {haveMaxMin() && (
        <Chip
          label={getMinMaxLabel()}
          variant='outlined'
          onClick={deletePrices}
          onDelete={deletePrices}
        />
      )}
      {filters['collection'] && (
        <Chip
          label={filters['collection']}
          variant='outlined'
          onClick={deleteCollection}
          onDelete={deleteCollection}
        />
      )}
      {filters['order'] && (
        <Chip
          label={labelChips(filters['order'])}
          variant='outlined'
          onClick={deleteOrder}
          onDelete={deleteOrder}
        />
      )}
      {(filters['query'] ||
        filters.min ||
        filters.max ||
        filters['order'] ||
        filters['collection']) && (
        <Chip label='Clear all' onClick={deleteAll} onDelete={deleteAll} />
      )}
    </Stack>
  );
};

export default ExploreFilterChips;
