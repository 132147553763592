import urls from "../config/constants/urls";
import { buildPathWithParams } from "../config/helpers/url";
import { toCamelCaseObject, toSnakeCaseObject } from "../config/helpers/utils";
import { GenericListWithPagination } from "../types/services";
import { HttpService } from "./http-service";

const Api = new HttpService(true);

export interface Country {
  isocode: string;
  name: string;
}

export interface CreateOrderRequest {
  tokenId: string;
  assetContractAddress: string;
  sellerWalletAddress: string;
  currency: string;
  paymentMethodCode: 'IX' | 'WA';
}

export interface CreateOrderResponse {
  id: number;
  tokenId: string;
  assetContractAddress: string;
  sellerWalletAddress: string;
  usdPrice: number;
  ethPrice: number;
  buyerUserId: number;
  status: string;
  paymentStatus: string;
  purchaseStatus: string;
  payment: {
    externalId: string;
    status: string;
    merchantPaymentId: string;
  };
}

export interface ROrder {
  product_token: string;
  seller_wallet_address: string;
  usd_price: number;
  eth_price: number;
  buyer_user_id: number;
  status: string;
  payment_status: string;
  purchase_status: string;
}

export interface Order {
  productToken: string;
  purchaseStatus: string;
  status: string;
  paymentStatus: string;
  usdPrice: number;
  ethPrice: number;
  sellerWalletAddress: string;
  assetContractAddress: string;
  buyerUserId: number;
  placedDate: string;
  tokenId: string;
}

export type ROrders = GenericListWithPagination<ROrder>;
export type Orders = GenericListWithPagination<Order>;

export default class OrdersService {
  static createOrder = async (createOrderRequest: CreateOrderRequest) => {
    try {
      const response = await Api.post<string>(
        urls.createOrder,
        toSnakeCaseObject(createOrderRequest)
      );
      return toCamelCaseObject<CreateOrderResponse>(response);
    } catch (e) {
      throw e;
    }
  };

  static getOrders = async () => {
    try {
      const response = await Api.get<ROrder[]>(buildPathWithParams(urls.getOrders, {}));
      return toCamelCaseObject<Orders>(response);
    } catch (e) {
      throw e;
    }
  };
}
