import { countryFormatter } from '../../config/helpers/formatter';
import { Box, Skeleton, Typography } from '@mui/material';
import { Profile } from '../../types/profile';
import React from 'react';

interface Props {
  profile: Profile;
  loading: boolean;
}

export const InfoComponent: React.FC<Props> = (props) => {
  const { profile, loading } = props;

  return loading ? (
    <Box display='flex' flexDirection='column' gap='30px' maxWidth='400px'>
      <Skeleton variant='text' sx={{ fontSize: '2rem' }} />
      <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
    </Box>
  ) : (
    <Box mb={3} textAlign={{ xs: 'center', md: 'left' }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent={{ xs: 'center', md: 'left' }}
        gap={2}
      >
        <Typography variant='h6' color='primary' fontSize={36}>
          {profile.username}
        </Typography>
        <Box display='flex' alignItems='center' gap={0.5}>
          {countryFormatter(profile.country)}
          <Typography fontSize={14} marginBottom={0} marginTop='4px'>
            {profile.country}
          </Typography>
        </Box>
      </Box>
      <Typography>{profile.email}</Typography>
    </Box>
  );
};

export default InfoComponent;
