import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Card,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WebIcon from '@mui/icons-material/Web';
import InstagramIcon from '@mui/icons-material/Instagram';
import { theme } from '../../../../../styles/material-theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Product } from '../../../../../types/product';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../../redux/hooks';
import { cleanExploreFilters, setExploreFilterCollection } from '../../../../../redux/filters';

interface AboutCollectionProps {
  loading: boolean;
  product: Product;
}

const AboutCollection = ({ product }: AboutCollectionProps) => {
  const dispatch = useAppDispatch();
  
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TextSnippetIcon sx={{ marginRight: 1 }} />
          <Link
            to={`/explore`}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              dispatch(cleanExploreFilters());
              dispatch(setExploreFilterCollection(product?.collection?.name));
            }}
          >
            <Typography color='textPrimary' fontWeight='bold'>
              About {product.collection.name || 'Collection'}
            </Typography>
          </Link>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Card>
                <CardMedia
                  component='img'
                  height='100%'
                  image={product.collection.imageUrl}
                  alt='img'
                />
              </Card>
            </Grid>
            <Grid item xs={10}>
              <Typography color='textPrimary' marginBottom={1} fontSize={14}>
                {product.collection.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup
                variant='outlined'
                sx={{
                  marginTop: 4,
                }}
              >
                {/* WEB */}
                {product.collection.externalUrl ? (
                  <Tooltip title='Web'>
                    <Button
                      href={product.collection.externalUrl}
                      target='_blank'
                    >
                      <WebIcon />
                    </Button>
                  </Tooltip>
                ) : null}

                {/* DISCORD */}
                {product.collection.discordUrl ? (
                  <Tooltip title='Discord'>
                    <Button
                      href={product.collection.discordUrl}
                      target='_blank'
                    >
                      <svg
                        fill={theme.palette.primary.main}
                        viewBox='0 0 22 16'
                        style={{ height: '20px', width: '20px' }}
                      >
                        <path d='M8.11.5c-.28.002-2.574.067-4.996 1.873 0 0-2.584 4.665-2.584 10.408 0 0 1.507 2.593 5.473 2.719 0 0 .664-.792 1.202-1.477-2.278-.685-3.14-2.108-3.14-2.108s.18.126.502.307c.018 0 .035.019.07.036.055.035.108.054.162.09.448.252.896.45 1.31.611.736.307 1.615.576 2.639.774 1.346.252 2.925.342 4.646.019a12.954 12.954 0 002.603-.774 10.118 10.118 0 002.062-1.063s-.896 1.458-3.247 2.125c.538.666 1.185 1.439 1.185 1.439 3.965-.126 5.473-2.72 5.473-2.7 0-5.746-2.584-10.409-2.584-10.409C16.32.446 13.861.5 13.861.5l-.251.288c3.05.918 4.468 2.27 4.468 2.27a14.856 14.856 0 00-5.4-1.711 15.048 15.048 0 00-3.626.036c-.107 0-.197.019-.306.035-.628.072-2.153.288-4.073 1.135-.663.288-1.059.505-1.059.505S5.088 1.635 8.317.717L8.137.5h-.028zm-.457 6.645c1.022 0 1.849.882 1.83 1.981 0 1.1-.808 1.982-1.83 1.982-1.005 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981zm6.55 0c1.004 0 1.83.882 1.83 1.981 0 1.1-.809 1.982-1.83 1.982-1.006 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981z'></path>
                      </svg>
                    </Button>
                  </Tooltip>
                ) : null}

                {/* INSTAGRAM */}
                {product.collection.instagramUsername ? (
                  <Tooltip title='Instagram'>
                    <Button
                      href={`https://instagram.com/${product.collection.instagramUsername}`}
                      target='_blank'
                    >
                      <InstagramIcon />
                    </Button>
                  </Tooltip>
                ) : null}

                {/* TWITTER */}
                {product.collection.twitterUsername ? (
                  <Tooltip title='Twitter'>
                    <Button
                      href={`https://twitter.com/${product.collection.twitterUsername}`}
                      target='_blank'
                    >
                      <TwitterIcon />
                    </Button>
                  </Tooltip>
                ) : null}
              </ButtonGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AboutCollection;
