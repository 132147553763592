const pt = {
  translation: {
    search_items_and_collect: 'Pesquise itens e colete',
    explore: 'Explore',
    buy_collect_and_enjoy:
      'Compre, colete e aproveite NFTs mais fácil que nunca',
    a_curated_collection_of_nf:
      'Uma coleção com curadoria de NFTs, com seu método de pagamento favorito, sem necessidade de carteiras complexas e outros aborrecimentos da web3',
    register: 'Registre-se',
    nf_ts_in_seconds: 'NFTs, em segundos',
    create_your_accont: 'Crie sua conta',
    create_your_own_account:
      'Crie sua própria conta com seus dados pessoais como sempre fez.',
    search_your_nft: 'Procure seu NFT',
    make_a_pic_in_any:
      'Dê uma espiada em qualquer um dos nossos NFTs exibidos, não se preocupe com a rede, provedor ou tecnicidade, nós resolvemos isso para você.',
    purchase_with_usd: 'Compre com USD ou moeda local',
    buy_cell_your_favorite:
      'Compre e venda seu NFT favorito com sua moeda local e com seu método de pagamento favorito.',
    we_hold_it_safely_for_you: 'Nós guardamos com segurança para você',
    we_will_secure_your:
      'Protegeremos seu NFT dentro da blockchain em nossos cofres e ajudaremos você a cuidar bem dele.',
    last_week_s_top_collections: 'NFTs mais vistos desta semana',
    sign_up: 'Cadastre-se ',
    name: 'Nome',
    username: 'Nome do usuário',
    email: 'E-mail',
    password: 'Senha',
    country: 'País',
    other: 'Outro',
    afghanistan: 'Afeganistão',
    brazil: 'Brasil',
    chile: 'Chile',
    china: 'China',
    colombia: 'Colômbia',
    india: 'Índia',
    mexico: 'México',
    negeria: 'Nigéria',
    sweden: 'Suécia',
    uganda: 'Uganda',
    uruguay: 'Uruguai',
    united_kingdom: 'Reino Unido',
    this_field_is_required: 'Este campo é obrigatório',
    create_account: 'Criar conta',
    you_already_have: 'Você já tem uma conta? Faça seu login',
    filter: 'Filtro',
    price: 'Preço',
    price_history: 'Histórico de preços',
    min_to_max: 'Mín até Máx',
    apply: 'Aplicar',
    collections: 'Coleções',
    search: 'Procurar',
    sort_by: 'Ordenar por',
    price_low_to_hight: 'Mais baixo para mais alto',
    price_high_to_low: 'Mais alto para mais baixo',
    sign_in: 'Entrar',
    contact_us: 'Fale Conosco',
    profile: 'Perfil',
    login: 'Entrar',
    my_account: 'Minha conta',
    marketplace: 'Mercado',
    logout: 'Sair',
    to: 'para',
    best_offer: 'Melhor oferta',
    collection: 'Coleção',
    Continue: 'Continuar',
    clear_all: 'Limpar tudo',
    done: 'pronto',
    please_enter_a_valid_email_address:
      'Por favor, digite um endereço de e-mail válido',
    email_sent_successfully: 'E-mail enviado com sucesso',
    password_must_have_at_least_8_characters:
      'A senha deve ter pelo menos 8 caracteres.',
    password_must_contain_upper_case_lower_case_and_numbers:
      'A senha deve conter letras maiúsculas, minúsculas e números.',
    passwords_do_not_match: 'Senhas não coincidem',
    new_password_set_successfully: 'Nova senha definida com sucesso',
    esqueci_senha: 'Esqueceu a senha?',
    don_t_worry_please_enter:
      'Não se preocupe! Digite seu e-mail associado à sua conta. Enviaremos um código para você escolher um novo.',
    send_code: 'Enviar Código',
    back_to_login: 'Voltar ao login',
    please_enter_a_new_password: 'Por favor, digite uma nova senha',
    and_the_code_that_we_send_to_your_email:
      'E o código que enviamos para o seu e-mail.',
    new_password: 'Nova Senha',
    confirm_password: 'Confirmar Senha',
    login_successfully: 'Login com sucesso',
    don_t_have_an_account_sign_up: 'Não tem uma conta? Inscreva-se',
    current_price: 'Preço Atual',
    buy_now: 'Compre agora',
    buy_successfully: 'Compra com sucesso',
    your_payment_is_being_credited:
      'Seu pagamento está sendo creditado, nas próximas 24 horas você receberá um e-mail com a confirmação da compra. Você pode ver os detalhes do Pedido no seu Perfil.',
    your_purchase_exceeds: 'Sua compra excede R$ 25.000',
    as_your_purchase_exceeds:
      'Como sua compra ultrapassa R$ 25.000, nossa equipe entrará em contato por e-mail para realizar a operação de forma personalizada.',
    item_activity: 'Atividade do Item',
    event: 'Evento',
    From: 'De',
    data: 'Data',
    description: 'Descrição',
    created: 'Criado',
    properties: 'Propriedades',
    no_properties: 'Sem Propriedades',
    more_from_this_collection: 'Mais desta coleção',
    view_collection: 'Ver coleção',
    usd_price: 'Preço em dólares',
    eth_price: 'Preço Eth',
    from: 'A partir de',
    date: 'encontro',
    seller_wallet_address: 'Endereço da carteira do vendedor',
    asset_contract_address: 'Endereço do Contrato do Ativo',
    buyer_user_id: 'ID de usuário do comprador',
    estado: 'Estado',
    information_updated_successfully: 'Informações atualizadas com sucesso',
    save_changes: 'Salvar alterações',
    email_confirmed_successfully: 'E-mail confirmado com sucesso',
    sign_up_successfully: 'Cadastre-se com sucesso',
    you_already_have_an_account_sign_in: 'Você já tem uma conta? Entrar',
    confirm_your_email: 'Confirme seu e-mail',
    please_check_your_inbox:
      'Por favor, verifique sua caixa de entrada para um e-mail de confirmação. Clique no link no e-mail para confirmar seu endereço de e-mail.',
    content_not_available_yet: 'Conteúdo ainda não disponível',
    not_for_sale: 'Não está a venda',
    forgot_password: 'Esqueceu sua senha',
    policy: 'Política da web',
    privacy: 'Termo de privacidade',
    cookies: 'Cookies',
    username_exists: 'O nome de usuário já existe',
    email_exists: 'e-mail já existe',
    you_dont_have_nft_yet: 'Você ainda não tem NFT',
    you_dont_any_order: 'Você ainda não tem nenhum pedido pendente',
    go_to_explore: 'Ir para explorar',
    collected: 'Coletado',
    orders: 'Pedidos',
    page_not_found: 'Oops, página não encontrada',
    no_items_to_display: 'Nenhum item para exibir',
    do_you_have_questions: 'Você tem perguntas?',
    we_have_answers: 'Nós temos respostas',
    q_what_is_4alldegens: 'O que é 4 All Degens NFT Marketplace?',
    q_how_to_get_started: 'Como começar?',
    q_how_to_buy_nft: 'Como comprar uma NFT em 4 All Degens?',
    q_how_to_sell_nft: 'Como vender uma NFT em 4 All Degens?',
    q_how_to_see_my_nft_vault: 'Como ver meu Vault NFT?',
    q_where_is_my_nft: 'Onde está meu NFT?',
    q_what_is_a_nft: 'O que é um NFT?',
    q_other_questions: 'Outras perguntas?',
    a_what_is_4alldegens:
      'É o primeiro mercado da NFT que ajuda usuários de todo o mundo a adquirir seus NFTs favoritos em sua moeda local. Estamos entre o Web 3.0, os Stands complexos e de blockchain e o cliente regular que deseja apenas comprar uma NFT.',
    a_how_to_get_started:
      'Inscreva -se em uma conta de 4 degas 4, confirme seu e -mail, escolha NFT, pague em sua moeda favorita, feito!',
    a_how_to_buy_nft:
      'Você só precisa clicar em comprar na sua NFT disponível, conecte -se ao seu número de telefone e selecione seu método de pagamento favorito.',
    a_how_to_sell_nft:
      'Aceite você vender requisitos ou entrar em contato com nossos agentes em ola@4alldegens.com.',
    a_how_to_see_my_nft_vault:
      'Clique no perfil da sua conta e verifique sua coleção complacente em 4 degas.',
    a_where_is_my_nft:
      '4 Todas as degas cofumas todas a NFT em um cofre seguro facilitando a segurança, a confiabilidade e a usabilidade.',
    a_what_is_a_nft:
      "A NFT, definida como 'tokes não fungíveis', são ativos digitais exclusivos na blockchain que representam comunidades, arte, valor intelectual e qualquer outro valor potencial. Eles vivem em diferentes blockchains e servem para casos de uso alternativos.",
    a_other_questions:
      'Entre em contato com ola@4alldegens.com, estamos aqui para ajudá -lo a entrar na nova economia digital.',
    faq: 'Perguntas frequentes',
    sell: 'Vender',
    do_you_want_request_sales_order: 'Deseja solicitar um pedido de venda?',
    a_representative_will_contact_you:
      'Um representante entrará em contato com você nas próximas 24 horas para coordenar os detalhes da venda.',
    request_a_sales_order: 'Solicitar um pedido de venda',
    request_sale_successfully: 'Venda solicitada com sucesso',
    select_price: 'Selecione o preço que deseja vender seu NFT',
    currency: 'Moeda',
    confirm: 'Confirme',
    will_contact_you:
      'Um representante entrará em contato para coordenar os detalhes da venda.',
    ON_SALE: 'À venda',
    OWNED: 'Possuído',
    username_between_4_20: 'O nome de usuário deve ter entre 4 e 20 caracteres',
    email_password_incorrect: 'Email e/ou senha errados, tente novamente',
    something_went_wrong_please_try_again:
      'Algo deu errado. Por favor, tente novamente mais tarde.',
    terms_of_use: 'Termos de uso',
    something_went_wrong: 'Oops, Algo deu errado',
    please_try_again: 'Tente novamente',
    link_copied: 'Link copiado!',
    check_out_this_item_on_4all: 'Confira este item no 4AllDegens',
    copy_link: 'Copiar link',
    share_on_facebook: 'Compartilhar no Facebook',
    share_on_twitter: 'Compartilhar no Twitter',
    try_adjusting_your_search:
      'Tente ajustar sua pesquisa ou filtros para encontrar o que está procurando',
    not_sure_where_to_start: 'Não sabe por onde começar? Veja estas sugestõess',
    sell_your_nft_collection_with_us: 'Venda sua coleção de NFT conosco',
    why_4alldegens: 'Por que 4AllDegens?',
    more_accessible: 'Mais acessível',
    the_easiest_way_to_access:
      'A maneira mais fácil de acessar NFTs World, sem necessidade de carteira, criptomoedas ou outras compelxidades. É só pegar sua NFT, com R$',
    collection_without_fraud: 'Coleção com curadoria',
    all_our_collections_are_filtered:
      'Todas as nossas coleções são filtradas pela nossa equipa para garantir a melhor experiência.',
    all_payment_methods: 'Todos os métodos de pagamento',
    bank_credit_card_cash:
      'Banco, cartão de crédito, dinheiro, boleto e outros métodos de pagamento estão disponíveis para sua compra',
    complete_this_information_about:
      'Preencha estas informações sobre sua coleção',
    im_not_selling_this_collection:
      'Não estou vendendo essa coleção em nenhum outro lugar',
    collection_name: 'Nome da coleção',
    amount_of_copies: 'Cantidad de copias',
    your_request_was_made: 'Seu pedido foi feito',
    thank_you_selling_nft_with_us:
      'Obrigado por se interessar em vender sua coleção conosco, entraremos em contato nas próximas 48 horas.',
    wishlist: 'Favoritos',
    did_not_get_a_confirmation_mail: 'Não recebeu um e-mail de confirmação?',
    send_again: 'Envie novamente',
    email_resend_successfully: 'Email reenviado com sucesso',
    if_you_continue_to_have_problems_confirmation:
      'Se você continuar tendo problemas com a confirmação da conta, entre em contato conosco:',
    label_pop: 'Mais visto',
    views: 'visualizações',
    my_favorites: 'Meus favoritos',
    join_the_community: 'Junte-se à comunidade',
    go_to_most_viewed: 'Ir para os mais vistos',
    label_fav: 'Favoritos',
    owned_by: 'Propriedade de',
    trend: 'Tendência',
    ADtrend: '#NFT-Bacano',
    edit_sale: 'Editar venda',
    label_notifications: 'Notificações',
    notification_seconds: '{time} segundos atrás',
    notification_minutes: '{time} minutos atrás',
    notification_hours: '{time} horas atrás',
    notification_days: 'Há {time} dias',
    dont_have_notifications: 'Você não tem notificações',
    label_all: 'Tudo',
    label_unread: 'Não lida',
    label_see_all: 'Ver todos',
    label_mark_see_all: 'Marcar tudo como lido',
    this_is_your_nft: 'Este é o seu NFT',
    here_you_have_the_option_to_sell: 'Aqui você pode vendê-lo',
    your_nft_is_for_sale: 'Seu NFT está à venda',
    now_you_can_share_your_nft_with_everyone:
      'Agora você pode compartilhar seu NFT com todos!',
    nft_edited_successfully: 'Preço NFT editado com sucesso',
    create_collection: 'Criar coleção',
    collection_symbol: 'Symbol',
    website: 'Página da Internet',
    label_information: 'Em formação',
    label_social_networks: 'Redes sociais',
    created_collection: 'Collection Created Successfully',
    max_three_characters: 'O símbolo deve ter no máximo 3 caracteres',
    min_three_characters: 'Mínimo de 3 caracteres',
    collection_symbol_info:
      'O Símbolo de Coleção deve ter no máximo 3 caracteres. Por exemplo "BTC"',
    my_collections: 'My Collections',
    customize_your_url:
      'Personalize seu URL no 4AllDegens. Deve conter apenas letras minúsculas, números e hífens.',
    must_only_contain_lowercase_number_hyphens:
      'Deve conter apenas letras minúsculas, números e hífens.',
    this_image_will_used_featuring:
      'Esta imagem será usada para apresentar sua coleção. Máximo 1 MB. 500 x 500 recomendado.',
    your_website: 'http://seusite.com',
    published: 'Publicados',
    unpublished: 'Não publicado',
    pending: 'Pendente',
    create_new_item: 'Criar Novo Item',
    image: 'Imagem',
    file_type_supported:
      'Tipos de arquivo suportados: JPG, PNG, GIF. 1000x1000 recomendado. Máximo de 10 MB.',
    item_name: 'Nome do item',
    mint_PENDING: 'Cunhar pendente, conclua o processo para publicar suas NFTs',
    mint_MINTED: 'Cunhar concluído, veja suas NFTs aqui e compartilhe',
    mint_APPROVED: 'Cunhar concluído, veja suas NFTs aqui e compartilhe',
    mint_CANCELLED: 'Cunhar cancelado, você pode publicar suas NFTs aqui',
    mint_FOR_REVIEW:
      'Cunhar em análise, o processo será concluído nas próximas 48 horas',
    collection_not_open: 'Esta coleção não está aberta',
    back_to_collection: 'Voltar para a coleção',
    is_not_correct_format: 'O nome de usuário só pode ter letras e números',
    create_nfts: `Criar NFTs`,
    now_you_can_create_your_own_collections_of_NFTs:
      'Agora você pode criar suas próprias coleções de NFTs',
    order_APPROVED: 'Order Approved, você pode ver sua NFT em seu perfil',
    order_PENDING: 'Order Pending, você pode ver seu pedido em seu perfil',
    select_your_payment_method: 'Selecione seu metodo de pagamento',
    publish_your_collection: 'Publique sua coleção',
    collection_status: 'Status da coleção:',
    add_asset: 'Adicionar NFT',
  },
};

export default pt;
