import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  alpha,
  Tooltip,
} from '@mui/material';
import { theme } from '../../styles/material-theme';
import { CollectionAsset } from '../../services/mint-service';
import DiamondIcon from '@mui/icons-material/Diamond';
import { makeStyles } from '@mui/styles';
import { currencyFormat } from '../../config/helpers/formatter';

const useStyles = makeStyles(() => ({
  rarity: {
    position: 'absolute',
    top: 10,
    right: 10,
    background: alpha(theme.palette.background.paper, 0.5),
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1px 4px',
  },
}));

interface Props {
  asset: CollectionAsset;
}

export const CollectionAssetCard = ({ asset }: Props) => {
  const classes = useStyles();

  return (
    <Card>
      <CardActionArea>
        <Box overflow='hidden' position='relative'>
          <CardMedia
            component='img'
            height='220'
            image={asset.image}
            alt='nft'
          />
          <Tooltip title='Rarity'>
            <Box className={classes.rarity}>
              <Typography
                color={theme.palette.text.primary}
                fontWeight='bold'
                fontSize='14px'
              >
                {asset.rarity}
              </Typography>
              <DiamondIcon fontSize='small' />
            </Box>
          </Tooltip>
        </Box>

        <CardContent>
          <Box display='flex'>
            <Tooltip title={asset.description}>
              <Box
                display='flex'
                textAlign='left'
                width='100%'
                justifyContent='space-between'
              >
                <Typography
                  color={theme.palette.text.primary}
                  fontSize='15px'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                >
                  {asset.name}
                </Typography>
                <Typography color={theme.palette.text.primary} fontSize='15px'>
                  {currencyFormat(asset.price)} {asset.currency}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CollectionAssetCard;
