import { alpha, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputBase } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getExploreFilter, setExploreFilterQuery, cleanExploreFiltersQuery } from "../../redux/filters";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from 'react';

const SearchInput = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(1),
  marginLeft: 0,
  width: "100%",
  height: "40px",
  [theme.breakpoints.up("md")]: {
    width: "300px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "1em",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Search = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const filters = useAppSelector(getExploreFilter);
  const [search, setSearch] = useState(filters.query || "");
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setSearch(filters.query || "");
  }, [filters]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setExploreFilterQuery(search));
    if (pathname !== "/explore") {
      navigate("/explore");
    }
  };

  const handleCleanSearchBar = () => {
    dispatch(cleanExploreFiltersQuery());
    setSearch("");
  };

  return (
    <SearchInput>
      <form style={{ display: "flex", justifyContent: "space-between" }} onSubmit={handleSubmit}>
        <StyledInputBase
          placeholder={t("search_items_and_collect")}
          inputProps={{ "aria-label": "search" }}
          onChange={handleChange}
          value={search}
          name="search"
        />
        <div>
          {search !== "" && (
            <IconButton onClick={handleCleanSearchBar} sx={{ p: "8px" }} aria-label="search">
              <CloseIcon />
            </IconButton>
          )}
          <IconButton type="submit" sx={{ p: "8px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </div>
      </form>
    </SearchInput>
  );
};
export default Search;
