import React, { memo, useEffect, useRef } from 'react';

interface Props {
  script: string;
}

const InjectScript = memo(({ script }: Props) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current === null) {
      return;
    }
    // create a contextual fragment that will execute the script
    // beware of security concerns!!
    const doc = document.createRange().createContextualFragment(script);

    // clear the div HTML, and append the doc fragment with the script
    divRef.current.innerHTML = '';
    divRef.current.appendChild(doc);
  });

  return <div ref={divRef} />;
});

InjectScript.displayName = 'InjectScript';

export default InjectScript;
