import SellYourCollection from './components/sell-your-collection';
import TopCollections from './components/top-collections';
import NftsInSeconds from './components/nfts-in-seconds';
import Why4AllDegens from './components/why-4alldegens';
import Footer from '../../components/footer/footer';
import FirstScroll from './components/first-scroll';
import Background from './components/background';
import { Container } from '@mui/material';
import HowTo from './components/how-to';
import React from 'react';

const Home = () => {
  return (
    <>
      <Container maxWidth='lg'>
        <Background fixed={true} />
        <FirstScroll />
        <Why4AllDegens />
        <TopCollections />
        <SellYourCollection />
        <HowTo />
        <NftsInSeconds />
      </Container>
      <Footer />
    </>
  );
};

export default Home;
