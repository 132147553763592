import urls from "../config/constants/urls";
import { buildPathWithParams } from "../config/helpers/url";
import { toCamelCaseObject, toSnakeCaseObject } from "../config/helpers/utils";
import { HttpService } from "./http-service";
import { ProductLite, RProductLite } from "./products-service";

const Api = new HttpService(true);

export interface RWishItem {
  token_id: string;
  asset_contract_address: string;
}
export interface WishItem {
  tokenId: string;
  assetContractAddress: string;
}

export default class WishListService {
  static addToWishList = async (whishItem: WishItem) => {
    try {
      const response = await Api.put<string>(urls.addToWishList, toSnakeCaseObject(whishItem));
      return toCamelCaseObject<RWishItem[]>(response);
    } catch (e) {
      throw e;
    }
  };

  static removeToWishList = async (whishItem: WishItem) => {
    try {
      const response = await Api.delete(
        buildPathWithParams(urls.deleteWishList, {
          wishlistAddress: whishItem.assetContractAddress,
          wishlistToken: whishItem.tokenId,
        })
      );
      return toCamelCaseObject<RWishItem[]>(response);
    } catch (e) {
      throw e;
    }
  };

  static getUserWishList = async () => {
    try {
      const response = await Api.get<RProductLite[]>(buildPathWithParams(urls.getUserWishList, {}));
      return toCamelCaseObject<ProductLite[]>(response);
    } catch (e) {
      throw e;
    }
  };
}
