const en = {
  translation: {
    search_items_and_collect: 'Search items and collect',
    explore: 'Explore',
    buy_collect_and_enjoy: 'Buy, collect and enjoy NFTs easier than ever',
    a_curated_collection_of_nf:
      'A curated collection of NFTs, with your favorite payment method, without need of complex wallets and other web3 hassles',
    register: 'Register',
    nf_ts_in_seconds: 'NFTs, in seconds',
    create_your_accont: 'Create your accont',
    create_your_own_account:
      'Create your own account with your personal data as you always did.',
    search_your_nft: 'Search your NFT',
    make_a_pic_in_any:
      'Make a pic in any of our displayed NFTs, don’t worry about the network, provider or technicality we solve it for you.',
    purchase_with_usd: 'Purchase with USD or local currency',
    buy_cell_your_favorite:
      'Buy & cell your favorite NFT with your local currency with your favorite payment method.',
    we_hold_it_safely_for_you: 'We hold it safely for you',
    we_will_secure_your:
      'We will secure your NFT within the blockchain ir our vaults and gelp you take good care of it.',
    last_week_s_top_collections: `This week's most viewed NFTs`,
    sign_up: 'Sign up',
    name: 'Name',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    country: 'Country',
    other: 'Other',
    afghanistan: 'Afghanistan',
    brazil: 'Brazil',
    chile: 'Chile',
    china: 'China',
    colombia: 'Colombia',
    india: 'India',
    mexico: 'Mexico',
    negeria: 'Negeria',
    sweden: 'Sweden',
    uganda: 'Uganda',
    uruguay: 'Uruguay',
    united_kingdom: 'United Kingdom',
    this_field_is_required: 'This field is required',
    create_account: 'Create account',
    you_already_have: 'You already have an account? Sign In',
    filter: 'Filter',
    price: 'Price',
    price_history: 'Price History',
    min_to_max: 'Min to Max',
    apply: 'Apply',
    collections: 'Collections',
    search: 'Search',
    sort_by: 'Sort by',
    price_low_to_hight: 'Low to High',
    price_high_to_low: 'High to Low',
    sign_in: 'Sign In',
    contact_us: 'Contact Us',
    profile: 'Profile',
    login: 'Login',
    my_account: 'My Account',
    marketplace: 'Marketplace',
    logout: 'Logout',
    to: 'to',
    best_offer: 'Best Offer',
    collection: 'Collection',
    continue: 'Continue',
    clear_all: 'Clear All',
    done: 'Done',
    please_enter_a_valid_email_address: 'Please enter a valid email address',
    email_sent_successfully: 'Email sent successfully',
    password_must_have_at_least_8_characters:
      'Password must have at least 8 characters.',
    password_must_contain_upper_case_lower_case_and_numbers:
      'Password must contain upper case, lower case and numbers.',
    passwords_do_not_match: 'Passwords do not match',
    new_password_set_successfully: 'New Password set successfully',
    forgot_password: 'Forgot Password?',
    don_t_worry_please_enter:
      "Don't worry! Please enter your email associated with your account. We'll send you a code to choose a new one.",
    send_code: 'Send Code',
    back_to_login: 'Back to login',
    please_enter_a_new_password: 'Please enter a New Password',
    and_the_code_that_we_send_to_your_email:
      'And the code that we send to your email.',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    login_successfully: 'Login Successfully',
    don_t_have_an_account_sign_up: "Don't have an account? Sign Up",
    current_price: 'Current Price',
    buy_now: 'Buy Now',
    purchase_successfully: 'Purchase Successfully',
    your_payment_is_being_credited:
      'Your payment is being credited, in the course of the next 24 hours you will receive an email with the confirmation of the purchase. You can see the details of the Order from your Profile.',
    your_purchase_exceeds: 'Your purchase exceeds $5000',
    as_your_purchase_exceeds:
      'As your purchase exceeds $5,000, our team will contact you by email to carry out the operation in a personalized way.',
    item_activity: 'Item Activity',
    event: 'Event',
    from: 'From',
    date: 'Date',
    description: 'Description',
    created: 'Created',
    properties: 'Properties',
    no_properties: 'No Properties',
    more_from_this_collection: 'More From This Collection',
    view_collection: 'View Collection',
    usd_price: 'Usd Price',
    eth_price: 'Eth Price',
    seller_wallet_address: 'Seller Wallet Address',
    asset_contract_address: 'Asset Contract Address',
    buyer_user_id: 'Buyer User Id',
    status: 'Status',
    information_updated_successfully: 'Information Updated Successfully',
    save_changes: 'Save Changes',
    email_confirmed_successfully: 'Email confirmed Successfully',
    sign_up_successfully: 'Sign Up Successfully',
    you_already_have_an_account_sign_in: 'You already have an account? Sign In',
    confirm_your_email: 'Confirm Your Email',
    please_check_your_inbox:
      'Please check your inbox for a confirmation email. Click the link in the email to confirm your email address.',
    content_not_available_yet: 'Content not available yet',
    not_for_sale: 'Not for sale',
    policy: 'Policy',
    privacy: 'Privacy',
    cookies: 'Cookies',
    username_exists: 'Username already exists',
    email_exists: 'Email already exists',
    you_dont_have_nft_yet: "You don't have any NFT yet",
    you_dont_any_order: "You don't have any Order Pending yet",
    go_to_explore: 'Go To Explore',
    collected: 'Collected',
    orders: 'Orders',
    page_not_found: 'Oops, Page not found',
    no_items_to_display: 'No items to display',
    do_you_have_questions: 'Do you have questions?',
    we_have_answers: 'We have answers',
    q_what_is_4alldegens: 'What is 4 All degens NFT Marketplace?',
    q_how_to_get_started: 'How to Get Started?',
    q_how_to_buy_nft: 'How to Buy an NFT in 4 All Degens?',
    q_how_to_sell_nft: 'How to Sell an NFT in 4 All Degens?',
    q_how_to_see_my_nft_vault: 'How to see my NFT vault?',
    q_where_is_my_nft: 'Where is my NFT?',
    q_what_is_a_nft: 'What is an NFT?',
    q_other_questions: 'Other questions?',
    a_what_is_4alldegens:
      'Its the first NFT marketplace that helps user from all over the world to aquire their favorite NFTs in their local currency. We stand between the web 3.0, complex and hard blockchain standars and the regular customer that just want to buy an NFT.',
    a_how_to_get_started:
      'Sign up for a 4 All Degens account, Confirm your email, Pick you NFT, Pay in your favorite currency, done!',
    a_how_to_buy_nft:
      'You only need to click buy on your available NFT, connect with your phone numbe and select your favorite payment method.',
    a_how_to_sell_nft:
      'Accept you sell requers or contact our agents at ola@4alldegens.com.',
    a_how_to_see_my_nft_vault:
      'Click on your account profile, and check your compleate collection in 4 All Degens.',
    a_where_is_my_nft:
      '4 All Degens safes all NFT on a secure vault facilitating security, reliability and usability.',
    a_what_is_a_nft:
      "NFT, defined as 'Non Fungible Tokes', are unique digital assets in the Blockchain that represent communities, art, intelectual value and any other potential value. They live in different blockchains and serve for alternative use cases.",
    a_other_questions:
      'Please contact ola@4alldegens.com, we are here to help you enter the new digital economy.',
    faq: 'FAQ',
    sell: 'Sell',
    do_you_want_request_sales_order: 'Do you want to request a sales order?',
    a_representative_will_contact_you:
      'A representative will contact you within the next 24 hours to coordinate the details of the sale.',
    request_a_sales_order: 'Request a sales order',
    request_sale_successfully: 'Successfully Requested Sale',
    select_price: 'Select the price you want to sell your NFT',
    currency: 'Currency',
    confirm: 'Confirm',
    will_contact_you:
      'A representative will contact you to coordinate the details of the sale.',
    ON_SALE: 'On Sale',
    OWNED: 'Owned',
    username_between_4_20: 'Username must be between 4 and 20 characters',
    email_password_incorrect: 'Wrong email and/or password, please try again',
    something_went_wrong_please_try_again:
      'Something went wrong. Please try again later.',
    terms_of_use: 'Terms Of Use',
    something_went_wrong: 'Oops, Something went wrong',
    please_try_again: 'Please try again',
    link_copied: 'Link Copied!',
    check_out_this_item_on_4all: 'Check out this item on 4AllDegens',
    copy_link: 'Copy Link',
    share_on_facebook: 'Share On Facebook',
    share_on_twitter: 'Share On Twitter',
    try_adjusting_your_search:
      'Try adjusting your search or filters to find what you are looking for',
    not_sure_where_to_start: 'Not sure where to start? See this suggestions',
    sell_your_nft_collection_with_us: 'Venda sua coleção de NFT conosco',
    why_4alldegens: 'Why 4AllDegens?',
    more_accessible: 'Mais acessível',
    the_easiest_way_to_access:
      'The easiest way to access NFTs World, no wallet, cryptocurrencies or other compelxities required. Just get your NFT, with R$',
    collection_without_fraud: 'Curated collection',
    all_our_collections_are_filtered:
      'All our collections are filtered by our team to ensure the best experience.',
    all_payment_methods: 'All payment methods',
    bank_credit_card_cash:
      'Bank, credit card, cash, bank slip and other payment methods are available for your purchase',
    complete_this_information_about:
      'Complete this information about your collection',
    im_not_selling_this_collection:
      'I am not selling this collection anywhere else',
    upload_sample: 'Upload Sample',
    collection_name: 'Collection Name',
    amount_of_copies: 'Amount of copies',
    your_request_was_made: 'Your request was made',
    thank_you_selling_nft_with_us:
      'Thank you for being interested in selling your collection with us, we will be contacting you in the next 48 hours.',
    wishlist: 'Favorites',
    did_not_get_a_confirmation_mail: 'Did not get a confirmation mail?',
    send_again: 'Send Again',
    email_resend_successfully: 'Email re-sent successfully',
    if_you_continue_to_have_problems_confirmation:
      'If you continue to have problems with your account confirmation, please contact us:',
    label_pop: 'Most Viewed',
    views: 'views',
    my_favorites: 'My Favorites',
    join_the_community: 'Join the community',
    go_to_most_viewed: 'Go to Most Viewed',
    label_fav: 'Favorites',
    owned_by: 'Owned by',
    trend: 'Trend',
    ADtrend: '#4ADTrend',
    edit_sale: 'Edit sale',
    label_notifications: 'Notifications',
    notification_seconds: '{time} seconds ago',
    notification_minutes: '{time} minutes ago',
    notification_hours: '{time} hours ago',
    notification_days: '{time} days ago',
    dont_have_notifications: 'You don’t have notifications',
    label_all: 'All',
    label_unread: 'Unread',
    label_see_all: 'See All',
    label_mark_see_all: 'Mark all as read',
    this_is_your_nft: 'This is your NFT',
    here_you_have_the_option_to_sell: 'Here you can sell it',
    your_nft_is_for_sale: 'Your NFT is for sale',
    now_you_can_share_your_nft_with_everyone:
      'Now you can share your NFT with everyone!',
    nft_edited_successfully: 'NFT price edited successfully',
    create_collection: 'Create Collection',
    collection_symbol: 'Symbol',
    website: 'Website',
    label_information: 'Information',
    label_social_networks: 'Social Networks',
    created_collection: 'Collection Created Successfully',
    max_three_characters: 'Symbol must have a maximum of 3 characters',
    min_three_characters: 'Minimum of 3 characters',
    collection_symbol_info:
      'Collection Symbol must have a maximun of 3 characters. For example "BTC"',
    my_collections: 'My Collections',
    customize_your_url:
      'Customize your URL on 4AllDegens. Must only contain lowercase letters, numbers, and hyphens.',
    must_only_contain_lowercase_number_hyphens:
      'Must only contain lowercase letters, numbers, and hyphens.',
    this_image_will_used_featuring:
      'This image will be used for featuring your collection. Max 1MB. 500 x 500 recommended.',
    your_website: 'http://yourwebsite.com',
    published: 'Published',
    unpublished: 'Unpublished',
    pending: 'Pending',
    create_new_item: 'Create New Item',
    image: 'image',
    file_type_supported:
      'File types supported: JPG, PNG, GIF. 1000x1000 recommended. Max 10MB.',
    item_name: 'Item Name',
    mint_PENDING: 'Mint pending, complete the process to publish your NFTs',
    mint_MINTED: 'Mint completed, see your NFTs here and share it',
    mint_APPROVED: 'Mint completed, see your NFTs here and share it',
    mint_CANCELLED: 'Mint cancelled, you can publish your NFTs here',
    mint_FOR_REVIEW:
      'Mint in review, the process be completed in the next 48 hours',
    collection_not_open: 'This collection is not open',
    back_to_collection: 'Back to collection',
    is_not_correct_format: 'Username can only have letters and numbers',
    create_nfts: `Create NFTs`,
    now_you_can_create_your_own_collections_of_NFTs:
      'Now you can create your own collections of NFTs',
    order_APPROVED: 'Order Approved, you can see your NFT on your Profile',
    order_PENDING: 'Order Pending, you can see your Order on your Profile',
    select_your_payment_method: 'Select your payment method',
    publish_your_collection: 'Publish your collection',
    collection_status: 'Collection Status:',
    add_asset: 'Add Asset'
  },
};

export default en;
