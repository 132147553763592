import { Avatar, Skeleton, Typography } from '@mui/material';
import { getInitials } from '../../config/helpers/utils';
import { theme } from '../../styles/material-theme';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  avatar: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundImage: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
    '&:hover': {
      backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.grey.A700})`,
    },
    color: theme.palette.grey.A200,
    margin: '0 auto 20px',
  },
}));

interface Props {
  username?: string;
  width?: number;
  height?: number;
  loading: boolean;
}

export const AvatarComponent = ({
  username,
  width = 180,
  height = 180,
  loading,
}: Props) => {
  const classes = useStyles();

  return loading ? (
    <Skeleton
      variant='circular'
      width={width}
      height={height}
      style={{ borderRadius: '50%' }}
    />
  ) : (
    <Avatar className={classes.avatar} style={{ width: width, height: height }}>
      <Typography variant='h6' color='primary' fontSize={48}>
        {getInitials(username || '')}
      </Typography>
    </Avatar>
  );
};

export default AvatarComponent;
