import urls from '../config/constants/urls';
import { buildPathWithParams } from '../config/helpers/url';
import { toCamelCaseObject } from '../config/helpers/utils';
import { HttpService } from './http-service';

const Api = new HttpService(true);

export interface ItemActivityPagination {
  items: ItemActivity[];
  page: string;
  size: string;
  total: string;
}

export interface ItemActivity {
  bidAmount: number;
  fromAccount: {
    profileImgUrl: string;
    address: string;
    user: {
      username: string;
    };
  };
  toAccount: {
    profileImgUrl: string;
    address: string;
    user: {
      username: string;
    };
  };
  eventType: string;
  paymentToken: {
    symbol: string;
    address: string;
    decimals: number;
    ethPrice: number;
    usdPrice: number;
  };
  price: {
    decimals: number;
    ethPrice: number;
    sessionPrice: number;
    symbol: string;
    usdPrice: number;
  };
  createdDate: string;
}

export const emptyItemActivity: ItemActivity = {
  bidAmount: 0,
  fromAccount: {
    profileImgUrl: '',
    address: '',
    user: {
      username: '',
    },
  },
  toAccount: {
    profileImgUrl: '',
    address: '',
    user: {
      username: '',
    },
  },
  eventType: '',
  paymentToken: {
    symbol: '',
    address: '',
    decimals: 0,
    ethPrice: 0,
    usdPrice: 0,
  },
  price: {
    decimals: 0,
    ethPrice: 0,
    sessionPrice: 0,
    symbol: '',
    usdPrice: 0,
  },
  createdDate: '',
};

export const emptyItemActivityPagination: ItemActivityPagination = {
  items: [emptyItemActivity],
  page: '',
  size: '',
  total: '',
};

export default class ItemActivityService {
  static getItemActivity = async (
    addressContractId: string,
    tokenId: string,
    page: string,
    size: string
  ) => {
    try {
      const response = await Api.get<ItemActivityPagination>(
        buildPathWithParams(urls.getItemActivity, {
          addressContractId,
          tokenId,
          page,
          size,
        })
      );
      return toCamelCaseObject<ItemActivityPagination>(response);
    } catch (e) {
      throw e;
    }
  };
}
