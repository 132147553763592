import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  alpha,
} from '@mui/material';
import React, { useState } from 'react';
import Background from '../home/components/background';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { theme } from '../../styles/material-theme';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import LoadingBtn from '../../components/common/loading-btn';
import { errorMessage } from '../../config/helpers/utils';
import ForgotPasswordService from '../../services/auth-services/forgot-password-service';
import { useTranslation } from 'react-i18next';
import { CommonError } from '../../services/auth-services/login-service';

type PageStep = 'email' | 'code';

const ForgotPassword = () => {
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [step, setStep] = useState<PageStep>('email');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //
  // Email Step
  //
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('please_enter_a_valid_email_address'))
      .required(t('this_field_is_required')),
  });

  const onSubmit = async (values: typeof initialValues) => {
    setLoadingBtn(true);
    try {
      const response = await ForgotPasswordService.forgotPassword(values);
      if (response) {
        setLoadingBtn(false);
        setStep('code');
        enqueueSnackbar(t('email_sent_successfully'), {
          variant: 'success',
        });
      }
    } catch (e) {
      setLoadingBtn(false);
      const error = e as CommonError;
      if (
        error.error === 'nft_marketplace:2' ||
        error.error === 'nft_marketplace:3'
      ) {
        enqueueSnackbar(t('email_password_incorrect'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(errorMessage(e), {
          variant: 'error',
        });
      }
    }
  };

  //
  // Code Step
  //
  const codeInitialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    code: '',
  };

  const codeValidationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('please_enter_a_valid_email_address'))
      .required(t('this_field_is_required')),
    password: Yup.string()
      .min(8, t('password_must_have_at_least_8_characters'))
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        t('password_must_contain_upper_case_lower_case_and_numbers')
      )
      .required(t('this_field_is_required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      .required(t('this_field_is_required')),
    code: Yup.string().trim().required(t('this_field_is_required')),
  });

  const onSubmitCode = async (values: typeof codeInitialValues) => {
    setLoadingBtn(true);
    const request = {
      email: values.email,
      authCode: values.code,
      newPassword: values.password,
    };
    try {
      const response = await ForgotPasswordService.changePassword(request);
      if (response) {
        setLoadingBtn(false);
        navigate('/login');
        enqueueSnackbar(t('new_password_set_successfully'), {
          variant: 'success',
        });
      }
    } catch (e) {
      setLoadingBtn(false);
      enqueueSnackbar(errorMessage(e), {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Background fixed={true} />
      <Box>
        <Container maxWidth='sm'>
          <Paper
            sx={{
              marginY: 18,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: { xs: 4, md: 8 },
              paddingY: { xs: 12, md: 8 },
              background: alpha(theme.palette.background.paper, 0.75),
            }}
          >
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>

            {step === 'email' ? (
              <>
                <Typography component='h1' variant='h5'>
                  {t('forgot_password')}
                </Typography>
                <Typography variant='body2' color='textSecondary' marginY={2}>
                  {t('don_t_worry_please_enter')}
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isValid }) => (
                    <Form style={{ width: '100%' }}>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='text'
                          label='Email'
                          name='email'
                          fullWidth
                        />
                      </Box>
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        disabled={!isValid || loadingBtn}
                        sx={{ mt: 3, mb: 2 }}
                        size='large'
                      >
                        <LoadingBtn showLoading={loadingBtn} />
                        {t('send_code')}
                      </Button>
                    </Form>
                  )}
                </Formik>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Link
                      to='/login'
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: 14,
                      }}
                    >
                      {t('back_to_login')}
                    </Link>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Typography component='h1' variant='h5'>
                  {t('please_enter_a_new_password')}
                </Typography>
                <Typography variant='body2' color='textSecondary' marginY={2}>
                  {t('and_the_code_that_we_send_to_your_email')}
                </Typography>
                <Formik
                  initialValues={codeInitialValues}
                  validationSchema={codeValidationSchema}
                  onSubmit={onSubmitCode}
                >
                  {({ isValid }) => (
                    <Form style={{ width: '100%' }}>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='text'
                          label={t('email')}
                          name='email'
                          fullWidth
                        />
                      </Box>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='text'
                          label='Code'
                          name='code'
                          fullWidth
                        />
                      </Box>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='password'
                          label={t('new_password')}
                          name='password'
                          fullWidth
                        />
                      </Box>
                      <Box marginTop={3}>
                        <Field
                          component={TextField}
                          type='password'
                          label={t('confirm_password')}
                          name='confirmPassword'
                          fullWidth
                        />
                      </Box>
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        disabled={!isValid || loadingBtn}
                        sx={{ mt: 3, mb: 2 }}
                        size='large'
                      >
                        <LoadingBtn showLoading={loadingBtn} />
                        {t('confirm_password')}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
