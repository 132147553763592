import urls from '../../config/constants/urls';
import { toCamelCaseObject } from '../../config/helpers/utils';
import { User } from '../../redux/session/types';
import { HttpService } from '../http-service';

const Api = new HttpService(true);

export interface LoginRequest {
  email: string;
  password: string;
}

export interface CommonError {
  error: string;
  message: string;
  internalTerm: string;
}

export default class LoginService {
  static login = async (request: LoginRequest) => {
    try {
      const response = await Api.post<string>(urls.login, request);
      return toCamelCaseObject<User>(response);
    } catch (e) {
      throw e;
    }
  };

  static logout = async () => {
    return await Api.post<string>(urls.logout);
  };
}
