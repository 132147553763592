import {
  Box,
  Button,
  CardMedia,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import Footer from '../../components/footer/footer';
import Background from '../home/components/background';
import notFoundImg from '../../assets/img/404-2.png';
import { alpha } from '@mui/material/styles';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { theme } from '../../styles/material-theme';

const SomethingWentWrong = () => {
  return (
    <>
      <Container maxWidth='lg'>
        <Background fixed={true} />

        <Box textAlign='center'>
          <Paper
            elevation={5}
            sx={{
              marginY: 16,
              p: { xs: 1, md: 4 },
              paddingY: { xs: 14, md: 4 },
              background: alpha(theme.palette.background.paper, 0.3),
            }}
          >
            <Box
              width={{ xs: '70%', md: '50%' }}
              maxWidth={'400px'}
              margin='0 auto'
            >
              <CardMedia component='img' image={notFoundImg} alt='404' />
            </Box>
            <Typography variant='h4' paddingY={3} color='textSecondary' fontSize="2rem">
              {t('something_went_wrong')}
            </Typography>
            <Typography
              variant='h6'
              color='textSecondary'
              fontWeight='normal'
            >
              {t('please_try_again')}
            </Typography>
            <Link to={'/explore'} style={{ textDecoration: 'none' }}>
              <Button
                variant='contained'
                sx={{ margin: '24px auto', display: 'block' }}
              >
                {t('go_to_explore')}
              </Button>
            </Link>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default SomethingWentWrong;
