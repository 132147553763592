import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  alpha,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import RoundedTitle from '../../../components/common/rounded-title';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingBtn from '../../../components/common/loading-btn';
import { errorMessage } from '../../../config/helpers/utils';
import Background from '../../home/components/background';
import MintService from '../../../services/mint-service';
import { theme } from '../../../styles/material-theme';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppSelector } from '../../../redux/hooks';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../redux/session';
import { Field, Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import 'antd/es/upload/style/index.css';
import { TextField } from 'formik-mui';
import { Upload } from 'antd';
import * as Yup from 'yup';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '108px',
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: 28,
    color: '#fff',
    marginBottom: 50,
  },
  container: {
    maxWidth: 991,
  },
  upload: {
    '& .ant-upload.ant-upload-select-picture-card': {
      width: 200,
      height: 200,
    },
    '& .ant-upload-list-picture-card-container': {
      width: 200,
      height: 200,
    },
    '& .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item':
      {
        borderRadius: 10,
        padding: 0,
        overflow: 'hidden',
      },
    '& .ant-upload-list .ant-upload-select-picture-card': {
      backgroundColor: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.23)',
      borderRadius: 10,
      '&:hover': {
        border: '1px solid #fff',
      },
    },
    '& .ant-upload': {
      color: 'rgb(255, 255, 255)',
    },
  },
  socialInputTop: {
    '& .MuiInputBase-formControl': {
      borderRadius: '10px 10px 0 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: 'none',
    },
    '& legend': {
      width: 'unset',
    },
  },
  socialInputBottom: {
    '& .MuiInputBase-formControl': {
      borderRadius: '0 0 10px 10px',
    },
    '& legend': {
      width: 'unset',
    },
  },
  socialInput: {
    '& .MuiInputBase-formControl': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: 'none',
    },
    '& legend': {
      width: 'unset',
    },
  },
  uppercaseInput: {
    '& .MuiInputBase-input': {
      textTransform: 'uppercase',
    },
  },
  inputError: {
    '& fieldset': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
}));

const CreateCollection = () => {
  const [loadingVerifySlug, setLoadingVerifySlug] = useState<boolean>(false);
  const [slugValid, setSlugValid] = useState<boolean | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  const initialValues = {
    name: '',
    symbol: 'AAA',
    slug: '',
    description: '',
    external_url: '',
    facebook: '',
    twitter: '',
    instagram: '',
    tiktok: '',
    discord: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t('this_field_is_required')),
    symbol: Yup.string()
      .trim()
      .required(t('this_field_is_required'))
      .max(3, t('max_three_characters')),
    description: Yup.string().trim().required(t('this_field_is_required')),
    slug: Yup.string()
      .trim()
      .required(t('this_field_is_required'))
      .min(3, t('min_three_characters'))
      .matches(
        /(^[a-z0-9-]{3,}$)/,
        t('must_only_contain_lowercase_number_hyphens')
      ),
    external_url: Yup.string().trim(),
    facebook: Yup.string().trim(),
    twitter: Yup.string().trim(),
    instagram: Yup.string().trim(),
    tiktok: Yup.string().trim(),
    discord: Yup.string().trim(),
  });

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const verifySlug = async (e: any, errors: any) => {
    setLoadingVerifySlug(true);
    setSlugValid(null);

    if (e.target.value === '' || errors !== undefined) {
      setLoadingVerifySlug(false);
      return;
    } else {
      try {
        const response = await MintService.validateSlug(e.target.value);
        setSlugValid(response);
        setLoadingVerifySlug(false);
      } catch (e) {
        setLoadingVerifySlug(false);
        setSlugValid(null);
      }
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file.originFileObj as RcFile);
    });
    formData.append('body', JSON.stringify(values));

    try {
      const response = await MintService.createCollection(formData);
      if (response) {
        setLoading(false);
        setFileList([]);
        enqueueSnackbar(t('created_collection'), {
          variant: 'success',
        });
        navigate(`/user/${user.username}`);
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(errorMessage(e), {
        variant: 'error',
      });
    }
  };

  return (
    <Box display='flex' className={classes.root}>
      <Container maxWidth='md'>
        <Background fixed={true} />

        <Box
          sx={{
            background: alpha(theme.palette.background.paper, 0.5),
            backdropFilter: 'blur(2px)',
            padding: 4,
            borderRadius: 4,
            marginBottom: 10,
          }}
        >
          <Typography
            variant='h1'
            fontWeight='500'
            className={classes.headerTitle}
          >
            {t('create_collection')}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ dirty, isValid }) => (
              <Form
                style={{
                  width: 'calc(100% - 24px)',
                  minWidth: '300px',
                  margin: '0 auto',
                }}
              >
                <RoundedTitle title={t('label_information')} />
                <Box marginTop={3}>
                  <Upload
                    className={classes.upload}
                    listType='picture-card'
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    accept='image/*'
                  >
                    {fileList.length < 1 && 'Logo *'}
                  </Upload>
                </Box>
                <Typography color='textSecondary' fontSize={13}>
                  {t('this_image_will_used_featuring')}
                </Typography>
                <Box sx={{ position: 'relative' }} marginTop={3}>
                  <Field
                    required
                    component={TextField}
                    type='text'
                    label={t('collection_name')}
                    name='name'
                    autoComplete='none'
                    fullWidth
                  />
                </Box>
                <Box sx={{ position: 'relative' }} marginTop={3}>
                  <Field
                    required
                    component={TextField}
                    type='text'
                    label={'URL'}
                    name='slug'
                    placeholder=''
                    onKeyUp={verifySlug}
                    autoComplete='none'
                    InputLabelProps={{ shrink: true }}
                    className={slugValid === false ? classes.inputError : null}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          https://4alldegens.com/collection/
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='start'>
                          {slugValid === true ? (
                            <CheckCircleIcon color='success' />
                          ) : slugValid === null && !loadingVerifySlug ? (
                            <Tooltip title={t('customize_your_url')}>
                              <InfoIcon
                                sx={{ color: 'rgba(255,255,255, 0.40)' }}
                              />
                            </Tooltip>
                          ) : slugValid === false ? (
                            <HighlightOffIcon color='error' />
                          ) : null}
                          <LoadingBtn
                            showLoading={
                              loadingVerifySlug && slugValid === null
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  {slugValid === false && (
                    <Typography
                      color='error'
                      fontSize={12}
                      marginLeft={1.5}
                      marginTop={0.5}
                    >
                      This URL it&apos;s already in use
                    </Typography>
                  )}
                </Box>
                <Box sx={{ position: 'relative' }} marginTop={3}>
                  <Field
                    required
                    component={TextField}
                    type='text'
                    label={t('description')}
                    name='description'
                    multiline
                    rows={4}
                    placeholder=''
                    autoComplete='none'
                    fullWidth
                  />
                </Box>
                <Box marginTop={3}>
                  <RoundedTitle title={t('label_social_networks')} />
                </Box>
                <Box marginTop={3}>
                  <Field
                    className={classes.socialInputTop}
                    component={TextField}
                    variant='outlined'
                    type='text'
                    name='external_url'
                    placeholder={t('your_website')}
                    autoComplete='none'
                    fullWidth
                  />
                  <Field
                    className={classes.socialInput}
                    component={TextField}
                    type='text'
                    name='facebook'
                    placeholder=''
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          https://facebook.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    className={classes.socialInput}
                    component={TextField}
                    type='text'
                    name='twitter'
                    placeholder=''
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          https://twitter.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    className={classes.socialInput}
                    component={TextField}
                    type='text'
                    name='instagram'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          https://instagram.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    className={classes.socialInput}
                    component={TextField}
                    type='text'
                    name='tiktok'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          https://tiktok.com/@
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    className={classes.socialInputBottom}
                    component={TextField}
                    type='text'
                    name='discord'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          https://discord.gg/
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box
                  marginTop={4}
                  marginBottom={2}
                  display='flex'
                  justifyContent='center'
                >
                  <LoadingButton
                    variant='contained'
                    size='large'
                    type='submit'
                    loading={loading}
                    disabled={
                      !isValid || !dirty || loading || fileList.length !== 1
                    }
                  >
                    {t('confirm')}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateCollection;
